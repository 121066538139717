import { THROW_ERROR } from "utils/types.utils";
import { storeAction } from "interfaces/common.interface";

const initialState = [];

const errorReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case THROW_ERROR:
      const newState = { ...state, ...action.payload }
      return newState;

    default:
      return state;
  }
}

export default errorReducer;
