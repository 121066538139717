import React, { useEffect } from "react";
import Footer from "components/footer/footer.component";
import Header from "components/header/header.component";
import Navbar from "components/navbar/navbar.component";
import { NavLink } from "react-router-dom";
import parse from 'html-react-parser';
import "screens/user_screens/csita.screen.scss";
import { GET_CSITA_CONTENT, GET_CSITA_OFFICER } from "utils/types.utils";
import { useDispatch, useSelector } from "react-redux";
import { throwError, useSetState } from "utils/common.utils";
import { getAllDescription, getAllUser } from "models/public.model";
import OfficerCard from "components/officer_card/officer_card.component";

export default function Csita(props) {
    const dispatch = useDispatch();
    const csitaContent = useSelector((state: any) => state.aboutCsi.csitaContent);
  const csitaOfficers = useSelector((state: any) => state.aboutCsi.csitaOfficer);
    const [state, setState] = useSetState({
      selectedData: "csita_officers",
      edit: false,
      openModal: false,
      active: false,
      checkedList: [],
      isChecked: false,
      confirmModal: false,
      search: "",
      description: [],
      csitaOfficerId: "",
      loading: true,
      limit: 20,
      page: 1,
    });
    const handleGetAllContent = async () => {
        try {
          const data = {
            type: "csita_content",
            user_sort: true
          }
          const res: any = await getAllDescription(data);
          const details = res.data.map((doc) => {
            doc.desc = doc.description.replace(/(<([^>]+)>)/gi, "");
            return doc
          })
          dispatch({
            type: GET_CSITA_CONTENT,
            payload: details
          })
          setState({ loading: false, description: res.data[0].description });
        } catch (err) {
          throwError(err.data ? err.data.desc : "Failed to get CSITA offices list")
        }
      }
      const handleGetAllCsitaOfficers = async () => {
        try {
          const data = {
            limit: state.limit,
            search: state.search,
            page: state.page,
            type: "csita_officers"
          }
          const csitaOfficer: any = await getAllUser(data);
          if (csitaOfficer) {
            dispatch({
              type: GET_CSITA_OFFICER,
              payload: csitaOfficer.data
            })
            setState({ loading: false });
          }
        } catch (err) {
          throwError(err.data ? err.data.desc : "Failed to get CSITA offices list")
        }
      }

      useEffect(() => {
        handleGetAllContent();
        handleGetAllCsitaOfficers()
      }, []);
    return (
    <div className="csita_screen">
        <div className="csita_container">
            <div className="csita_wrapper">
                <div className="csita_header_wrapper">
                    <Header />
                </div>
                <div className="csita_navbar_wrapper" style={{ position: 'sticky', top: '0', zIndex: 999 }}>
                <Navbar {...props} />
                </div>
                <div className="csita_title_wrapper">
                    <div className="csita_title h2">Administration</div>
                    <div className="csita_line_wrapper"> </div>
                </div>
                    <div className="csita_nav_link_wrapper h1">
                        <div className="title_links_wrapper">
                            <div className="link h3">
                                <NavLink
                                  to="/about/officer"
                                  className="title_link"
                                >
                                    Officers
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/working_committee"
                                  className="title_link"
                                >
                                    Working Committee
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/executive_committee"
                                  className="title_link"
                                >
                                    Executive Committee
                                </NavLink>
                            </div>
                            <div className="link_1 h3">
                                <NavLink
                                  to="/about/csita"
                                  className="title_link"
                                  activeClassName="selected"
                                >
                                    CSITA
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/secretarial_staff/Staff Under General Secretary"
                                  className="title_link"
                                >
                                    Secretarial Staff
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="csita_content_container">
                        <div className="csita_content">
                            {parse(`${state.description}`)}
                        </div>
                        <div className="csita_officer_content_wrapper">
                            {csitaOfficers && csitaOfficers.map((officer) => {
                                return (
                                    <div className="officer_image_wrapper">
                                     <OfficerCard
                                       types="officers"
                                       profilePicture={officer.profile_picture}
                                       profession={officer.designation}
                                       name={officer.user_name}
                                       location={officer.address}
                                       email={officer.email}
                                       mobile={officer.mobile}
                                       phoneFax={officer.phone_fax}
                                       className="off_card_wrapper"
                                     />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                <div className="csita_footer_wrapper">
                    <div className="csita_footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
