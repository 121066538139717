import { instance } from '../helper/axios.helper';

export const addDiocese = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`diocese/create`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const getAllDioceses = () => {
    const promise = new Promise((resolve, reject) => {
        instance().get('diocese/get_all')
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllDiocese = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`diocese/get`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getDioceseById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`diocese/get/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const editDiocese = (id, data) => {
    const promise = new Promise((resolve, reject) => {
        instance().put(`diocese/edit/${id}`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const deleteDiocese = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().delete(`diocese/delete/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const removeMedia = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('videos/remove_media', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}
