import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './news_view.screen.scss';
import '../../index.scss';
import parse from 'html-react-parser';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import {
  GET_DIOCESE_NEWS,
  GET_CAMPAIGNS,
  GET_DEPARTMENT_NEWS,
} from 'utils/types.utils';
import { getAllNews, getCampaignDetails, getDioceseById, getMoreRelateNews, getNews } from 'models/public.model';
import { throwError, useSetState } from 'utils/common.utils';
import Swiper from 'components/swiper/swiper.component';
import Navbar from 'components/navbar/navbar.component';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import { getDepartmentById } from 'models/department.model';
import Button from 'components/button/button.component';
import Thumbnail from 'assets/images/thumbnail.jpg'

export default function NewsScreen(props) {
  const { match } = props;
  const newsData = useSelector((state: any) => state.diocese.news);
  const campaign = useSelector((state: any) => state.campaigns);
  const newsDetails = useSelector((state: any) => state.department.news);
  const filteredNews = newsDetails
  && newsDetails.docs.filter((news) => news._id !== match.params.id);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selected_filter: '',
    limit: 20,
    page: 1,
    search: '',
    activated: 'all',
    title: '',
    description: '',
    thumbnail: '',
    author: '',
    loading: true,
    gallery: '',
    postedAt: '',
    id: '',
    pdf: [],
    postedBy: '',
  });
  const handleGetRelatedNews = async ({ id }) => {
    try {
      const data = {
        limit: 20,
        page: state.page,
        type: match.params.type,
        news: match.params.id,
        activated: true,
        [match.params.type.slice(0, -1)]: [id],
      };
      const news: any = await getMoreRelateNews(data);
      dispatch({
        type: GET_DEPARTMENT_NEWS,
        payload: news.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Related News")
    }
  };
  const getDepartmentName = async (id) => {
    try {
      const res: any = await getDepartmentById(id);
      setState({ name: res.data.name })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Department details")
    }
  };
  const getDioceseName = async (id) => {
    try {
      const res: any = await getDioceseById(id);
      setState({ name: res.data.name })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Department details")
    }
  };
  const getNewsById = async () => {
    try {
      const news: any = await getNews(match.params.id);
      if (news !== undefined) {
        setState({
          title: news.data.title,
          description: news.data.description,
          thumbnail: news.data.thumbnail,
          gallery: news.data.gallery,
          postedAt: moment(news.data.date).format('dddd, MMM D, YYYY'),
          id: news.data.department ? news.data.department : news.data.diocese,
          author: news.data.created_by.user_name,
          pdf: news.data.pdf ? news.data.pdf : []
        });
        if (news.data.department) {
          getDepartmentName(news.data.department)
        } else {
          getDioceseName(news.data.diocese)
        }
        dispatch({
          type: GET_DIOCESE_NEWS,
          payload: news.data,
        });
        handleGetRelatedNews({
          id: news.data.department ? news.data.department : news.data.diocese,
        });
        setState({ loading: false })
      }
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get News details")
    }
  };

  const handleGetAllCampaignDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: state.activated,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        return doc;
      });
      res.data.docs = details;
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Campaigns")
    }
  };
  useEffect(() => {
      getNewsById();
      handleGetAllCampaignDetails();
      document.getElementById('main')?.scrollIntoView()
  }, [match.params.id]);

  return (
    <div className="screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="header_image_wrapper">
        <div className="image_wrapper">
          {state.loading ? <Spinner />
          : <img className="header_image" src={state.thumbnail !== '0' ? state.thumbnail : Thumbnail} alt="header" />}
        </div>
      </div>
      <div className="body_wrapper" id="main">
        <div className="header_content_wrapper">
          <div className="header_content">
            <div className="h1 header_title">{state.title}</div>
            <div className="h4 header_title_2">
              CSI SYNOD NEWS | {state.postedAt} | {state.name}
            </div>
          </div>
        </div>
        <div className="news_header_description_wrapper">
          {parse(`${state.description}`)}
          {state.pdf.map((file) => {
          return <a href={file} target="_blank" rel="noreferrer" className="file_link">{file}</a>
          })}
        </div>
        <div className="news_gallery_wrapper">
          <div className="news_gallery_heading">
            <div className="heading h1">Gallery</div>
            <div className="head_line" />
          </div>
          <div className="news_gallery_swiper">
            {state.loading ? <Spinner /> : newsData
            && newsData.gallery && newsData.gallery.length > 0 ? (
              <Swiper
                data={
                  newsData && newsData.gallery && newsData.gallery.length !== 0
                    ? newsData.gallery
                    : []
                }
                number={3}
                orderNow={() => {}}
                types="gallery"
                className="swiper_image"
              />
            ) : (
              <div className="related_news_content">No gallery images found</div>
            )}
          </div>
        </div>
      </div>
      <div className="news_campaigns_wrapper">
        <div className="news_campaign_wrapper2">
          <div className="news_campaign_heading">
            <div className="campaign_title h1 heading">More Related News</div>
            <div className="head_line" />
          </div>
          <div className="news_campaign_swiper_wrapper">
            {state.loading ? <Spinner /> : filteredNews && filteredNews.length > 0 ? (
              <Swiper
                types="news"
                className="news_swiper_image"
                data={newsDetails ? newsDetails.docs : []}
                number={3}
                orderNow={(id) => {
                  props.history.push(`/news_screen/${match.params.type}/${id}`);
                }}
              />
            ) : (
              <div className="related_news_content">No related news</div>
            )}
          </div>
        </div>
      </div>
      {match.params.type === 'departments'
      ? <div className="synod_btn_wrapper">
          <Button onClick={() => props.history.push('/news_list_screen/departments')} text="View all Synod news" />
        </div>
         : <div className="synod_btn_wrapper">
         <Button onClick={() => props.history.push('/news_list_screen/dioceses')} text="View all Diocese news" />
           </div>}
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
