import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import moment from "moment";
import _ from "lodash";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Button from "common_components/ui/button/button.ui";
import { GET_RESOURCES, GET_CSI_LIFE, THROW_ERROR } from 'utils/types.utils';
import PlusIcon from "assets/icons/Plus_White.svg";
import Filter from "assets/icons/Filter.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import Tick from "assets/icons/Tick_White.svg";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import Table from "components/table/table.component";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import { getAllPublication, addPublication, getPublicationById, editPublication, deletePublication } from "models/publication.model";
import { PublicationNavData, csiLifeTableHeading, csiLifeBody, csiUserSuggessionData, resourceYearSelectionData, csiMonthSelectionData, csitaOfficersBody, staffTableHeading } from "helper/constants.helper";
import "screens/publication/csi_life.screen.scss";
import { useSetState, getYear, getMonth, uploadMediaFiles, uploadPdf } from "utils/common.utils";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import { toast } from "react-toastify";

export default function CsiLife(props) {
  const dispatch = useDispatch();
  const csi = useSelector((state: any) => state.publication.csi_life);
  const [state, setState] = useSetState({
    selectedData: "csi_life",
    edit: false,
    openModal: false,
    filterModal: false,
    checkedList: [],
    checkList: [],
    page: 1,
    limit: 40,
    isChecked: false,
    confirmModal: false,
    title: "",
    searchValue: "",
    fromDate: "",
    toDate: "",
    image: "",
    edition: 'all',
    year: "",
    csiId: "",
    thumbnail: "",
    resourceName: "",
    month: "",
    fromYear: "",
    toYear: "",
    fromMonth: "",
    toMonth: "",
    loading: true,
    date: "",
    uploaded: false,
    fileUploading: false,
    pdfLoading: false,
    pdfUploaded: false
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleGetAllCsiLife = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        search,
        type: "csi_life",
        special_edition: 'all',
        from_date: state.fromDate,
        sort: true,
        to_date: state.toDate,
      };
      const csiLife: any = await getAllPublication(data);
      const doc = csiLife.data.docs.map((docs) => {
        docs.author = docs.created_by?.user_name;
        docs.year = moment(docs?.date)?.format('YYYY');
        docs.month = getMonth(docs?.month);
        docs.specialEdition = docs?.special_edition ? "Special Edition" : "-------------------";
        docs.date = moment(docs?.date).format('DD/MM/YYYY');
        return docs;
      });
      csiLife.data.docs = doc;
      dispatch({
        type: GET_CSI_LIFE,
        payload: csiLife.data
      })
      setState({ loading: false })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to load CsiLife");
    }
  }

  const handleAddCsiLife = async () => {
    try {
      const date = new Date(state.date);
      setState({ edit: false, fromDate: '', toDate: '', date: '' });
      const data = {
        pdf: state.pdf,
        special_edition: state.edition,
        thumbnail: state.thumbnail,
        resource_name: state.resourceName,
        title: state.title,
        type: "csi_life",
        date,
        year: moment(date).format('YYYY'),
        month: moment(date).format('MM')
      }
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        const resource = await addPublication(data);
        setState({ openModal: false, edition: 'all' })
        handleGetAllCsiLife({
          page: 1, limit: state.page * state.limit, search: state.search
      });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to add Csilife");
    }
  }

  const handleEditCsiLife = async (id) => {
    try {
      setState({ openModal: true, edit: true, csiId: id });
      const csiLife: any = await getPublicationById(id);
      if (csiLife && csiLife.data) {
        const { year, thumbnail, pdf, month } = csiLife.data;
        setState({
          year,
          month,
          thumbnail,
          date: moment(csiLife.data.date).format('YYYY-MM-DD'),
          pdf,
          edition: csiLife.data.special_edition,
          resourceName: csiLife.data.resource_name,
          title: csiLife.data.title,
          pdfUploaded: pdf ? true : false,
          uploaded: thumbnail ? true : false
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to load Csilife");
    }
  }

  const handleUpdateCsiLife = async () => {
    try {
      const date = new Date(state.date);
      const data = {
        thumbnail: state.thumbnail,
        pdf: state.pdf,
        resource_name: state.resourceName,
        special_edition: state.edition,
        title: state.title,
        date,
        year: moment(date).format('YYYY'),
        month: moment(date).format('MM')
      }
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        const resource = await editPublication(state.csiId, data);
        handleGetAllCsiLife({
          page: 1, limit: state.page * state.limit, search: state.search
      });
        setState({ openModal: false, edition: false })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to update CsiLife");
    }
  }

  const handleOnDeleteCsiLife = async () => {
    try {
      const resource = await deletePublication(state.csiId);
       handleGetAllCsiLife({
          page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false, searchValue: "" })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to delete CsiLife");
    }
  }

  useEffect(() => {
    handleGetAllCsiLife(state);
  }, [state.search])

  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        sort: true,
        type: "csi_life",
        special_edition: state.edition,
        from_date: state.fromDate,
        to_date: state.toDate,
      };
      const csiLife: any = await getAllPublication(data);
      const doc = csiLife.data.docs.map((docs) => {
        docs.author = docs.created_by?.user_name;
        docs.year = moment(docs?.date).format('YYYY');
        docs.month = getMonth(docs?.month);
        docs.specialEdition = docs?.special_edition ? "Special Edition" : "----------";
        docs.date = moment(docs?.date).format('DD/MM/YYYY');
        return docs;
      });
      csiLife.data.docs = doc;
      csiLife.data.docs = [...csi.docs, ...csiLife.data.docs]
      dispatch({
        type: GET_CSI_LIFE,
        payload: csiLife.data
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to load CsiLife");
    }
  }

  const searchChange = (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  const handleDeleteCsiLife = (id) => {
    setState({ confirmModal: true, csiId: id })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditCsiLife,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteCsiLife,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value, page: 1 })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     page: 1,
    //     search: state.searchValue,
    //     type: "csi_life",
    //     special_edition: "all"
    //   };
    //   const csiLife: any = await getAllPublication(data);
    //   const doc = csiLife.data.docs.map((docs) => {
    //     const date = new Date();
    //     docs.author = docs.created_by.user_name;
    //     docs.year = date.getFullYear();
    //     docs.month = getMonth(date.getMonth());
    //     docs.date = moment().format('YYYY-MM-DD')
    //     return docs;
    //   });
    //   csiLife.data.docs = doc;
    //   dispatch({
    //     type: GET_CSI_LIFE,
    //     payload: csiLife.data
    //   })
    // } catch (err) {
    //   props.throwError(err.data ? err.data.desc : "Unable to search Csilife");
    // }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true })
          const pdf: any = await uploadPdf(e);
          if (pdf) {
            setState({ pdf: pdf.url })
            const fileName = pdf.url.split("/")[4];
            setState({ resourceName: fileName, pdfUploaded: true, pdfLoading: false });
          }
        } else {
          props.throwError("File is not supported! Please Upload Pdf")
        }
      } else {
        setState({ pdfLoading: true })
        const pdf: any = await uploadPdf(e);
        if (pdf) {
          setState({ pdf: pdf.url })
          const fileName = pdf.url.split("/")[4];
          setState({ resourceName: fileName, pdfUploaded: true, pdfLoading: false });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload PDF");
    }
  }

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
          }
        } else {
          props.throwError('File is not Supported Please Upload Jpg, Png, Gif',)
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleApplyFilters = async () => {
    try {
      setState({ page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: state.searchValue,
        type: "csi_life",
        from_date: state.fromDate,
        to_date: state.toDate,
        special_edition: state.edition
      };
      const csiLife: any = await getAllPublication(data);
      const doc = csiLife.data.docs.map((docs) => {
        const date = new Date(docs.date);
        docs.author = docs.created_by.user_name;
        docs.year = date.getFullYear();
        docs.specialEdition = docs.special_edition ? "Special Edition" : "----------";
        docs.month = getMonth(docs.month);
        return docs;
      });
      csiLife.data.docs = doc;
      dispatch({
        type: GET_CSI_LIFE,
        payload: csiLife.data
      })
      setState({ filterModal: false })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to load CsiLife");
    }
  }

  const setEdition = () => {
    if (state.edition === "all" || !state.edition) {
      setState({ edition: true })
    } else {
      setState({ edition: false })
    }
  }

  const handleOnChangeMonth = (selectedOption) => {
    setState({ month: selectedOption.value });
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      limit: state.limit,
      page: state.page + 1,
      search: state.search
    });
  };
  return (
    <div className="csi_life_screen">
      <div className="csi_life_container">
        <div className="csi_life_wrapper">
          <div className="csi_life_header_wrapper">
        <div className="books-title_wrapper">
          <div className="officers_name_wrapper h2">
              Publications
          </div>
        </div>
        <div className="csi_life_action_wrapper">
            <div className="csi_life_search_wrapper">
              <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={(e) => { setState({ search: e.target.value }) }} />
            </div>
            <div className="csi_life_filter_button_wrapper">
              <Button type="primary" text="Filter" onClick={() => setState({ filterModal: true, edition: false })} icon={Filter} />
            </div>
            <div className="csi_life_action_button_wrapper">
              <Button className="csi_life_action_button" type="primary" text="Add Resources" onClick={() => setState({ openModal: true, edit: false, edition: false, date: '', pdfUploaded: false, uploaded: false, title: '' })} icon={PlusIcon} />
            </div>
        </div>
          </div>
          <div className="csi_life_custom_navbar_wrapper">
            <CustomNavBar
              data={PublicationNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddCsiLife}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="officers_table-wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={csiLifeTableHeading}
                    body={csi ? csi.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    hasMore={csi && csi.hasNextPage ? csi.hasNextPage : false}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    actionButton={actionArray}
                    error="No Csi Life is found"
                />
            }
          </div>
          <div>
            <Modal
              open={state.filterModal}
              onClose={() => setState({ filterModal: false, edition: state.edition })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'csi_life_filter_modal'
              }}
              showCloseIcon={false}
            >
              <div className="csi_life_filter_modal_container">
                <div className="csi_life_filter_modal_wrapper">
                  <div className="csi_life_filter_modal_header_wrapper">
                    <div className="csi_life_filter_modal_header h4">
                      Date
                    </div>
                    <div className="csi_life_filter_modal_clear h6" onClick={() => setState({ fromDate: "", toDate: "", edition: 'all' })}>
                      Clear All
                    </div>
                  </div>
                  <div className="csi_life_filter_modal_date_wrapper">
                    <div className="csi_life_filter_modal_name h4">From</div>
                    <div className="csi_life_filter_modal_input_wrapper">
                      <Input name="" type="date" onChange={handleOnChange} inputKey="fromDate" value={state.fromDate} />
                    </div>
                    <div className="csi_life_filter_modal_name h4">To</div>
                    <div className="csi_life_filter_modal_input_wrapper">
                      <Input name="" type="date" onChange={handleOnChange} inputKey="toDate" value={state.toDate} />
                    </div>
                  </div>
                  <div className="csi_life_filter_modal_edition_wrapper" onClick={() => setState({ edition: !state.edition })}>
                    {state.edition === 'all' || state.edition === false
                      ? <div className="csi_life_filter_modal_edition_icon"> </div>
                      : <div className="csi_life_filter_modal_icon_wrapper">
                        <img className="csi_life_filter_modal_icon" alt="select_icon" src={Tick} />
                        </div>}
                    <div className="csi_life_filter_modal_edition_name h4">Special Edition</div>
                  </div>
                  <div className="csi_life_filter_apply_filter_button_wrapper">
                    <div className="csi_life_filter_button">
                      <Button text="Apply Filters" type="primary" onClick={handleApplyFilters} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="csi_life_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'csi_life_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="csi_life_modal_header_wrapper">
                <div className="csi_life_modal_title_wrapper">
                  <div className="csi_life_modal_title h4">{state.edit !== true ? "Add Resources" : "Edit Resources"}</div>
                  <div className="csi_life_modal_icon_wrapper">
                    <img className="csi_life_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="csi_life_modal_line_wrapper"> </div>
              </div>
              <div className="csi_life_modal_input_wrapper">
                <div className="csi_life_modal_input">
                  <Input name="Date" type="date" onChange={handleOnChange} inputKey="date" value={state.date} />
                </div>
                <div className="csi_life_modal_input">
                <Input name="Title" onChange={handleOnChange} inputKey="title" value={state.title} />
                </div>
                <div className="csi_life_modal_input">
                  <div className="csi_life_filter_modal_edition_wrapper" onClick={() => setState({ edition: !state.edition })}>
                    {state.edition
                      ? <div className="csi_life_filter_modal_icon_wrapper">
                        <img className="csi_life_filter_modal_icon" alt="select_icon" src={Tick} />
                        </div>
                      : <div className="csi_life_filter_modal_edition_icon"> </div>}
                    <div className="csi_life_filter_modal_edition_name h4">Special Edition</div>
                  </div>
                </div>
                <div className="csi_life_modal_input">
                  <div className="upload_box_wrapper">
                    Thumbnail
                    <div className="upload_box">
                      <UploadBox name="thumbnail" className="uploadThumbnail" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" onChange={handleUploadImage} loading={state.fileUploading} uploaded={state.uploaded} />
                    </div>
                  </div>
                </div>
                <div className="csi_life_modal_input">
                  <div className="upload_box_wrapper">
                    Upload PDF
                    <div className="upload_box">
                      <UploadBox name="uploadpdf" className="uploadPdf" accept=".pdf,.docx" text="Drag and Drop or" onChange={(e) => handleUploadPDF(e)} loading={state.pdfLoading} uploaded={state.pdfUploaded} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="csi_life_modal_button_wrapper">
                <div className="csi_life_modal_button">
                  <Button text={state.edit ? "Update" : "Add CSI Life"} type="primary" onClick={state.edit ? handleUpdateCsiLife : handleAddCsiLife} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this Csi Life ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteCsiLife} />
        </div>
      </div>
    </div>
  )
}
