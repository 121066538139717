import { GET_EXECUTIVE_COMMITTEE } from "utils/types.utils";
import { storeAction } from "interfaces/common.interface";

const initialState = {};

const executiveReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_EXECUTIVE_COMMITTEE:
      const newState = { ...state, executive_committee: action.payload }
      return newState;

    default:
      return state;
  }
}

export default executiveReducer;
