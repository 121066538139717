import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import Button from "common_components/ui/button/button.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import TextArea from "components/text_area/text_area.component";
import { csitaData, contentTableHeading, csiUserSuggessionData } from "helper/constants.helper";
import EditIcon from "assets/icons/Edit.svg";
import PlusIcon from "assets/icons/Plus_White.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import Table from "components/table/table.component";
import { addUser, getAllUser } from "models/user_management.model";
import Arrow from "assets/icons/Arrow.svg";
import { GET_CSITA_CONTENT, GET_CSITA_OFFICER } from "utils/types.utils";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import { addDescription, deleteDescription, editDescription, getAllDescription, getDescriptionById } from "models/executive.model";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/content.screen.scss";
import { useSetState } from "../../utils/common.utils";

export default function Content(props) {
const dispatch = useDispatch()
const csitaContent = useSelector((state: any) => state.aboutCsi.csitaContent);
  const [state, setState] = useSetState({
    selectedData: "content",
    edit: false,
    openModal: false,
    id: '',
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    added: false,
    description: ""
  });
  const handleGetAllContent = async () => {
    try {
      const data = {
        type: "csita_content"
      }
      const res: any = await getAllDescription(data);
      const details = res.data.map((doc) => {
        doc.desc = doc.description.replace(/(<([^>]+)>)/gi, "");
        return doc
      })
      dispatch({
        type: GET_CSITA_CONTENT,
        payload: details
      })
      setState({ loading: false, description: res.data.description });
    } catch (err) {
      props.throwError("Failed to get Content");
    }
  }
  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChange = (value) => {
    setState({ description: value });
  }

  const handleEditContent = async (id) => {
    setState({ openModal: true, edit: true, id })
    try {
      const res: any = await getDescriptionById(id)
      setState({
        description: res.data.description
      })
    } catch (err) {
      props.throwError('Failed to get details')
    }
  }

  const handleDeleteContent = (id) => {
    setState({ confirmModal: true, id })
  }

  const loadMore = () => {
    // Api Call
  }

  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditContent,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteContent,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = (e) => {
    setState({ searchValue: e.target.value })
  }

  const handleOnDeleteContent = async () => {
    try {
      await deleteDescription(state.id)
      await handleGetAllContent();
      setState({ confirmModal: false, added: false })
    } catch (err) {
      props.throwError("Failed to delete CSITA content");
    }
  }

  const handleAddContent = async () => {
    try {
      const data = {
        description: state.description,
        type: "csita_content"
      };
      await addDescription(data)
      await handleGetAllContent();
      setState({ openModal: false, added: true })
    } catch (err) {
      props.throwError("Failed to add Executive committee");
    }
  }
  const handleUpdateContent = async () => {
    try {
      const data = {
        description: state.description,
        type: "csita_content"
      };
      await editDescription(state.id, data)
      await handleGetAllContent();
      setState({ openModal: false })
    } catch (err) {
      props.throwError("Failed to add Executive committee");
    }
  }

  useEffect(() => {
    handleGetAllContent()
  }, []);
  return (
    <div className="content_screen">
      <div className="content_container">
        <div className="content_wrapper">
          <div className="content_header_wrapper">
            <div className="content_name_wrapper h2">
              <div className="content_nav_icon_wrapper">
                <img className="content_nav_icon" alt="nav_icon" src={Arrow} onClick={() => props.history.push('/about/executive_commitee')} />
              </div>
              CSITA
            </div>
            <div className="content_action_wrapper">
              <div className="content_search_box_wrapper">
                <SearchBar suggession="" placeholder="Search..." suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              {csitaContent && csitaContent.length > 0 ? ''
                : <div className="content_button_wrapper">
                    <Button className="content_button" type="primary" text="Add Content" onClick={() => setState({ openModal: true, edit: false })} icon={PlusIcon} />
                  </div>}
            </div>
          </div>
          <div className="content_custom_navbar_wrapper">
            <CustomNavBar
              data={csitaData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={() => {}}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="content_table-wrapper">
            <Table
              header={contentTableHeading}
              body={csitaContent ? csitaContent : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              loadMore={loadMore}
              actionButton={actionArray}
            />
          </div>
          <div className="content_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'content_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="content_modal_title_wrapper">
                <div className="content_modal_title h4">{state.edit !== true ? "Description" : "Edit Content"}</div>
              </div>
              <div className="content_modal_line_wrapper"> </div>
              <div className="content_modal_text_area_wrapper">
                <TextArea text={state.description} onChange={handleOnChange} />
              </div>
              <div className="content_button_wrapper">
                <div className="content_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateContent : handleAddContent} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteContent} />
        </div>
      </div>
    </div>
  )
}
