import { instance } from '../helper/axios.helper';

export const userLogin = async (data) => {
  try {
    const user = await instance().post('auth/user_login', data);
    return user.data;
  } catch (err) {
    return err;
  }
}
