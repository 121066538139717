import React from 'react';
import './news_card_2.component.scss';
import ArrowRightFill from '../../assets/icons/Arrow_right_fill.svg';

interface NewsCard2Props {
  title: string,
  description: string,
  link: string,
  onClick: any,
  type: string,
  className?: string,
  name?: String,
  reverse?: boolean,
  text: string,
  imgClass?: string
}

const NewsCard2 = (props: NewsCard2Props) => {
  const {
    title, description, link, onClick, type, className, reverse, text, imgClass, name } = props;
  return (
    <div className={`news_card_2_component ${className || ''}`}>
      <div className={`news_card_2_container ${reverse && 'news_card_2_flex_reverse'}`}>
        <div className="news_card_2_wrapper wrapper_small">
          <div className="news_card_2_media">
            <img src={link} alt="news_card_2_media" className={`news_card_2_img ${type === "campaign" && "news_card_2_img_border_right"} ${reverse && "news_card_2_img_border_left"} ${imgClass && imgClass}`} />
          </div>
        </div>
        <div className="news_card_2_wrapper text">
          <div className={`news_card_2_content ${reverse && 'news_card_2_reverse_content'}`}>
            <div className="news_card_2_time_stamp p6">{description}</div>
          {name && <div className="news_card_2_head h5">{name}</div>}
            <div className="news_card_2_head h4">{title}</div>
            <div className="news_card_2_text p5">{text}</div>
            <div className="news_card_2_view">
              <div className="news_card_2_view_text" onClick={onClick}>Read More</div>
              <div className="arrow_icon">
                <img src={ArrowRightFill} alt="arrow_right_fill" className="arrow_right_fill" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewsCard2.defaultProps = {
  className: '',
  reverse: false,
  imgClass: "",
  name: ''
}

export default NewsCard2;
