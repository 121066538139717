import React from 'react';
import './input_box.ui.scss';

interface InputProps {
  value: string,
  onChange: any,
  fontSize?: string,
  marginX?: string,
  marginY?: string,
  className?: string,
  multiline?: boolean,
  name: string,
  inputKey: string,
  type?: string,
  background?: string
}

const Input = (props: InputProps) => {
  const { value, onChange, fontSize, marginX, marginY,
    className, multiline, name, inputKey, type, background } = props;
  return (
    <div className={`input_box_container ${className || ''}`}>
      <div className="input_box_wrapper">
        <div className="styled-input">
          <input
            value={value}
            type={type}
            style={{ fontSize, backgroundColor: background }}
            onChange={(eve) => onChange(eve, inputKey)}
            required
          />
          <div className="label" style={{ fontSize }}>{name}</div>
          <span> </span>
        </div>
      </div>
    </div>
  )
}

Input.defaultProps = {
  fontSize: '',
  marginX: '',
  marginY: '',
  className: '',
  multiline: false,
  type: "text",
  background: ""
}

export default Input;
