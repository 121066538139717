import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import './campaign_view.screen.scss';
import '../../index.scss';
import {
  getCampaignDetails,
  getCampaignDetailsById,
} from 'models/public.model';
import { throwError, useSetState } from 'utils/common.utils';
import { GET_CAMPAIGNS } from 'utils/types.utils';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import Swiper from 'components/swiper/swiper.component';
import Navbar from 'components/navbar/navbar.component';
import ArrowRightFill from '../../assets/icons/Arrow_right_fill.svg';

export default function CampaignViewScreen(props) {
  const { match } = props;
  const campaignDetails = useSelector((state: any) => state.campaigns);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    title: '',
    selectedData: '',
    description: '',
    thumbnail: '',
    activated: 'all',
    gallery: [],
    loading: true,
    diocese: '',
    limit: 20,
    page: 1,
    postedAt: '',
  });

  const handleGetCampaign = async () => {
    try {
      const res: any = await getCampaignDetailsById(match.params.id);
      setState({
        description: res.data.description,
        title: res.data.title,
        thumbnail: res.data.thumbnail,
        gallery: res.data.gallery,
        postedAt: moment(res.data.created_at).format('LLLL'),
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Failed to get Campaign details');
    }
  };
  const handleGetAllCampaignDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: true,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        return doc;
      });
      res.data.docs = details;
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Failed to get related Campaigns');
    }
  };

  useEffect(() => {
    handleGetCampaign();
    handleGetAllCampaignDetails();
    document.getElementById('main')?.scrollIntoView();
  }, [match.params.id]);
  return (
    <div className="screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="header_image_wrapper">
        <div className="image_wrapper">
          <img className="header_image" src={state.thumbnail} alt="header" />
        </div>
      </div>
      <div className="body_wrapper" id="main">
        <div className="header_content_wrapper">
          <div className="header_content">
            <div className="h1 header_title">{state.title}</div>
            <div className="h4 header_title_2">
              CSI SYNOD NEWS | {state.postedAt}
            </div>
          </div>
        </div>
        <div className="header_description_wrapper">
          <div className="header_description">{parse(state.description)}</div>
        </div>
        {/* <div className="action_button_wrapper">
            <div className="button_wrapper">
              <Button
                text="View"
                className="btn"
                onClick={() => {}}
                icon={ArrowRightFill}
              />
            </div>
            <div className="button_wrapper">
              <Button
                text="Donate"
                className="btn"
                onClick={() => {}}
                icon={ArrowRightFill}
              />
            </div>
          </div> */}
        {state.gallery.length > 0 ? (
          <div className="gallery_wrapper">
            <div className="gallery_header_wrapper">
              <div className="heading h1">Gallery</div>
              <div className="head_line" />
            </div>
            <div className="gallery_content_wrapper">
              <Swiper
                types="gallery"
                className="swiper_image"
                data={
                  state.gallery
                }
                number={4}
                orderNow={() => {}}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="campaigns_wrapper">
        <div className="campaigns_wrapper2">
          <div className="campaigns_heading_wrapper">
            <div className="campaign_title h1 heading">More Campaigns</div>
            <div className="head_line" />
          </div>
          <div className="campaign_swiper_wrapper">
            <Swiper
              types="campaigns"
              className="campaign_swiper_image"
              data={
                  campaignDetails && campaignDetails.docs ? campaignDetails.docs.filter(
                      (campaign) => campaign._id !== match.params.id,
                    ) : []
              }
              number={2}
              orderNow={(id) => {
                props.history.push(`/campaign_screen/${id}`);
              }}
            />
          </div>
        </div>
      </div>
      <div className="campaign_footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
