import React, { useState, useRef } from "react";
import "./swiper.component.scss";
import { Modal } from 'react-responsive-modal';
import { useSetState, getMonth, getType } from 'utils/common.utils';
import Carousel from 'react-elastic-carousel';
import moment from "moment"
import NextButton from "assets/icons/Arrow.svg";
import Button from "components/button/button.component";
import Inactive from "assets/icons/Inactive_black.svg";
import Plus from "../../assets/icons/Plus_White.svg";
import ArrowButton from "../../assets/icons/Arrow_right_fill.svg";

interface SwiperProps {
  data: Array<any>,
  orderNow: Function,
  types: string,
  className?: string,
  number: number
}

export default function Swiper(props: SwiperProps) {
  const { data, orderNow, types, className } = props;
  let { number } = props
  if (window.innerWidth <= 1500) {
   number = 3
  } if (window.innerWidth <= 1050) {
   number = 2
  } if (window.innerWidth <= 600) {
    number = 1
  } if (window.innerWidth <= 1050 && types === 'news') {
    number = 1
  } if (window.innerWidth <= 1050 && types === 'campaigns') {
    number = 1
  }

  const [state, setState] = useSetState({ selected_filter: "", limit: 20, page: 1, search: "", activated: "all", openModal: false, subscribeModal: false, name: "", email: "", mobile: "", url: "", index: "" });
  
  const getSrcFile = () => {
    let url = data[state.index] && data[state.index].url ? data[state.index].url : data[state.index]
    return url;
  }

  return (
    <Carousel
      // itemsToShow={number
      //   ? window.innerWidth <= 600 ? 1 : number : window.innerWidth <= 1050 ? 2 : number}
      itemsToShow={number}
      className="swiper_container"
      renderArrow={({ type, onClick }) => <div className="swiper_slider_wrapper" onClick={onClick}>{type === 'PREV' ? <img className="previous_button" alt="" src={ArrowButton} /> : <img className="next_button" alt="" src={ArrowButton} />}</div>}
      pagination={false}
    >
      {data.map((m, index) =>
        <div className={types === 'gallery' ? 'swiper_image_wrapper gallery_image_wrapper' : 'swiper_image_wrapper'} onClick={() => orderNow(m._id)}>
         {types === 'publication' && <div className="publication_overlay" />}
          <img className={`swiper_slider_images ${className}`} key={m.image} alt="SwiperImages" src={m.thumbnail || m.url || m} onClick={() => types === "gallery" ? setState({ openModal: true, index }) : {}} />
         {types === 'campaigns' && <div className="news_card_small_overlay"> </div> }
         {types === 'news' && <div className="news_card_small_overlay"> </div> }
          {types === "publication"
            && <div>
                  <div className="swiper_text h4">{m.title}</div>
                  <div className="swiper_order_wrapper">
                    <div className="swiper_icon-wrapper">
                      <div className="publication_wrapper">
                      <img className="swiper_icon" alt="OrderNowIcon" src={Plus} />
                      </div>
                    </div>
                    <div className="swiper_order h6">Order Now</div>
                  </div>
               </div>}
          {types === "campaigns"
            && <div>
                  <div className="swiper_campaigns_text h6">{moment(m.created_at).format('dddd, MMM Do, YYYY')}</div>
                  <div className="swiper_campaigns_order_wrapper" onClick={() => orderNow()}>
                    <div className="swiper_campaigns_order h3">{m.title}</div>
                  </div>
               </div>}
               {types === "news"
            && <div>
                  <div className="swiper_news_text h6">{moment(m.date).format('dddd, MMM Do, YYYY')}</div>
                  <div className="swiper_news_order_wrapper" onClick={() => orderNow()}>
                    <div className="swiper_news_order h3">{m.title}</div>
                  </div>
               </div>}
          {types === "explore"
            && <div>
                  <div className="swiper_explore_more_wrapper">
                    <div className="swiper_explore_more h4">{m.title}</div>
                  </div>
               </div>}
               
        </div>)}
        { types === "gallery"
       && <div className="swiper_gallery_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                root: 'swiperModalRoot',
                overlay: 'swiperOverlay',
                modal: 'swiperModal',
                closeButton: 'closeButton',
              }}
              styles={{
                modalContainer: {
                  width: '100%',
                  position: 'relative'
                }
              }}
              animationDuration={700}
              showCloseIcon
            >
              <div className="swiper_modal_gallery_container">
                  { state.index > 0
                  && <div className="swiper_modal_left_button" onClick={() => setState({ index: state.index - 1 })}>
                        <img src={NextButton} alt="previous_button" className="left_button" />
                     </div>}
                  <div className="swiper_modal_image_wrapper">
                    <img src={getSrcFile()} className="swiper_banner" alt="advertisement_modal" />
                    <img src={Inactive} className="swiper_modal_close_icon" alt="advertisement_modal" onClick={() => setState({ openModal: false })} />
                    <div className="swiper_modal_information">{state.index + 1} / {data.length}</div>
                  </div>
                  { state.index < data.length - 1
                   && <div className="swiper_modal_right_button" onClick={() => setState({ index: state.index + 1 })}>
                        <img src={NextButton} alt="next_button" className="right_button" />
                      </div>}
              </div>
            </Modal>
          </div>}
    </Carousel>
  )
}

Swiper.defaultProps = {
  className: "",
}
