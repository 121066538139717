import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader = (props: any) => (
  <ContentLoader
    viewBox="0 0 300 100"
    width="100%"
    backgroundColor="white"
    foregroundColor="#dedede"
    style={{ borderRadius: 0 }}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
  </ContentLoader>
)

export default Loader
