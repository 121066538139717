/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import uuid from 'react-uuid';
import { useSetState } from 'utils/common.utils';
import Tick from 'assets/icons/Tick_White.svg';
import Inactive from 'assets/icons/Inactive_Pink.svg';
import Button from '../../common_components/ui/button/button.ui';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import './table.component.scss';

interface headerArray {
  title?: string,
  name: string,
  type?: string,
}

interface btnArray {
  onClick: Function,
  type: string,
  icon: string,
  text: string
}

interface TableProps {
  header: headerArray[],
  body: any[],
  checkedData?: Array<string>,
  isChecked: boolean,
  checkAll: any,
  checkOne: Function,
  loadMore: any,
  hasMore?: boolean,
  error?: string,
  actionButton: btnArray[],
  active?: boolean,
  onClick?: any
}

const Table = (props: TableProps) => {
  const { header, body, isChecked, checkedData,
    checkAll, checkOne, loadMore, hasMore, actionButton, active, error, onClick } = props;
  const [state, setState] = useSetState({ activePage: 1 })
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber });
  }

  return (
    <div className="table_component">
      <div className="table_container">
        <div className="table_wrapper">
          <div className="table_content">
            <table className="table">
              <thead className="thead">
                <tr className="thead_inner_row">
                  {header.map((head, index) => {
                    return (
                      head.type !== "check"
                        ? <th className="thead_text" key={uuid()}>{head.title}</th>
                        : <th className="table_head_check">
                            {isChecked
                              ? <div className="thead_checked" onClick={checkAll}><img className="table_check_icon" src={Tick} alt="check_tick" /></div>
                              : <div className="thead_unchecked" onClick={checkAll}> </div>}
                          </th>)
                  })}
                </tr>
              </thead>
              {body.length !== 0
               ? <tbody className="tbody">
                  {body.map((row, index) =>
                    <tr key={uuid()} className="tbody_inner_row">
                      {
                        header.map((value) =>
                          value.type === "action"
                            ? <td key={uuid()}>
                                <div className="table_action_container">
                                  {actionButton.map((btn) =>
                                    <div className="table_action_btn_container" key={uuid()}>
                                      <ActionButton
                                        text={btn.text}
                                        icon={btn.icon}
                                        type={btn.type}
                                        onClick={() => btn.onClick(row._id || index)}
                                      />
                                    </div>)}
                                  {
                                    active
                                    && <div className="table_action_btn_container" key={uuid()}>
                                          <ActionButton
                                            text={row.activated ? "Make Inactive" : "Make Active"}
                                            icon={row.activated ? Inactive : Tick}
                                            type={row.activated ? "primary" : "secondary"}
                                            onClick={() => onClick(row._id || index)}
                                          />
                                       </div>
                                  }
                                </div>
                              </td>
                            : value.type === "image"
                              ? <td key={uuid()}>
                                  <img className="table_img" src={row[value.name]} alt="table_img" />
                                </td>
                              : value.type === "check"
                                ? <td key={uuid()} className="table_body_check">
                                    {checkedData?.indexOf(row[value.name]) === -1
                                      ? <div className="tbody_unchecked" onClick={() => checkOne(row[value.name])}> </div>
                                      : <div className="tbody_checked" onClick={() => checkOne(row[value.name])}><img className="table_check_icon" src={Tick} alt="check_tick" /></div>}
                                  </td>
                                : value.type === "pdf"
                                  ? <td><a href={row[value.name]} className="download_btn" target="blank" download>Download</a></td>
                                  : <td key={uuid()}>{row[value.name]}</td>
                          )
                        }
                      </tr>
                    )}
                  </tbody>
                : <div className="empty_record">{error}</div>}
            </table>
          </div>
        </div>
        {hasMore
          && <div className="pagination_container">
              <div className="pagination_btn">
                <Button type="primary" text="Load More..." onClick={loadMore} />
              </div>
             </div>}
      </div>
    </div>
  )
}

Table.defaultProps = {
  checkedData: [],
  hasMore: false,
  active: false,
  error: "",
  onClick: ""
}

export default Table;
