import React, { useEffect } from 'react';
import 'screens/diocese/diocese_personnels.screen.scss';
import {
  bannerImageData,
  diocesePersonnelsData,
  DiocesePersonnelsData,
  galleryData,
  personnelsBody,
} from 'helper/constants.helper';
import { Modal } from 'react-responsive-modal';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { uploadMediaFiles, useSetState } from 'utils/common.utils';
import { editDiocese, getDioceseById } from 'models/diocese.model';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Table from 'components/table/table.component';
import _ from 'lodash';
import Button from 'common_components/ui/button/button.ui';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USERS, THROW_ERROR } from 'utils/types.utils';
import { uploadMedia } from 'models/news.model';
import moment from 'moment';
import { toast } from 'react-toastify';
import Input from 'common_components/ui/input_box/input_box.ui';
import {
  addUser,
  deleteUser,
  editUser,
  getAllUser,
  getUserById,
} from 'models/user_management_model';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import InactiveIcon from '../../assets/icons/Inactive_Pink.svg'
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Arrowleft from '../../assets/icons/Arrow.svg';

export default function DiocesePersonnels(props) {
  const dispatch = useDispatch();
  const personnelData = useSelector((state: any) => state.users.user_list);
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'diocese_personnels',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: '',
    limit: 20,
    page: 1,
    name: '',
    designation: '',
    email: '',
    phone: '',
    address: '',
    userType: '',
    sortOrder: '',
    role: '',
    profilePicture: '',
    dioceseName: '',
    personnelId: '',
    loading: true,
    uploaded: false,
    fileUploading: false,
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const suggessionData = [
    {
      icon: PlusIcon,
      name: 'sunil',
    },
    {
      icon: PlusIcon,
      name: 'prakash',
    },
    {
      icon: PlusIcon,
      name: 'Mukunthan',
    },
  ];
  const handleGetAllPersonnels = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        user_sort: true,
        diocese: match.params.id,
        type: 'diocese_personnels',
      };
      const res: any = await getAllUser(data);
      const doc = res.data.map((docs) => {
        docs.diocese = docs.diocese?.name;
        docs.author = docs.created_by?.user_name;
        return docs;
      })
      res.data.docs = doc
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to load Diocese Personnels',
      );
    }
  };
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      setState({
        dioceseName: res.data.name,
      });
    } catch (err) {
      props.throwError(
        err.statusText ? err.statusText : 'Failed to Load DioceseDetails',
      );
    }
  };
  useEffect(() => {
    handleGetDiocese();
    handleGetAllPersonnels();
  }, []);
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleAddPersonnels = async () => {
    try {
      const data = {
        diocese: match.params.id,
        user_name: state.name,
        user_type: 'diocese_personnels',
        phone: state.phone,
        role: state.role,
        address: state.address,
        user_sort: true,
        sort: Number(state.sortOrder),
        email: state.email,
        designation: state.designation,
        profile_picture: state.profilePicture,
      };
      const res:any = await addUser(data);
        await handleGetAllPersonnels();
        setState({ openModal: false, edit: false });
    } catch (err) {
      props.throwError(
        err.status === 422 ? err.data.message : 'Failed to add personnel'
      );
    }
  };
  const loadMore = () => {
    // Api Call
  };

  const searchChange = () => {};

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            profilePicture: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            profilePicture: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const handleEditPersonnels = async (id) => {
    try {
      setState({ openModal: true, edit: true, personnelId: id, phone: "" });
      const res: any = await getUserById(id);
      setState({
        name: res.data.user_name,
        role: res.data.role,
        profilePicture: res.data.profile_picture,
        phone: res.data.phone,
        email: res.data.email,
        sortOrder: res.data.sort,
        address: res.data.address,
        designation: res.data.designation,
        uploaded: res.data.profile_picture ? true : false,
      });
    } catch (err) {
      props.throwError(
        err.status === 422 ? err.data.message : 'Failed to update diocese personnels',
      );
    }
  };
  const handleUpdatePersonnels = async (id) => {
    try {
      const data = {
        user_name: state.name,
        role: state.role,
        phone: state.phone,
        email: state.email,
        sort: Number(state.sortOrder),
        user_sort: true,
        designation: state.designation,
        profile_picture: state.profilePicture,
        address: state.address,
      };
      await editUser(state.personnelId, data);
      await handleGetAllPersonnels();
      setState({ openModal: false });
    } catch (err) {
      props.throwError(
        err.status === 422 ? err.data.message : 'Failed to update personnels',
      );
    }
  };
  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };
  const handleConfirm = (id) => {
    setState({ confirmModal: true, personnelId: id });
  };
  const handleOnDeletePersonnels = async () => {
    try {
      await deleteUser(state.personnelId);
      await handleGetAllPersonnels();
      setState({ confirmModal: false });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to delete diocese personnels',
      );
    }
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditPersonnels,
      icon: Edit,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'banner_images',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'diocese_gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'diocese_personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'diocese_description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'diocese_ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];

  return (
    <div className="diocese_personnels_screen">
      <div className="diocese_personnels_container">
        <div className="diocese_personnels_wrapper">
          <div className="diocese_personnels_header_wrapper">
            <div className="diocese_personnels_navigate_icon_wrapper">
              <div className="diocese_personnels_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="diocese_personnels_header_name_container">
              <div className="diocese_personnels_header_name h3">
                {state.dioceseName}
              </div>
              <div className="diocese_personnels_header_button_wrapper">
                <Button
                  text="Add Personnel"
                  type="primary"
                  icon={PlusIcon}
                  onClick={() => setState({ openModal: true, designation: "", uploaded: false, edit: false, mobile: "", email: "", role: "", address: "", profilePicture: "", name: "", phone: '', sortOrder: '' })}
                />
              </div>
            </div>
          </div>
          <div className="diocese_personnels_navbar_wrapper">
            <CustomNavBar
              data={dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Personnels"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={() => setState({ openModal: true, edit: false })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="diocese_personnels_table_wrapper">
            {state.loading ? <Spinner />
            : <Table
                header={DiocesePersonnelsData}
                body={personnelData ? personnelData.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMore}
                actionButton={actionArray}
                error="No personnels found"
            />}
          </div>
          <div className="">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'modal_container',
                closeButton: 'closeButton',
              }}
              showCloseIcon
            >
              <div className="header_wrapper">
                  <div className="csi_life_modal_icon_wrapper">
                    <img className="csi_life_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                <div className="csi_life_modal_line_wrapper"> </div>
              <div className="modal_title_wrapper">
                <div className="modal_title h4">
                  {state.edit !== true ? 'Add Personnel' : 'Edit Personnel'}
                </div>
              </div>
              <div className="modal_line_wrapper"> </div>
              </div>
              <div className="modal_input_wrapper">
                <div className="modal_input">
                  <Input
                    name="Name"
                    onChange={handleOnChange}
                    type="text"
                    inputKey="name"
                    value={state.name}
                  />
                </div>
                <div className="modal_input">
                  <SelectBox
                    data={diocesePersonnelsData}
                    name="Type"
                    selectedItem={state.role}
                    onChange={(selectedOption) => {
                      setState({ role: selectedOption.value });
                    }}
                  />
                </div>
                <div className="personnel_upload_box_wrapper">
                  Personnel image
                  <div className="upload_box">
                    <UploadBox
                      className="upload"
                      name="banner"
                      accept="image/x-png,image/gif,image/jpeg"
                      text="Drag and Drop or"
                      onChange={handleUploadThumbnail}
                      loading={state.fileUploading}
                      uploaded={state.uploaded}
                    />
                  </div>
                </div>
                <div className="modal_input">
                  <Input
                    name="Designation"
                    onChange={handleOnChange}
                    type="text"
                    inputKey="designation"
                    value={state.designation}
                  />
                </div>
                <div className="modal_input">
                  <TextArea
                    name="Address"
                    onChange={handleOnChange}
                    inputKey="address"
                    value={state.address}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Email"
                    onChange={handleOnChange}
                    type="email"
                    inputKey="email"
                    value={state.email}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Phone"
                    onChange={handleOnChange}
                    type="text"
                    inputKey="phone"
                    value={state.phone}
                  />
                </div>
                <div className="modal_input">
                <Input name="Sort Order" type="number" onChange={handleOnChange} inputKey="sortOrder" value={state.sortOrder} />
                </div>
              </div>
              <div className="csi_user_button_wrapper">
                <div className="csi_user_button">
                  <Button
                    text={state.edit ? 'Update Personnel' : 'Add Personnel'}
                    type="primary"
                    onClick={
                      state.edit ? handleUpdatePersonnels : handleAddPersonnels
                    }
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete Personnel ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleOnDeletePersonnels}
          />
        </div>
      </div>
    </div>
  );
}
