import { instance } from '../helper/axios.helper';

export const addDescription = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`description/create`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllDescription = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`description/get`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getDescriptionById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`description/get/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const editDescription = (id, data) => {
    const promise = new Promise((resolve, reject) => {
        instance().put(`description/edit/${id}`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const deleteDescription = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().delete(`description/delete/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
