import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './diocese_news_user.screen.scss';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { getType, throwError, useSetState } from 'utils/common.utils';
import { GET_DIOCESE_NEWS, GET_DIOCESE, THROW_ERROR, GET_DEPARTMENT, GET_ALL_DEPARTMENTS, GET_ALL_DIOCESES } from 'utils/types.utils';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import NewsCard3 from 'components/news_card_3/news_card_3.component';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import PaginationComponent from 'components/pagination/pagination.component';
import Navbar from 'components/navbar/navbar.component';
import { getAllNews, getAllDioceses, getAllDepartments } from "models/public.model";
import { getAllDepartment } from 'models/department.model';
import { getAllDiocese } from 'models/diocese.model';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Thumbnail from 'assets/images/thumbnail.jpg'

export default function DioceseNewsScreen(props) {
  const { match } = props;
  const diocese = useSelector((state: any) => state.diocese.allDioceses);
  const news = useSelector((state: any) => state.diocese.news);
  const departments = useSelector((state: any) => state.department.allDepartments);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({ filter: "", limit: 21, page: 1, search: "", activated: "all", loading: true });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  const handleGetAllDioceseNews = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: true,
        type: match.params.type,
      };
      const res: any = await getAllNews(data);
      getType(match.params.type, res.data.docs)
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : `Failed to get ${match.params.type} News Details`)
    }
  };
  interface DioceseArray {
    label: string;
    value: string;
  }
  const dioData: DioceseArray[] = [{ label: 'All', value: '' }];
    const dioceseData = diocese && diocese.length !== 0
      && diocese.map((dio) => {
        dioData.push({ label: dio.name, value: dio._id });
        return "";
      });
    const handleGetAllDiocese = async () => {
      try {
        const res: any = await getAllDioceses();
        if (res !== undefined
          && res.data !== undefined) {
          dispatch({
            type: GET_ALL_DIOCESES,
            payload: res.data
          })
          setState({ loading: false })
        }
      } catch (err) {
      throwError(err.data ? err.data.desc : 'Failed to get Diocese Details')
      }
    };
    interface DepartmentArray {
    label: string;
    value: string;
  }
  const deptData: DepartmentArray[] = [{ label: 'All', value: '' }];
    const dept = departments && departments.length !== 0
      && departments.map((department) => {
        deptData.push({ label: department.name, value: department._id });
        return "";
      });
      const handleGetAllDepartment = async () => {
        try {
          setState({ loading: true })
          const department: any = await getAllDepartments();
          if (department !== undefined
            && department.data !== undefined) {
            dispatch({
              type: GET_ALL_DEPARTMENTS,
              payload: department.data
            })
            setState({ loading: false })
          }
        } catch (err) {
          throwError(err.data ? err.data.desc : 'Failed to get Department Details')
        }
      }

  const handleApplyFilter = async (filter) => {
    try {
      if (filter.length > 0) {
        setState({ filter })
        const data = {
          limit: state.limit,
          page: state.page,
          search: state.search,
          type: match.params.type,
          activated: true,
          [match.params.type.slice(0, -1)]: [filter]
        };
        const filteredNews: any = await getAllNews(data);
        getType(match.params.type, filteredNews.data.docs)
        setState({ loading: false })
        dispatch({
            type: GET_DIOCESE_NEWS,
            payload: filteredNews.data
          })
      } else {
        const data = {
          limit: state.limit,
          page: state.page,
          search: state.search,
          type: match.params.type,
          activated: true,
          [match.params.type.slice(0, -1)]: ''
        };
        const filteredNews: any = await getAllNews(data);
        getType(match.params.type, filteredNews.data.docs)
          dispatch({
            type: GET_DIOCESE_NEWS,
            payload: filteredNews.data
          })
      }
    } catch (err) {
      throwError(err.data ? err.data.desc : `Failed to filter ${match.params.type} News`)
    }
  }
  useEffect(() => {
    if (match.params.id) {
      handleApplyFilter(match.params.id)
      handleGetAllDiocese()
      handleGetAllDepartment()
    } else {
      handleGetAllDepartment()
      handleGetAllDioceseNews();
      handleGetAllDiocese()
    }
  }, [state.page])
  return (
    <div className="screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="diocese_news_user_screen_container">
        <div className="diocese_news_user_screen_header_wrapper">
          <div className="diocese_news_user_screen_header h1">
             {match.params.type === 'departments' ? 'CSI Synod' : "Diocese"} News
            <div className="header_line" />
          </div>
        </div>
        <div className="select_box_wrapper">
          <div className="select_box">
            <SelectBox
              data={match.params.type === "departments" ? deptData : dioData}
              name=""
              selectedItem={state.filter}
              onChange={(selectedOption) => {
                handleApplyFilter(selectedOption.value)
                setState({ filter: selectedOption.value })
              }}
            />
          </div>
        </div>
        <div className="diocese_news_user_screen_body_wrapper">
          {state.loading ? <Spinner /> : news && news.docs
          && news.docs.length > 0 ? news.docs.map((m) =>
              <div className="card_wrapper">
                <NewsCard3
                  link={m.thumbnail !== '0' ? m.thumbnail : Thumbnail}
                  title={m.title}
                  type="news_card_1"
                  onClick={() => props.history.push(`/news_screen/${match.params.type}/${m._id}`)}
                  description={m.heading}
                  text={`${match.params.type === "departments" ? m.department_name : m.diocese_name} | ${moment(m.date).format('dddd, MMM D, YYYY')}`}
                />
              </div>
            ) : "No news found"}
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            news && news.totalDocs ? news.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
