import { instance } from '../helper/axios.helper';

export const addSubscriber = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('news_letter/add_subscriber', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const sendNewsletter = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('news_letter/send_news_letter', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllSubscribers = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('news_letter/get_subscriber', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const editSubscriber = (id, data) => {
    const promise = new Promise((resolve, reject) => {
        instance().put(`news_letter/edit_subscriber/${id}`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getSubscriberById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`news_letter/get_subscriber/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const deleteSubscriber = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().delete(`news_letter/delete_subscriber/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
