import React, { useEffect } from 'react';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import { NavLink } from 'react-router-dom';
import Wallpaper from 'assets/images/wallpaper.jpg';
import Arrow from 'assets/icons/Arrow.svg';
import OfficerCard from 'components/officer_card/officer_card.component';
import 'screens/user_screens/secretarial_staff.screen.scss';
import { throwError, useSetState } from 'utils/common.utils';
import { GET_STAFF } from 'utils/types.utils';
import { useDispatch, useSelector } from 'react-redux';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { getAllUser } from 'models/public.model';

export default function SecretarialStaff(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const staffs = useSelector((state: any) => state.aboutCsi.staff);
  const [state, setState] = useSetState({
    selected: false,
  });
  const handleGetAllStaff = async () => {
    try {
      const data = {
        limit: state.limit,
        search: state.search,
        page: state.page,
        user_sort: true,
        type: 'secretarial_staff',
        role: match.params.type,
      };
      const staff: any = await getAllUser(data);
      dispatch({
        type: GET_STAFF,
        payload: staff.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Secretarial staff details")
    }
  };
  useEffect(() => {
    handleGetAllStaff();
  }, [match.params.type]);
  return (
    <div className="officer_screen">
      <div className="secretarial_staff_container">
        <div className="secretarial_staff_wrapper">
          <div className="secretarial_staff_header_wrapper">
            <Header />
          </div>
          <div className="secretarial_staff_navbar_wrapper" style={{ position: 'sticky', top: '0', zIndex: 999 }}>
            <Navbar {...props} />
          </div>
          <div className="secretarial_staff_title_wrapper">
            <div className="secretarial_staff_title h2">Administration</div>
            <div className="secretarial_staff_line_wrapper"> </div>
          </div>
          <div className="secretarial_staff_nav_link_wrapper h1">
            <div className="title_links_wrapper">
              <div className="link h3">
                <NavLink
                  to="/about/officer"
                  className="title_link"
                  activeClassName="selected"
                >
                  Officers
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/about/working_committee"
                  className="title_link"
                  activeStyle={{}}
                >
                  Working Committee
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink to="/about/executive_committee" className="title_link">
                  Executive Committee
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/about/csita"
                  className="title_link"
                  activeStyle={{}}
                >
                  CSITA
                </NavLink>
              </div>
              <div className="link_1 h3">
                <NavLink
                  to="/about/secretarial_staff/Staff Under General Secretary"
                  className="title_link"
                  activeClassName="selected"
                >
                  Secretarial Staff
                  <img
                    src={Arrow}
                    alt="content_icon"
                    className="content_icon"
                    onClick={() => setState({ selected: !state.selected })}
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {state.selected && (
            <div className="staff_filter_wrapper">
              <div className="secretarial_staff_content_dropdown_wrapper">
                <NavLink
                  to="/about/secretarial_staff/Staff Under General Secretary"
                  className="dropdown h4"
                  onClick={() => setState({ selected: false })}
                >
                  Staff Under General Secretary
                </NavLink>
                <NavLink
                  to="/about/secretarial_staff/Staff Under Treasurer Office"
                  className="dropdown h4"
                  onClick={() => setState({ selected: false })}
                >
                  Staff Under Treasurer Office
                </NavLink>
                <NavLink
                  to="/about/secretarial_staff/Staff of CSITA"
                  className="dropdown h4"
                  onClick={() => setState({ selected: false })}
                >
                  Staff of CSITA
                </NavLink>
                <NavLink
                  to="/about/secretarial_staff/Guest House Staff"
                  className="dropdown h4"
                  onClick={() => setState({ selected: false })}
                >
                  Guest House Staff
                </NavLink>
              </div>
            </div>
          )}
          <div className="secretarial_staff_content_wrapper" onClick={() => setState({ selected: false })}>
            <div className="secretarial_staff_content">
              <div className="secretarial_staff_title_wrapper">
                <div className="secretarial_staff_title h2">
                  {match.params.type
                    ? match.params.type
                    : 'Staff Under General Secretary'}
                </div>
                <div className="secretarial_staff_title_line_wrapper"> </div>
              </div>
              {/* <div className="secretarial_staff_header_content_wrapper"> */}
                {/* <div className="secretarial_staff_header_content h3">Head</div> */}
              {/* </div> */}
              {staffs && staffs.filter((staff) => staff.designation === 'Head').map((staff) => {
                  return (
                    <div className="secretarial_staff_head_profile_wrapper">
                      <OfficerCard
                        name={staff.user_name}
                        profilePicture={staff.profile_picture}
                        types="sec_staff"
                        className="secretarial_staff_head_profile"
                      />
                    </div>
                  );
                })}
              <div className="secretarial_staff_staffs_content_wrapper">
                <div className="secretarial_staff_staffs_title h3">Staff</div>
                <div className="secretarial_staff_staffs_image_wrapper">
                  {staffs && staffs.filter((staff) => staff.designation === 'Staff').map((staff) => {
                      return (
                        <div className="secretarial_staff_staffs_image">
                          <OfficerCard
                            name={staff.user_name}
                            className="staffs_image"
                            types="sec_staff"
                            profilePicture={staff.profile_picture}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="secretarial_staff_footer_wrapper">
            <div className="secretarial_staff_footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
