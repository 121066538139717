import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetState } from 'utils/common.utils';
import Button from 'common_components/ui/button/button.ui';
import Select from 'common_components/ui/select_box/select_box.ui';
import './diocese_add_description.screen.scss';
import {
  editDiocese,
  getAllDiocese,
  getDioceseById,
} from 'models/diocese.model';
import { GET_DIOCESE } from 'utils/types.utils';
import Input from 'common_components/ui/input_box/input_box.ui';
import Arrowleft from '../../assets/icons/Arrow.svg';
import AddDescription from './add_description.screen';

const DioceseAddDesc = (props) => {
  const { match } = props;
  const [state, setState] = useSetState({
    description: [],
    selectedData: '',
    desc: '',
    title: '',
    limit: 20,
    page: 1,
  });

  const handleInputChange = (value) => {
    setState({ desc: value });
  };
  const handleGetDioceseById = async (id) => {
    try {
      const res: any = await getDioceseById(id);
      const details = [...res.data.description];
      const doc = details.map((docs) => {
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      setState({
        description: res.data.description,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get details');
    }
  };
  const handleEditDesc = async (id) => {
    try {
      const res: any = await getDioceseById(id);
      const details = [...res.data.description];
      const filter = details.filter(
        (desc, index) => index === props.location.state.data,
      );
      setState({ title: filter[0].title, desc: filter[0].description });
      const doc = details.map((docs) => {
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      setState({
        description: res.data.description,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get details');
    }
  }
  useEffect(() => {
    if (match.params.id) {
      handleEditDesc(match.params.id);
    } else {
      handleGetDioceseById(props.location.state.data);
    }
  }, []);

  const handleAddDescription = async () => {
    try {
      const obj = [{ title: state.title, description: state.desc }];

      const data = {
        description: [...state.description, ...obj],
      };
      const res: any = await editDiocese(props.location.state.data, data);
      if (match.params.id) {
        handleGetDioceseById(match.params.id);
      props.history.push(`/dioceses/diocese_information/description/${match.params.id}`)
      } else {
        handleGetDioceseById(props.location.state.data);
      props.history.push(`/dioceses/diocese_information/description/${props.location.state.data}`)
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to add ');
    }
  };

  const handleUpdateDescription = async () => {
    try {
      const details = [...state.description];
      const filter = details.filter(
        (desc, index) => index === props.location.state.data,
      );
      details[props.location.state.data] = {
        title: state.title,
        description: state.desc,
      };
      const data = {
        description: details,
      };
      const res: any = await editDiocese(match.params.id, data);
      if (match.params.id) {
        handleGetDioceseById(match.params.id);
      props.history.push(`/dioceses/diocese_information/description/${match.params.id}`)
      } else {
        handleGetDioceseById(props.location.state.data);
      props.history.push(`/dioceses/diocese_information/description/${props.location.state.data}`)
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to add ');
    }
  };

  return (
    <div className="add_news_screen">
      <div className="add_news_content">
        <div className="add_news_head">
          <div className="diocese_view_navigate_icon_wrapper">
            <div className="diocese_view_navigate_icon">
              <img
                className="navigate_icon"
                alt=""
                src={Arrowleft}
                onClick={() => {
                  if (match.params.id) {
                    props.history.push(
                      `/dioceses/diocese_information/description/${match.params.id}`,
                    );
                  } else {
                    props.history.push(
                      `/dioceses/diocese_information/description/${props.location.state.data}`,
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className="add_news_head_text h3">
            {match.params.id ? 'Edit Description' : 'Add Description'}
          </div>
          <div className="add_news_btn_container">
            <div className="add_news_btn">
              <Button
                type="primary"
                text={match.params.id ? 'Update' : 'Add'}
                onClick={
                  match.params.id
                    ? handleUpdateDescription
                    : handleAddDescription
                }
              />
            </div>
          </div>
        </div>
        <div className="news_content">
          <div className="input_wrapper">
            <Input
              name="Title"
              inputKey="title"
              onChange={(e) => {
                setState({ title: e.target.value });
              }}
              value={state.title}
            />
          </div>
          <AddDescription
            text={state.desc}
            textAreaOnchange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DioceseAddDesc;
