import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import Button from "common_components/ui/button/button.ui";
import PlusIcon from "assets/icons/Plus_White.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import Table from "components/table/table.component";
import TextArea from "components/text_area/text_area.component";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import { AboutCsiNavData, executiveCommitteeTableHeading, executiveCommitteeBody, csiUserSuggessionData } from "helper/constants.helper";
import _ from "lodash";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import { addUser, getAllUser } from "models/user_management.model";
import { GET_EXECUTIVE_COMMITTEE, GET_WORKING_COMMITTEE } from "utils/types.utils";
import { addDescription, deleteDescription, editDescription, getAllDescription, getDescriptionById } from "models/executive.model";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/executive_committee.screen.scss";
import { useSetState } from "../../utils/common.utils";

export default function ExecutiveCommittee(props) {
  const dispatch = useDispatch();
  const executive = useSelector((state: any) => state.executive_committee.executive_committee);
  const [state, setState] = useSetState({
    selectedData: "executive_committee",
    edit: false,
    openModal: false,
    added: false,
    active: false,
    checkedList: [],
    isChecked: false,
    searchValue: "",
    confirmModal: false,
    limit: 20,
    page: 1,
    userName: "",
    desc: '',
    designation: "",
    profile: "",
    category: "",
    dioceses: "",
    sortOrder: "",
    description: '',
    id: '',
    loading: true,
    committeeId: "",
    imageUploading: false,
    uploaded: false,
    diocese: []
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }
  const handleGetAllCommittee = async () => {
    try {
      const data = {
        type: "executive_committee"
      }
      const res: any = await getAllDescription(data);
      const details = res.data.map((doc) => {
        doc.desc = doc.description.replace(/(<([^>]+)>)/gi, "");
        return doc
      })
      dispatch({
        type: GET_EXECUTIVE_COMMITTEE,
        payload: details
      })
      setState({ loading: false, description: res.data.description });
    } catch (err) {
      props.throwError("Failed to get Committee");
    }
  }
useEffect(() => {
  handleGetAllCommittee()
}, [])
  const handleOnChange = (value) => {
    setState({ description: value });
  }

  const handleEditCommittee = async (id) => {
    setState({ openModal: true, edit: true, id })
    try {
      const res: any = await getDescriptionById(id)
      setState({
        description: res.data.description
      })
    } catch (err) {
      props.throwError('Failed to get details')
    }
  }

  const handleDeleteCommittee = (id) => {
    setState({ confirmModal: true, id })
  }

  const handleAddCommittee = async () => {
    try {
      const data = {
        description: state.description,
        type: "executive_committee"
      };
      await addDescription(data)
      await handleGetAllCommittee();
      setState({ openModal: false, added: true })
    } catch (err) {
      props.throwError("Failed to add Executive committee");
    }
  }

  const loadMore = () => {
    // Api Call
  }

  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditCommittee,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteCommittee,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = (e) => {
    setState({ searchValue: e.target.value })
  }

  const handleUpdateCommittee = async () => {
    try {
      const data = {
        description: state.description,
        type: "executive_committee"
      };
      await editDescription(state.id, data)
      await handleGetAllCommittee();
      setState({ openModal: false })
    } catch (err) {
      props.throwError("Failed to add Executive committee");
    }
  }

  const handleOnDeleteCommittee = async () => {
    try {
      await deleteDescription(state.id)
      await handleGetAllCommittee();
      setState({ confirmModal: false, added: false })
    } catch (err) {
      props.throwError("Failed to delete Executive committee");
    }
  }

  return (
    <div className="executive_committee_screen">
      <div className="executive_committee_container">
        <div className="executive_committee_wrapper">
          <div className="executive_committee_header_wrapper">
            <div className="executive_committee_name_wrapper h2">
              Administration
            </div>
            <div className="executive_committee_action_wrapper">
              <div className="executive_committee_search_box_wrapper">
                <SearchBar suggession="" type="secondary" placeholder="Search..." suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
             {executive && executive.length > 0 ? ''
             : <div className="executive_committee_button_wrapper">
                  <Button className="executive_committee_button" type="primary" text="Add Committee" onClick={() => setState({ openModal: true, edit: false })} icon={PlusIcon} />
               </div>}
            </div>
          </div>
          <div className="executive_committee_custom_navbar_wrapper">
            <CustomNavBar
              data={AboutCsiNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddCommittee}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="executive_committee_table-wrapper">
            <Table
              header={executiveCommitteeTableHeading}
              body={executive ? executive : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              loadMore={loadMore}
              actionButton={actionArray}
            />
          </div>
          <div className="executive_committee_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'executive_committee_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="executive_committee_modal_title_wrapper">
                <div className="executive_committee_modal_title h4">{state.edit !== true ? "Description" : "Edit Committee"}</div>
              </div>
              <div className="executive_committee_modal_line_wrapper"> </div>
              <div className="executive_committee_modal_text_area_wrapper">
                <TextArea text={state.description} onChange={handleOnChange} />
              </div>
              <div className="executive_committee_button_wrapper">
                <div className="executive_committee_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateCommittee : handleAddCommittee} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteCommittee} />
        </div>
      </div>
    </div>
  )
}
