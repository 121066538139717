import React, { useState } from "react";
import _ from 'lodash';
import "./checklist.ui.scss";
import Button from "../button/button.ui";

interface CheckListItemsProps {
  index: Number,
  name: String,
  value: String
}

interface CheckListProps {
  data: Array<Object>,
  selectedData?: Function,
  multiple: Boolean,
  icon: string,
  iconBackground?: String,
  onChange: Function,
  list: CheckListItemsProps[],
  multipleList?: Array<String>
}

export default function CheckList(props: CheckListProps) {
  const { data, selectedData, multiple, icon, iconBackground,
    onChange, list, multipleList } = props;
  return (
    <div className="checklist_container">
      {data.map((listName: any, index) => {
        return (
          <div className="checklist_list_wrapper">
            {multiple === false ? list[0] !== undefined && list[0].index === index
              ? <div className={`radiobutton_checked_wrapper ${iconBackground}`}>
                <img className="checked_icon" alt="" src={icon} />
                </div>
              : <div className={`radiobutton_unchecked_wrapper ${iconBackground}`} onClick={() => onChange({ index, value: listName.value }, multiple,)}> </div>
              : _.findIndex(list, { index }) !== -1
                ? <div className={`radiobutton_checked_wrapper ${iconBackground}`} onClick={() => onChange({ index, value: listName.value }, multiple)}>
                  <img className="checked_icon" alt="" src={icon} />
                  </div>
                : <div className={`radiobutton_unchecked_wrapper ${iconBackground}`} onClick={() => onChange({ index, value: listName.value }, multiple)}> </div>}
            <div className="list_details h4" onClick={() => onChange({ index, value: listName.value }, multiple)}>{listName.name}</div>
          </div>)
      })}
    </div>
  )
}

CheckList.defaultProps = {
  iconBackground: "",
  multipleList: "",
  selectedData: ""
}
