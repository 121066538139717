import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import Button from 'common_components/ui/button/button.ui';
import {
  GET_RESOURCES,
  GET_BOOKS,
  THROW_ERROR,
  GET_ARCHIVES,
} from 'utils/types.utils';
import {
  getAllPublication,
  addPublication,
  getPublicationById,
  editPublication,
  deletePublication,
  uploadMedia,
} from 'models/publication.model';
import {
  PublicationNavData,
  booksTableHeading,
  booksBody,
  csiUserSuggessionData,
} from 'helper/constants.helper';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import Table from 'components/table/table.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import PlusIcon from 'assets/icons/Plus_White.svg';
import EditIcon from 'assets/icons/Edit.svg';
import DeleteIcon from 'assets/icons/Delete.svg';
import Input from 'common_components/ui/input_box/input_box.ui';
import _ from 'lodash';
import Inactive from 'assets/icons/Inactive_Pink.svg';
import InactiveIcon from 'assets/icons/Inactive_black.svg';
import 'screens/publication/library_archives.screen.scss';
import { useSetState, getYear, uploadMediaFiles, uploadPdf } from 'utils/common.utils';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function LibraryArchives(props) {
  const dispatch = useDispatch();
  const libraryArchives = useSelector(
    (state: any) => state.publication.archives,
  );
  const [state, setState] = useSetState({
    selectedData: 'library_archives',
    edit: false,
    openModal: false,
    filterModal: false,
    checkedList: [],
    checkList: [],
    isChecked: false,
    confirmModal: false,
    title: '',
    description: '',
    page: 1,
    limit: 20,
    thumbnail: '',
    pdf: [],
    file: '',
    searchValue: '',
    fromDate: '',
    toDate: '',
    image: '',
    archivesId: '',
    contentImage: '',
    loading: true,
    fileUploading: false,
    uploaded: false,
    contentUploaded: false,
    contentLoading: false,
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const searchChange = () => {};

  const handleGetAllArchives = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        sort: true,
        search,
        type: 'library_archives',
      };
      const archives: any = await getAllPublication(data);
      const doc = archives.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      archives.data.docs = doc;
      dispatch({
        type: GET_ARCHIVES,
        payload: archives.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to load archives');
    }
  };

  const handleAddArchives = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        pdf: state.pdf,
        thumbnail: state.thumbnail,
        type: 'library_archives',
        content_image: state.contentImage,
      };
      if (state.thumbnail === '') {
        toast.error('Please upload thumbnail');
      } else {
        const archives = await addPublication(data);
        await handleGetAllArchives({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to add archives');
    }
  };

  const handleEditArchives = async (id) => {
    try {
      setState({ openModal: true, edit: true, archivesId: id });
      const archives: any = await getPublicationById(id);
      if (archives && archives.data) {
        const { title, description, thumbnail, pdf } = archives.data;
        setState({ title, description, thumbnail, pdf, uploaded: thumbnail ? true : false });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to load archives');
    }
  };

  const handleUpdateArchives = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        pdf: state.pdf,
        thumbnail: state.thumbnail,
      };
      if (state.thumbnail === '') {
        toast.error('Please upload thumbnail');
      } else {
        const archives = await editPublication(state.archivesId, data);
        await handleGetAllArchives({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to update archives');
    }
  };

  const handleOnDeleteArchives = async () => {
    try {
      const resource = await deletePublication(state.archivesId);
      await handleGetAllArchives({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false, searchValue: '' });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to delete archives');
    }
  };

  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        search,
        sort: true,
        type: 'library_archives',
      };
      const archives: any = await getAllPublication(data);
      const doc = archives.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      archives.data.docs = doc;
      archives.data.docs = [...libraryArchives.docs, ...archives.data.docs]
      dispatch({
        type: GET_ARCHIVES,
        payload: archives.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to load archives');
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }

  useEffect(() => {
    handleGetAllArchives(state);
  }, [state.search]);

  const handleDeleteArchives = (id) => {
    setState({ confirmModal: true, archivesId: id });
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditArchives,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDeleteArchives,
      icon: DeleteIcon,
    },
  ];

  const handleOnChangeInSearch = async (e) => {
    // try {
    //   const data = {
    //     limit: state.limit,
    //     page: ,
    //     search: e.target.value,
    //     type: 'library_archives',
    //   };
    //   const archives: any = await getAllPublication(data);
    //   setState({ searchValue: e.target.value });
    //   dispatch({
    //     type: GET_ARCHIVES,
    //     payload: archives.data,
    //   });
    // } catch (err) {
    //   props.throwError(err.data ? err.data.desc : 'Unable to search archives');
    // }
    setState({ search: e.target.value, page: 1 })
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleUploadContentImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ contentLoading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const contentImage: any = await uploadMedia(data);
          if (contentImage) {
            setState({
              contentImage: contentImage.url,
              contentLoading: false,
              contentUploaded: true,
            });
          }
        } else {
          props.throwError('Upload Image Formats jpg, jpeg, png, gif');
        }
      } else {
        setState({ contentLoading: true });
        const data = new FormData();
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const contentImage: any = await uploadMedia(data);
        if (contentImage) {
          setState({
            contentImage: contentImage.url,
            contentLoading: false,
            contentUploaded: true,
          });
        }
      }
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Unable to Upload Content Image',
      );
    }
  };

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Formats jpg, jpeg, png, gif');
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const pdf: any = await uploadPdf(e);
          if (pdf) {
            setState({ pdf: pdf.url });
            const fileName = pdf.url.split('/')[4];
            setState({
              resourceName: fileName,
              pdfUploaded: true,
              pdfLoading: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        setState({ pdfLoading: true });
        const pdf: any = await uploadPdf(e);
        if (pdf) {
          setState({ pdf: pdf.url });
          const fileName = pdf.url.split('/')[4];
          setState({
            resourceName: fileName,
            pdfUploaded: true,
            pdfLoading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload pdf');
    }
  };
  return (
    <div className="library_archives_screen">
      <div className="library_archives_container">
        <div className="library_archives_wrapper">
          <div className="library_archives_header_wrapper">
        <div className="books-title_wrapper">
          <div className="officers_name_wrapper h2">
              Publications
          </div>
        </div>
        <div className="archives_action_wrapper">
            <div className="library_archives_search_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                suggessionData={csiUserSuggessionData}
                onChange={handleOnChangeInSearch}
              />
            </div>
            <div className="library_archives_action_button_wrapper">
              <Button
                className="library_archives_action_button"
                type="primary"
                text="Add Archives"
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    title: '',
                    description: '',
                    thumbnail: '',
                    pdf: [],
                    uploaded: false,
                    fileUploading: false,
                    contentLoading: false,
                    contentUploaded: false,
                  })}
                icon={PlusIcon}
              />
            </div>
        </div>
          </div>
          <div className="library_archives_custom_navbar_wrapper">
            <CustomNavBar
              data={PublicationNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddArchives}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="library_archives_table-wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={booksTableHeading}
                body={libraryArchives ? libraryArchives.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMoreInitial}
                hasMore={
                  libraryArchives && libraryArchives.hasNextPage
                  ? libraryArchives.hasNextPage : false
                }
                actionButton={actionArray}
                error="No Library archives is found"
              />
            )}
          </div>
          <div className="library_archives_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'library_archives_customModal',
                closeButton: 'close_button',
              }}
              showCloseIcon
              // closeIcon={inactiveIcon}
            >
              <div className="library_archives_modal_header_wrapper">
                <div className="library_archives_modal_title_wrapper">
                  <div className="library_archives_modal_title h4">
                    {state.edit !== true ? 'Add Archive' : 'Edit Archive'}
                  </div>
                  <div className="library_archives_modal_icon_wrapper">
                    <img
                      className="library_archives_modal_icon"
                      src={InactiveIcon}
                      alt="close_icon"
                      onClick={() => setState({ openModal: false })}
                    />
                  </div>
                </div>
                <div className="library_archives_modal_line_wrapper"> </div>
              </div>
              <div className="library_archives_modal_input_wrapper">
                <div className="library_archives_modal_input">
                  <Input
                    name="Title"
                    onChange={handleOnChange}
                    inputKey="title"
                    value={state.title}
                  />
                </div>
                <div className="library_archives_modal_input">
                  <TextArea
                    name="Description"
                    onChange={handleOnChange}
                    inputKey="description"
                    value={state.description}
                  />
                </div>
                <div className="library_archives_modal_input">
                  <div className="upload_box_wrapper">
                    Thumbnail
                    <div className="upload_box">
                      <UploadBox
                        name="thumbnail"
                        className="uploadThumbnail"
                        loading={state.fileUploading}
                        uploaded={state.uploaded}
                        multiple={false}
                        accept="image/x-png,image/gif,image/jpeg"
                        text="Drag and Drop or"
                        onChange={handleUploadImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="library_archives_modal_input">
                  <div className="upload_box_wrapper">
                    Pdf
                    <div className="upload_box">
                      <UploadBox
                        name="uploadpdf"
                        className="uploadPdf"
                        accept=".pdf,.docx"
                        text="Drag and Drop or"
                        onChange={(e) => handleUploadPDF(e)}
                        loading={state.pdfLoading}
                        uploaded={state.pdfUploaded}
                      />
                    </div>
                  </div>
                </div>
                {state.file !== '' && state.file !== undefined ? (
                  <div className="library_archives_file_upload_wrapper">
                    <div className="file_upload_file_name">{state.file}</div>
                    <div className="file_upload_inactive_icon-wrapper">
                      <img
                        className="file_upload_delete_icon"
                        src={Inactive}
                        alt="delete_icon"
                        onClick={() => setState({ file: '' })}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="library_archives_modal_button_wrapper">
                <div className="library_archives_modal_button">
                  <Button
                    text={state.edit ? 'Update Archives' : 'Add Archives'}
                    type="primary"
                    onClick={
                      state.edit ? handleUpdateArchives : handleAddArchives
                    }
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete this resource?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleOnDeleteArchives}
          />
        </div>
      </div>
    </div>
  );
}
