import { combineReducers } from 'redux';
import testReducer from 'store/reducers/test.reducer';
import userReducer from 'store/reducers/user.reducer';
import advertisementReducer from 'store/reducers/advertisement.reducer';
import departmentReducer from 'store/reducers/department.reducer';
import publicationReducer from 'store/reducers/publication.reducer';
import aboutCsiReducer from 'store/reducers/about_csi.reducer'
import videoReducer from 'store/reducers/video.reducer';
import newsLetterReducer from 'store/reducers/news_letter.reducer';
import errorReducer from './error.reducer';
import worshipReducer from './worship.reducer';
import dioceseReducer from './diocese.reducer';
import announcementReducer from './announcement.reducer';
import partnerAndNetworkReducer from './partner_and_network.reducer';
import campaignReducer from './campaign.reducer';
import executiveReducer from './executive.reducer';

export default combineReducers({
  test: testReducer,
  users: userReducer,
  errors: errorReducer,
  worship_resources: worshipReducer,
  diocese: dioceseReducer,
  executive_committee: executiveReducer,
  announcements: announcementReducer,
  advertisement: advertisementReducer,
  department: departmentReducer,
  publication: publicationReducer,
  aboutCsi: aboutCsiReducer,
  partner_and_network: partnerAndNetworkReducer,
  video: videoReducer,
  campaigns: campaignReducer,
  newsLetter: newsLetterReducer,
});
