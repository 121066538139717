import { instance } from "helper/axios.helper";

export const getAllPublication = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`publications/get`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getPublicationById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`publications/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const addPublication = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`publications/create`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const editPublication = (id, body) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`publications/edit/${id}`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const deletePublication = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`publications/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const uploadMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/upload_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
