import React, { useEffect } from 'react';
import 'screens/diocese/diocese_ministerial_roll.screen.scss';
import {
  bannerImageData,
  galleryData,
  ministerialRollData,
  personnelsBody,
} from 'helper/constants.helper';
import { Modal } from 'react-responsive-modal';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { uploadMediaFiles, uploadPdf, useSetState } from 'utils/common.utils';
import { editDiocese, getDioceseById, removeMedia } from 'models/diocese.model';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Table from 'components/table/table.component';
import _ from 'lodash';
import Button from 'common_components/ui/button/button.ui';
import { useDispatch } from 'react-redux';
import { THROW_ERROR } from 'utils/types.utils';
import { uploadMedia } from 'models/news.model';
import moment from 'moment';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import InactiveIcon from 'assets/icons/Inactive_Pink.svg';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Arrowleft from '../../assets/icons/Arrow.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";

export default function DioceseMinsterialRoll(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'diocese_ministerial_roll',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    loading: true,
    confirmModal: '',
    newDetails: '',
    removePdf: '',
    details: [],
    id: '',
    uploaded: false,
    fileUploading: false,
    dioceseName: '',
    resourceUrl: '',
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const suggessionData = [
    {
      icon: PlusIcon,
      name: 'sunil',
    },
    {
      icon: PlusIcon,
      name: 'prakash',
    },
    {
      icon: PlusIcon,
      name: 'Mukunthan',
    },
  ];
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      setState({
        dioceseName: res.data.name,
        details: res.data.ministerial_role,
        loading: false,
      });
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };
  useEffect(() => {
    handleGetDiocese();
  }, []);
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };
  const addRole = async () => {
    try {
      const data = {
        ministerial_role: state.newDetails,
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };
  const handleUpdateRole = async (list) => {
    try {
      const data = {
        ministerial_role: list,
      };
      await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };

  const handleDeleteUser = async () => {
    const list = state.details.filter((role, index) => index !== state.id);
    const removeFile = state.details.filter((gal, index) => index === state.id);
    setState({ details: list, removePdf: removeFile[0].url });
    const data = {
      resource_url: state.removePdf
    }
    setState({ confirmModal: false });
    handleUpdateRole(list);
    await removeMedia(data)
  };
  const loadMore = () => {
    // Api Call
  };

  const searchChange = () => {};

  const handleConfirm = (index) => {
    setState({ confirmModal: true, id: index });
  };
  const handleUploadRole = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const pdf: any = await uploadPdf(e);
          const fileName = pdf.url.split('/')[4];
          const obj = [
            {
              url: pdf.url,
            },
          ];
          if (pdf) {
            setState({
              newDetails: obj,
              resourceUrl: fileName,
              uploaded: true,
              fileUploading: false,
              imageUploaded: true,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        setState({ fileUploading: true });
        const pdf: any = await uploadPdf(e);
        const fileName = pdf.url.split('/')[4];
        const obj = [
          {
            url: pdf.url,
            date: moment(new Date()).format('DD-MM-YYYY HH:MM A'),
          },
        ];
        if (pdf) {
          setState({
            newDetails: obj,
            resourceUrl: fileName,
            uploaded: true,
            fileUploading: false,
            imageUploaded: true,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to Upload role');
    }
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'diocese_ministerial_rolls',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'diocese_gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'diocese_personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'diocese_description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'diocese_ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];

  return (
    <div className="diocese_ministerial_roll_screen">
      <div className="diocese_ministerial_roll_container">
        <div className="diocese_ministerial_roll_wrapper">
          <div className="diocese_ministerial_roll_header_wrapper">
            <div className="diocese_ministerial_roll_navigate_icon_wrapper">
              <div className="diocese_ministerial_roll_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="diocese_ministerial_roll_header_name_container">
              <div className="diocese_ministerial_roll_header_name h3">
                {state.dioceseName}
              </div>
              <div className="diocese_ministerial_roll_header_button_wrapper">
                <Button
                  text="Add Role"
                  type="primary"
                  icon={PlusIcon}
                  onClick={() =>
                    setState({
                      openModal: true,
                      edit: false,
                      resourceUrl: '',
                      fileUploading: false,
                      uploaded: false,
                    })}
                />
              </div>
            </div>
          </div>
          <div className="diocese_ministerial_roll_navbar_wrapper">
            <CustomNavBar
              data={dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Personnels"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={() => setState({ openModal: true, edit: false })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="diocese_ministerial_roll_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={ministerialRollData}
                body={state.details ? state.details : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMore}
                actionButton={actionArray}
                error="No ministerial data found"
              />
            )}
          </div>
          <div>
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'diocese_ministerial_roll_modal',
              }}
              showCloseIcon
            >
              <div className="diocese_ministerial_roll_modal_title_wrapper">
                <div className="diocese_ministerial_roll_modal_title h4">
                  {state.edit !== true
                    ? 'Add Ministerial role'
                    : 'Edit Ministerial role'}
                </div>
              </div>
              <div className="diocese_ministerial_roll_modal_line_wrapper">
                {' '}
              </div>
              <div className="diocese_ministerial_roll_modal_input_wrapper">
                <div className="banner_outline_modal_input">
                  <div className="upload_box_wrapper">
                    <div className="upload_box">
                      <UploadBox
                        className="pdf"
                        name="upload_pdf"
                        loading={state.fileUploading}
                        uploaded={state.uploaded}
                        accept=".pdf"
                        text="Drag and Drop or"
                        onChange={handleUploadRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="diocese_ministerial_roll_button_wrapper">
                <div className="diocese_ministerial_roll_button">
                  <Button text="Add" type="primary" onClick={addRole} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete role ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleDeleteUser}
          />
        </div>
      </div>
    </div>
  );
}
