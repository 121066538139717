import React from 'react';
import './resources_card.scss';
import ArrowRightFill from '../../assets/icons/Arrow_right_fill.svg';

interface ResourcesCardProps {
  title: string;
  onClick: any;
  type?: string;
  className?: string;
  hoverText?: string;
  splEdition?: string;
  url: string;
  icon?: string;
  pdfLink?: any;
}

const ResourcesCard = (props: ResourcesCardProps) => {
  const {
    title,
    onClick,
    type,
    className,
    splEdition,
    url,
    icon,
    hoverText,
    pdfLink,
  } = props;

  return (
    <div className="resources_card_wrapper">
      <div className="card">
        <div
          className={`${
            type === 'publications' && 'resource_image'
          } resources_card`}
        >
          {type === 'publications'
          && <div className="img_wrapper">
            <img
              src={url}
              alt="resources"
              className={`${type === 'publications' && 'publication_card'}`}
            />
             </div>}
          {type === 'publications' && (
            <div className="wrapper">
              <a href={pdfLink}>
                <div className="h6 text" onClick={onClick}>
                  <div className="wrapper_icon">
                    {icon && <img src={icon} alt="" className="icon" />}
                    {hoverText}
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div className="title_wrapper">
          {/* <div className="title h4">CSI Life</div> */}
          <div className="title h4">{title}</div>
          <div className="title spl_edition h4">{splEdition}</div>
        </div>
      </div>
    </div>
  );
};

ResourcesCard.defaultProps = {
  className: '',
  type: '',
  splEdition: '',
  icon: '',
  hoverText: '',
  pdfLink: '',
};

export default ResourcesCard;
