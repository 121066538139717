/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import { getAllAdvertisment } from "models/advertisment.model";
import { getAllAdvertismentWithTypes, getAllPromotionsList } from "models/public.model";
import Wallpaper from "assets/images/wallpaper.jpg";
import { GET_ADVERTISEMENT, THROW_ERROR, GET_ADVERTISEMENT_WITH_TYPES } from "utils/types.utils";
import "screens/user_screens/advertisement_user_screen.scss";
import NewsCard3 from "components/news_card_3/news_card_3.component";
import Inactive from "assets/icons/Inactive_black.svg";
import Modal from "react-responsive-modal";
import { throwError, uploadMediaFiles, useSetState } from "../../utils/common.utils";

export default function AdvertisementHome(props) {
  const advertisementData = useSelector((state: any) => state.advertisement.data);
  console.log(advertisementData)
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    title: "",
    url: "",
    order: "",
    advertisement: [],
    id: "",
    limit: 21,
    page: 1,
    search: "",
    thumbnail: "",
    banner: "",
    uploaded: false,
    bannerUploaded: false,
    fileUploading: false,
    bannerUploading: false,
    activated: "all"
  });

  const handleGetAllAdvertismentWithTypes = async () => {
    try {
      const advertisement: any = await getAllPromotionsList();
      console.log(advertisement)
        dispatch({
          type: GET_ADVERTISEMENT,
          payload: advertisement
        })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get advertisements")
    }
  }

  useEffect(() => {
    handleGetAllAdvertismentWithTypes();
  }, []);

  return (
    <div className="advertisement_home_screen">
      <div className="advertisement_home_container">
        <div className="advertisement_home_wrapper">
          <div className="advertisement_home_header_wrapper">
            <Header />
          </div>
          <div className="advertisement_home_navbar_wrapper">
            <Navbar {...props} />
          </div>
          <div className="advertisement_home_body_wrapper">
            <div className="advertisement_home_body">
              <div className="advertisement_home_title_wrapper">
                <div className="advertisement_home_title h1">Advertisement</div>
                <div className="advertisement_home_line_wrapper"> </div>
              </div>
              {/* <div className="advertisement_home_content_title_wrapper h2">Platinum
                 <div className="advertisement_home_content_title_line_wrapper"> </div>
              </div> */}
              <div className="advertisement_home_content_wrapper">
                {
                  advertisementData && advertisementData
                  && advertisementData
                  ? advertisementData.map((advertisement, index) =>
                    <div className="advertisement_home_content">
                      <div className="advertisement_home_thumbnail_wrapper">
                        <img src={advertisement.thumbnail} alt="advertisement_icon" className="advertisement_home_thumbnail" onClick={() => setState({ thumbnail: advertisement.banner_image ? advertisement.banner_image : advertisement.thumbnail, openModal: true, })} />
                      </div>
                      <div className="advertisement_home_thumbnail_title h4">{advertisement.title}</div>
                      <Modal
                        open={state.openModal}
                        onClose={() => setState({ openModal: false })}
                        center
                        classNames={{
                          root: 'swiperModalRoot',
                          overlay: 'swiperOverlay',
                          modal: 'swiperModal',
                          closeButton: 'closeButton',
                        }}
                        styles={{
                          modalContainer: {
                            width: '100%',
                            position: 'relative'
                          }
                        }}
                        animationDuration={700}
                        showCloseIcon
                      >
                        <div className="ad_modal_image_wrapper">
                      <img src={state.thumbnail} alt="" className="ad_modal_image" />
                      <div className="close_button" onClick={() => setState({ openModal: false })}>X</div>
                        </div>
                      </Modal>
                    </div>
                  )
                  : <div className="h3">No Ad is found</div>
                }
              </div>
              {/* <div className="advertisement_home_content_title_gold_wrapper h2">Gold
                    <div className="advertisement_home_content_title_gold_line_wrapper"> </div>
              </div> */}
              {/* <div className="advertisement_home_content_gold_wrapper">
                {
                  advertisementWithTypes && advertisementWithTypes[0]
                  && advertisementWithTypes[0].gold.length !== 0
                  ? advertisementWithTypes[0].gold.map((advertisement, index) =>
                    <div className="advertisement_home_content_gold">
                      <div className="advertisement_home_thumbnail_wrapper">
                        <img src={advertisement.thumbnail} alt="advertisement_icon" className="advertisement_home_thumbnail" onClick={() => setState({ thumbnail: advertisement.thumbnail, openModal: true, })} />
                      </div>
                      <div className="advertisement_home_thumbnail_title h3">{advertisement.title}</div>
                      <Modal
                        open={state.openModal}
                        onClose={() => setState({ openModal: false })}
                        center
                        classNames={{
                          root: 'swiperModalRoot',
                          overlay: 'swiperOverlay',
                          modal: 'swiperModal',
                          closeButton: 'closeButton',
                        }}
                        styles={{
                          modalContainer: {
                            width: '100%',
                            position: 'relative'
                          }
                        }}
                        animationDuration={700}
                        showCloseIcon
                      >
                      <img src={state.thumbnail} alt="" className="ad_modal_image" />
                      </Modal>
                    </div>
                  )
                  : <div className="h3">No Ad is found</div>
                }
              </div> */}
              {/* <div className="advertisement_home_content_title_silver_wrapper h2">Silver
                 <div className="advertisement_home_content_title_silver_line_wrapper"> </div>
              </div> */}
            </div>
          </div>
          <div className="advertisement_home_footer_wrapper">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}
