export const TEST_ACTION = "TEST_ACTION"
export const SHOW_SUCCESS = "SHOW_SUCCESS"
export const THROW_ERROR = "THROW_ERROR"
export const GET_PRAYER_CALENDAR_LIST = 'GET_PRAYER_CALENDAR_LIST';
export const GET_SERMON_OUTLINE_LIST = 'GET_SERMON_OUTLINE_LIST'
export const GET_LECTIONARY_LIST = 'GET_LECTIONARY_LIST'
export const GET_DIOCESE_NEWS_DETAILS = 'GET_DIOCESE_NEWS_DETAILS'
export const GET_DIOCESE_NEWS = 'GET_DIOCESE_NEWS'
export const GET_DIOCESE = 'GET_DIOCESE'
export const GET_SINGLE_DIOCESE = 'GET_SINGLE_DIOCESE'
export const GET_ALL_DIOCESES = "GET_ALL_DIOCESES";
export const GET_DIOCESE_EVENTS = 'GET_DIOCESE_EVENTS'
export const GET_USERS = "GET_USERS";
export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const GET_ADVERTISEMENT_WITH_TYPES = "GET_ADVERTISEMENT_WITH_TYPES";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const ADVERTISEMENT_ERROR = "ADVERTISEMENT_ERROR";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_SINGLE_DEPARTMENT = "GET_SINGLE_DEPARTMENT";
export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";
export const GET_DEPARTMENT_NEWS = "GET_DEPARTMENT_NEWS";
export const GET_DEPARTMENT_EVENTS = "GET_DEPARTMENT_EVENTS";
export const GET_DEPARTMENT_NEWS_DETAILS = "GET_DEPARTMENT_NEWS_DETAILS";
export const GET_RESOURCES = "GET_RESOURCES";
export const GET_CSI_LIFE = "GET_CSI_LIFE";
export const GET_BOOKS = "GET_BOOKS";
export const GET_ONE_BOOK = "GET_ONE_BOOK";
export const GET_ARCHIVES = "GET_ARCHIVES";
export const GET_OFFICERS = "GET_OFFICERS";
export const GET_WORKING_COMMITTEE = "GET_WORKING_COMMITTEE";
export const GET_EXECUTIVE_COMMITTEE = "GET_EXECUTIVE_COMMITTEE";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_STATEMENTS = 'GET_STATEMENTS'
export const GET_PARTNER_AND_NETWORK_DETAILS = 'GET_PARTNER_AND_NETWORK_DETAILS'
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_STAFF = "GET_STAFF";
export const GET_CSITA_OFFICER = "GET_CSITA_OFFICER";
export const GET_CSITA_CONTENT = "GET_CSITA_CONTENT";
export const GET_NEWS = "GET_NEWS";
export const GET_SUBSCRIBER = "GET_SUBSCRIBER";
export const SEND_QUERY = 'SEND_QUERY'
export const SEND_DIOCESE_QUERY = 'SEND_DIOCESE_QUERY'
