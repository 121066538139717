import React, { useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import uuid from 'react-uuid';
import moment from 'moment';
import './synod_video.component.scss';
import 'video.js/dist/video-js.css';
import Video from '../video/video.component';
import Arrow from '../../assets/icons/Arrow_right_fill.svg';
import ArrowLarge from '../../assets/icons/Arrow Line_Right Large Copy 11.svg';

interface SynodProps {
  url: string;
  index: number;
  head: string;
  date: string;
}

interface VideoProps {
  data: any;
}

const SynodVideo = (props: VideoProps) => {
  const { data } = props;

  const arrows = ({ type, onClick }) => {
    return (
      <div onClick={onClick} className="arrow_container">
        {type === 'PREV' ? (
          <img src={ArrowLarge} className="arrow_left" alt="arrow_previous" />
        ) : (
          <img src={ArrowLarge} className="arrow_right" alt="arrow_next" />
        )}
      </div>
    );
  };
const videoLink = (link) => {
  if (link.includes('=')) {
    link = link.split('=').pop()
  } else if (link.includes('/')) {
    link = link.split('/').pop()
  } else {
   return link
  }
  return link
}
  return (
    <div className="synod_video_component">
      <div className="synod_video_container">
        <Carousel
          renderArrow={arrows}
          className="carousel_synod_video"
          pagination={false}
        >
          {data.map((content, i) => (
            <div className="synod_video_wrapper" key={uuid()}>
              <div className="synod_video_doc">
                {content.video_url ? (
                  <Video url={content.video_url} thumbnail={content.thumbnail} />
                ) : (
                  <iframe
                    className="synod_video_content"
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${videoLink(content.youtube_url)}?rel=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                )}
              </div>
              <div className="synod_video_description">
                <div className="synod_video_date p5">
                  {moment(content.created_at).format('dddd ll')}
                </div>
                <div className="synod_video_head h3">{content.title}</div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SynodVideo;
