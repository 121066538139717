import React from 'react';
import Spinner from 'assets/images/loader.gif';
import 'common_components/ui/spinner/spinner.ui.scss';

const spinner = () => {
    return (
        <div className="spinner_component">
            <img src={Spinner} alt="Loading..." />
        </div>
    )
}

export default spinner
