import { instance } from "../helper/axios.helper";

export const getAllAdvertisment = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post("promotions/get", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response)
        } else {
          reject(err)
        }
      });
  });
  return promise;
}

export const getAllAdvertismentWithTypes = () => {
  const promise = new Promise((resolve, reject) => {
    instance().post("promotions/get_all_with_type")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response)
        } else {
          reject(err)
        }
      });
  });
  return promise;
}

export const addAdvertisement = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`promotions/create`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const getAdvertisementById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`promotions/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const editAdvertisement = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`promotions/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const deleteAdvertisment = async (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`promotions/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const uploadMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/upload_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
