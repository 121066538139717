import { instance } from '../helper/axios.helper';

export const addDepartment = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`department/create`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}
export const getAllDepartments = () => {
  const promise = new Promise((resolve, reject) => {
    instance().get('department/get_all')
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getAllDepartment = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`department/get`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getDepartmentById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`department/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const editDepartment = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`department/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const deleteDepartment = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`department/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}
