import React from 'react';
import Wallpaper from 'assets/images/wallpaper.jpg';
import 'components/csi_synod_event_card/csi_synod_event_card.component.scss';

interface SynodEventCardProps {
  date: string;
  month: string;
  toDate?: string;
  endTime?: string;
  time: string;
  name: string;
  eventName: string;
  thumbnail: string;
  endMonth?: string;
  eventDescription: string;
}

export default function SynodEventCard(props: SynodEventCardProps) {
  const {
    date,
    month,
    time,
    name,
    eventName,
    eventDescription,
    thumbnail,
    toDate,
    endTime,
    endMonth,
  } = props;
  return (
    <div className="synod_event_card_container">
      <div className="synod_event_card_wrapper">
        <div className="synod_event_card_image_wrapper">
          <img
            src={thumbnail}
            alt="event_card_thumbnail"
            className="synod_event_card_image"
          />
        </div>
        <div className="synod_event_card_content_wrapper">
          <div className="synod_event_card_date_wrapper">
            <div className="synod_event_card_date h1">{date}</div>
            <div className="synod_event_card_month_time_wrapper">
              <div className="synod_event_card_month h5">{month}</div>
              <div className="synod_event_card_time h6">{time}</div>
            </div>
            <div style={{ padding: '0 1em' }}>-</div>
            <div className="synod_event_card_date h1">{toDate}</div>
            <div className="synod_event_card_month_time_wrapper">
              <div className="synod_event_card_month h5">{endMonth}</div>
              <div className="synod_event_card_time h6">{endTime}</div>
            </div>
          </div>
          <div className="synod_event_card_name h5">{name}</div>
          <div className="synod_event_card_event_name h3">{eventName}</div>
          <div className="synod_event_card_event_description h6">
            {eventDescription}
          </div>
        </div>
      </div>
    </div>
  );
}
SynodEventCard.defaultProps = {
  toDate: '',
  endMonth: '',
  endTime: '',
};
