import React, { useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './about_csi.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import { useSetState } from 'utils/common.utils';
import Navbar from 'components/navbar/navbar.component';
import Logo from '../../assets/images/logo_new.png';
import History from '../../assets/images/history.jpg';
import HistoryFirst from '../../assets/images/First Bishops of CSI.jpg';
import HistorySecond from '../../assets/images/First Bishops of CSI 2.jpg';
import HistoryThird from '../../assets/images/People attend formation of CSI.jpeg';
import HistoryFour from '../../assets/images/St. George’s Cathedral on the Formation Day.jpg';
import HistoryFive from '../../assets/images/New Bishops Consecration 1947.jpg';

export default function AboutCsi(props) {
  const [state, setState] = useSetState({
    active: 'history',
  });
  const handleActiveClass = (title) => {
    setState({
      active: title,
    });
  };
  return (
    <div className="about_csi_screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="about_csi_navbar_wrapper">
          <Navbar {...props} />
        </div>
      </div>
      <div className="about_csi_container">
        <div className="header_wrapper">
          <div className="header h2">About CSI</div>
          <div className="header_line" />
        </div>
        <div className="tab_wrapper">
          <div
            className={
              state.active === 'history' ? 'link_active h3' : 'link h3'
            }
            onClick={() => {
              handleActiveClass('history');
            }}
          >
            <a href="#history">History</a>
          </div>
          <div
            className={state.active === 'vision' ? 'link_active h3' : 'link h3'}
            onClick={() => handleActiveClass('vision')}
          >
            <a href="#vision">Vision</a>
          </div>
          <div
            className={
              state.active === 'mission' ? 'link_active h3' : 'link h3'
            }
            onClick={() => {
              handleActiveClass('mission');
            }}
          >
            <a href="#mission">Mission</a>
          </div>
          <div
            className={state.active === 'logo' ? 'link_active h3' : 'link h3'}
            onClick={() => handleActiveClass('logo')}
          >
            <a href="#logo">Logo</a>
          </div>
        </div>
        <div className="section_scroll_history" id="history" />
        <div className="body_wrapper">
          <div className="section_wrapper">
            <div className="section_body_wrapper">
              <div className="section_heading h2">History</div>
              <div className="header_line line_width" />
              <div className="description_wrapper">
                <div className="description">
                  The Church of South India is the result of the union of
                  churches of varying traditions Anglican, Methodist,
                  Congregational, Presbyterian, and Reformed. It was inaugurated
                  in September 1947, after protracted negotiation among the
                  churches concerned. Organized into 24 dioceses, each under the
                  spiritual supervision of a bishop, the church as a whole is
                  governed by a synod, which elects a moderator (presiding
                  bishop) every 3 years. Episcopacy is thus combined with
                  Synodical government, and the church explicitly recognizes
                  that Episcopal, Presbyterian, and congregational elements are
                  all necessary for the churchs life. The Scriptures are the
                  ultimate standard of faith and practice. The historic creeds
                  are accepted as interpreting the biblical faith, and the
                  sacraments of baptism and the Lords Supper are recognized as
                  of binding obligation.
                </div>
              </div>
              <div className="image_wrapper">
                <img src={History} alt="csi_image" />
              </div>
              <div className="description_wrapper">
                <div className="description">
                  Discussions concerning union had begun at a conference at
                  Tranquebar (now Tarangambadi) in 1919, and in 1947, after
                  India attained independence, the union was completed. The
                  Church of South India has its own service book and communion
                  service, both of which draw from several denominational
                  sources. The union, especially in its reconciliation of the
                  Anglican doctrine of apostolic succession with the views of
                  other denominations, is often cited as a landmark in the
                  ecumenical movement.
                </div>
              </div>
            </div>
            <div className="section_body_wrapper">
              <div className="section_title h3">Formation</div>
              <div className="description_wrapper">
                <div className="description">
                  On 27th September 1947, the General council of Church of
                  India, Pakistan, Burma and Ceylon, General Assembly of South
                  India United Church and South India Provincial Synod of
                  Methodist Church joined together to from the CHURCH OF SOUTH
                  INDIA as the largest united national church in India. The
                  continued growth has been further enriched with the joining of
                  the churches of Basel Mission and the Anglican Diocese of
                  Nandyal. A unique church was born out of the blending of the
                  Episcopal and non - Episcopal traditions as a gift of God to
                  the people of India and as a visible sign of the
                  ecclesiastical unity for the universal church.
                  <div className="gallery_wrapper">
                    <div className="gallery_image">
                      <img src={HistoryFour} alt="gallery_1" />
                    </div>
                    <div className="gallery_image">
                      <img src={HistoryFirst} alt="gallery_1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_body_wrapper">
              <div className="section_title h3">Commitment of The Church</div>
              <div className="description_wrapper">
                <div className="description">
                  Being the largest Protestant church in India, the CSI
                  celebrates her life with Indian culture and spirituality and
                  she also raises her voice for the voiceless on matters of
                  justice, peace and integrity of creation. Sharing the love of
                  Jesus Christ with the people of India through proclamation of
                  the good news of Jesus; responding to human need through
                  institutional and emergency relief work; through community
                  development projects and skill training programmes for the
                  marginalized and disadvantaged sections of the people and
                  programmes for the integrity of creation. Translating this
                  vision and commitment, the Synod of the Church of South India
                  as the apex body of the church endeavors to encourage, equip
                  and empower her dioceses, congregations and institutions
                  through varied ministries.
                  <div className="gallery_wrapper">
                    <div className="gallery_image">
                      <img src={HistoryFive} alt="gallery_1" />
                    </div>
                    <div className="gallery_image">
                      <img src={HistoryThird} alt="gallery_1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section_body_wrapper">
              <div className="section_title h3">The Journey Continues</div>
              <div className="description_wrapper">
                <div className="description">
                  The CSI is a united and uniting church moving forward with a
                  dynamic vision for The Communion of Churches in India. The
                  Church of South India, the Church of North India and the Mar
                  Thoma Church are already members of this commune of Churches
                  and are venturing into wider ecumenical relations to witness
                  to One Lord Jesus Christ.
                </div>
              </div>
            </div>
            <div className="gallery_wrapper">
              <div className="gallery_image">
                <img src={HistorySecond} alt="gallery_1" />
              </div>
            </div>
            <div className="sec_scroll" id="vision" />
          </div>
          <div className="section_wrapper" style={{ paddingTop: 0 }}>
            <div className="section_body_wrapper">
              <div className="section_heading h2">Vision</div>
              <div className="header_line line_width" />
              <div className="description_wrapper">
                <div className="description">
                  The Church of South India (CSI) affirms that the purpose of
                  the union is to fulfill the priestly prayer of Jesus Christ,
                  the Lord of the Church That they all may be one, and that the
                  world may believe that you have sent me. And the Church of
                  South India would become an effective instrument of God&apos;s
                  mission so there will be greater peace, closer fellowship and
                  fuller life in the Church and a renewed commitment for the
                  proclamation of the Gospel of Jesus Christ through word and
                  deed.
                </div>
                <div className="sec_scroll_mission" id="mission" />
              </div>
            </div>
            <div className="section_body_wrapper">
              <div className="section_heading h2">Mission</div>
              <div className="header_line line_width" />
              <div className="description_wrapper">
                <div className="description">
                  The Church of South India affirms that the Church is the
                  Servant of God to carry on the mission rooted in Jesus Christ
                  based on the Scriptures. The Church through her mission
                  expresses solidarity with the broken communities for a new
                  hope to face the challenges of life. The Cross continues to be
                  the sign of hope for the witnessing Church, which strives
                  towards Unity, Peace and Reconciliation as a vibrant Channel
                  of God.
                  <div className="sec_scroll_logo" id="logo" />
                </div>
              </div>
            </div>
            <div className="section_body_wrapper">
              <div className="section_heading h2">Logo</div>
              <div className="header_line line_width" />
              <div className="logo_wrapper">
                <img src={Logo} alt="logo" />
              </div>
              <div className="description_wrapper">
                <div className="description">
                  The Logo of the Church of South India is an important and
                  significant as the formation of the CSI through the process of
                  unification. The Lotus and the Cross used in the Logo possess
                  a rich cultural history and heritage which will help us in the
                  understanding of the significance of the unification and our
                  call and mission as members of this great and unique church.{' '}
                  <br /> <br />
                  The Lotus, a typical Indian flower, in Mythology is supposed
                  to be not only a temple flower but also the seat of the
                  creator. Pankajam one of the Indian names given to the Lotus
                  has a very significant meaning that it is born in mud. The
                  Lotus blooms at sunrise and withers at sunset, in other words
                  it lives as long as it can receive the sunrays hence it is
                  also called Thammipuvu meaning flower of the sun. Whether it
                  means born in the mud or flower of the sun to the Indian
                  Church, it stands to be interpreted as symbolizing the
                  composition, nature and role of the members of the Church of
                  South India in the bond of the union and in the context of
                  being indigenous and also being dependent on the grace of God.
                  <br /> <br />
                  The petals of the Lotus and the arms of the Cross are
                  beautifully intertwined together with the fiery tongues of the
                  Holy Spirit. This again is an authentic Indian expression of
                  peoples communion with God. The colours being red (for life),
                  purple (for piety and ecclesiastical) and the white backdrop
                  (for purity) implicitly communicate the nature of the mystical
                  union, where, an inseparable companionship is established
                  which, again is a typical Indian thought form.
                  <br /> <br /> The words THAT THEY ALL MAY BE ONE, CHURCH OF
                  SOUTH INDIA are embossed in a circle round the Lotus and the
                  Cross. The words, as we know, are taken from the high priestly
                  prayer of our Lord Jesus Christ who prayed not only for the
                  Church but also for the whole world. This universality is
                  expressed by placing the words in a form of a circle
                  symbolizing not only the universe but also the inclusive
                  nature of the Church.
                  <br /> <br /> That they all may be one; as thou, father, art
                  in me, and I in thee that they also may be one in us......
                  (John 17:21) is an inclusive affirmation showing explicitly
                  that Christ is the Head and the Church, His body. It also
                  symbolizes the prayer of the Church that not only Churches
                  need to be united but all people of this country which can be
                  interpreted as a meaningful prayer for national integration.
                  <br /> <br /> The imposing central position of the cross in
                  the Logo conveys the idea that the indefatigable, self less
                  supreme sacrifice of Jesus on the cross is the basis of the
                  Church and its faith. The cross runs through the Lotus
                  depicting its cleansing act working in the hearts of the
                  members of the Church and restoring them to the pristine
                  purity of heaven.
                  <br /> <br /> The four ends of the cross made of equal size
                  symbolizes the equality and also the contextual peaceful
                  co-existence and communal harmony. The Church of South India
                  gratefully acknowledges the initiative taken by the Officers
                  and the Executive Committee Members coinciding with the Silver
                  Jubilee celebration of the CSI in 1972.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
