import { instance } from '../helper/axios.helper';

export const getAllPartnerAndNetworkDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
      instance().post('partner_network/get', data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
    return promise
  }

  export const getPartnerAndNetworkById = (id) => {
    const promise = new Promise((resolve, reject) => {
      instance().get(`partner_network/get/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
    return promise
  }

  export const editPartnerAndNetwork = (id, data) => {
    const promise = new Promise((resolve, reject) => {
      instance().put(`partner_network/edit/${id}`, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
    return promise
  }

  export const addPartnerAndNetwork = (data) => {
    const promise = new Promise((resolve, reject) => {
      instance().post('partner_network/create/', data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
    return promise
  }

  export const deletePartnerAndNetwork = (id) => {
    const promise = new Promise((resolve, reject) => {
      instance().delete(`partner_network/delete/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
    return promise
  }
