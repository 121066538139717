import React from "react";
import { Modal } from "react-responsive-modal";
import Button from "common_components/ui/button/button.ui";
import "components/confirm_modal/confirm_modal.component.scss";
import { useSetState } from "utils/common.utils";

interface ConfirmModalProps {
  description: String,
  openModal: boolean,
  closeModal: Function,
  deleteConfirm: Function
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const { description, openModal, closeModal, deleteConfirm } = props;
  const [state, setState] = useSetState({ confirmModal: "" });
  return (
    <div className="confirmation_modal_container">
      <Modal
        open={openModal}
        onClose={() => setState({ confirmModal: false })}
        center
        classNames={{
          overlay: 'customOverlay', modal: 'confirmModal'
        }}
        showCloseIcon={false}
      >
        <div className="confirmation_modal_wrapper">
          <div className="confirmation_modal_description_wrapper h4">{description}</div>
          <div className="confirmation_modal_button_wrapper">
            <div className="confirmation_modal_button">
              <Button text="Yes" type="secondary" onClick={() => deleteConfirm()} />
            </div>
            <div className="confirmation_modal_button">
              <Button text="No" type="primary" onClick={() => closeModal()} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
