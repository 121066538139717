import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Input from 'common_components/ui/input_box/input_box.ui';
import Button from 'common_components/ui/button/button.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import TitleCard from 'common_components/ui/title_card/title_card.ui';
import {
  csiUserSuggessionData,
  dioceseInfoData,
  dioceseNavData,
  dioceseStateData,
  titleCardData,
} from 'helper/constants.helper';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDiocese,
  deleteDiocese,
  editDiocese,
  getAllDiocese,
  getAllDioceses,
  getDioceseById,
} from 'models/diocese.model';
import { GET_ALL_DIOCESES, GET_DIOCESE, GET_SINGLE_DIOCESE, THROW_ERROR } from 'utils/types.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import NewsCard1 from 'components/news_card_1/news_card_1.component';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import { useSetState } from '../../utils/common.utils';
import 'screens/diocese/diocese_information.screen.scss';
import PlusIcon from '../../assets/icons/Plus_White.svg';

export default function DioceseInformation(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const diocese = useSelector((state: any) => state.diocese.allDioceses);
  const singleDiocese = useSelector((state: any) => state.diocese.singleDiocese);
  const dioceseUser = localStorage.getItem('diocese');
  const [state, setState] = useSetState({
    selectedData: 'diocese_information',
    edit: false,
    openModal: false,
    confirmModal: '',
    diocese: '',
    dioceseData: [],
    diocese_user_id: '',
    dioceseState: '',
    limit: 30,
    page: 1,
    search: '',
    id: '',
    loading: true,
  });
  const handleGetAllDiocese = async () => {
    try {
      const data = {
        limit: 30,
        page: state.page,
        search: state.search,
      };
      if (dioceseUser) {
        const res: any = await getDioceseById(dioceseUser);
        setState({
          diocese_user_id: res.data._id,
          button: res.data._id === dioceseUser ? true : false
        })
        dispatch({
          type: GET_SINGLE_DIOCESE,
          payload: res.data,
        });
        setState({ loading: false });
      } else {
        const res: any = await getAllDioceses();
      dispatch({
        type: GET_ALL_DIOCESES,
        payload: res.data,
      });
      setState({ loading: false });
      }
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };
  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  };

  const handleOnChangeInput = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleDeleteDiocese = async () => {
    try {
      await deleteDiocese(state.id);
      setState({
        confirmModal: false,
      });
      handleGetAllDiocese();
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };

  const handleAddDiocese = async () => {
    try {
      const data = {
        name: state.diocese,
        state: state.dioceseState
      };
      const res: any = await addDiocese(data);
      handleGetAllDiocese();
      setState({
        openModal: false,
      });
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };

  const searchChange = async (e) => {
    setState({ search: e.target.value });
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        // search: {
        //   diocese: e.target.value,
        // }
        search: e.target.value,
      };
      const res: any = await getAllDiocese(data);
      dispatch({
        type: GET_DIOCESE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };

  const handleGetDioceseById = async (id) => {
    try {
      setState({
        openModal: true,
        edit: true,
        id,
      });
      const res: any = await getDioceseById(id);
      setState({
        diocese: res.data.name,
        dioceseState: res.data.state,
      });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Unable to fetch diocese information',
      );
    }
  };
  const handleUpdateDioceseById = async () => {
    try {
      const data = {
        name: state.diocese,
        state: state.dioceseState
      };
      const res: any = await editDiocese(state.id, data);
      handleGetAllDiocese();
      setState({
        openModal: false,
      });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Unable to fetch diocese information',
      );
    }
  };

  useEffect(() => {
    handleGetAllDiocese();
  }, []);
  return (
    <div className="diocese_information_screen">
      <div className="diocese_information_container">
        <div className="diocese_information_navbar_wrapper">
          <CustomNavBar
            data={dioceseNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add Diocese"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={() => setState({
              openModal: true,
              edit: false,
              dioceseState: '',
              diocese: ''
            })}
            button={state.button ? false : true}
            search={state.button ? false : true}
            onSearchChange={searchChange}
          />
        </div>
          {state.loading ? <Spinner />
        : <div className="diocese_information_title_card_wrapper">
          {
          dioceseUser ? singleDiocese
          && <div className="diocese_information_title_card">
                <TitleCard
                  title={singleDiocese.name}
                  onClick={() => { props.history.push(`/dioceses/diocese_information/address/${singleDiocese._id}`) }}
                  editAction={() => handleGetDioceseById(singleDiocese._id)}
                  deleteAction={() => setState({ confirmModal: true, id: singleDiocese._id })}
                />
             </div>
        : diocese.length > 0
            ? diocese.map((title) => (
              <div className="diocese_information_title_card">
              <TitleCard
                title={title.name}
                onClick={() => { props.history.push(`/dioceses/diocese_information/address/${title._id}`) }}
                editAction={() => handleGetDioceseById(title._id)}
                deleteAction={() => setState({ confirmModal: true, id: title._id })}
              />
              </div>
              ))
            : 'No diocese found'
            }
          </div>}
        <div className="diocese_information_modal">
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'diocese_information_modal',
            }}
            showCloseIcon
          >
            <div className="diocese_information_modal_title_wrapper">
              <div className="diocese_information_modal_title h4">
                {state.edit !== true ? 'Add Diocese' : 'Edit Diocese'}
              </div>
            </div>
            <div className="diocese_information_modal_line_wrapper"> </div>
            <div className="diocese_information_modal_select_box_wrapper">
              <SelectBox
                data={dioceseStateData}
                name="Diocese State"
                selectedItem={state.dioceseState}
                onChange={(selectedOption) => {
                  setState({ dioceseState: selectedOption.value });
                }}
              />
            </div>
            <div className="diocese_information_modal_input_wrapper">
              <Input
                name="Diocese"
                inputKey="diocese"
                onChange={handleOnChangeInput}
                value={state.diocese}
                className="diocese_information_modal_input"
              />
            </div>
            <div className="diocese_information_button_wrapper">
              <div className="diocese_information_button">
                <Button
                  text={state.edit ? 'Update' : 'Add'}
                  type="primary"
                  onClick={
                    state.edit ? handleUpdateDioceseById : handleAddDiocese
                  }
                />
              </div>
            </div>
          </Modal>
        </div>
        <ConfirmModal
          openModal={state.confirmModal}
          description="Are you sure want to delete Diocese Information ?"
          closeModal={() => setState({ confirmModal: false })}
          deleteConfirm={handleDeleteDiocese}
        />
      </div>
    </div>
  );
}
