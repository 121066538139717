import React from "react";
import "components/ad_swiper/ad_swiper.component.scss";
import { Carousel } from 'react-responsive-carousel';
import Wallpaper from "assets/images/wallpaper.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface AdswiperProps {
    data: any,
    onClick: any,
    className?: string
}

export default function Adswiper(props: AdswiperProps) {
    const { data, onClick, className } = props;
    return (
        <Carousel
          autoPlay
          infiniteLoop
          stopOnHover={false}
          showThumbs={false}
          showStatus={false}
          showArrows
          showIndicators
          swipeable
          onClickItem={(index, item) => onClick(index, data[index])}
        >
            { data.map((item, index) =>
                <div className="adswiper_component">
                    <div className="adswiper_container">
                        <div className="adswiper_wrapper">
                            <img className={`adswiper_image ${className}`} src={item.thumbnail || item.url} alt="Carousel_swiper" />
                        </div>
                    </div>
                </div>
            )}
        </Carousel>
    )
}

Adswiper.defaultProps = {
    className: ""
}
