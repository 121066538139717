import React, { useEffect } from 'react';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import OfficerCard from 'components/officer_card/officer_card.component';
import 'screens/user_screens/working_committee.screen.scss';
import { GET_EXECUTIVE_COMMITTEE, GET_WORKING_COMMITTEE } from 'utils/types.utils';
import { getAllDescription, getAllUser } from "models/public.model";
import { useDispatch, useSelector } from 'react-redux';
import { throwError, useSetState } from 'utils/common.utils';

export default function WorkingCommittee(props) {
  const dispatch = useDispatch();
  const officer = useSelector(
    (state: any) => state.aboutCsi.workingCommittee,
  );
  const [state, setState] = useSetState({
    search: '',
    limit: 20,
    page: 1,
    loading: true,
    diocese: [],
    description: []
  });

const members = officer && officer.filter((member) => member.role === 'member')
const officers = officer && officer.filter((member) => member.role === 'officer')
const regionalWomen = officer && officer.filter((member) => member.role === 'regional_women')

  const handleGetAllCommittee = async () => {
    try {
      const data = {
        type: "working_committee"
      }
      const res: any = await getAllDescription(data);
      dispatch({
        type: GET_EXECUTIVE_COMMITTEE,
        payload: res.data
      })
      setState({ loading: false, description: res.data[0].description });
    } catch (err) {
        throwError(err.data ? err.data.des : "Failed to get Committee details")
    }
  };

  useEffect(() => {
    handleGetAllCommittee();
  }, []);

  return (
    <div className="officer_screen">
      <div className="working_committee_container">
        <div className="working_committee_wrapper">
          <div className="working_committee_header_wrapper">
            <Header />
          </div>
          <div className="working_committee_navbar_wrapper" style={{ position: 'sticky', top: '0', zIndex: 9999 }}>
            <Navbar {...props} />
          </div>
          <div className="working_committee_title_wrapper">
            <div className="working_committee_title h2">Administration</div>
            <div className="working_committee_line_wrapper"> </div>
          </div>
          <div className="working_committee_nav_link_wrapper h1">
            <div className="title_links_wrapper">
              <div className="link h3">
                <NavLink
                  to="/about/officer"
                  className="title_link"
                >
                  Officers
                </NavLink>
              </div>
              <div className="link_1 h3">
                <NavLink
                  to="/about/working_committee"
                  className="title_link"
                  activeClassName="selected"
                >
                  Working Committee
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/about/executive_committee"
                  className="title_link"
                  activeStyle={{}}
                >
                  Executive Committee
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink to="/about/csita" className="title_link" activeStyle={{}}>
                  CSITA
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/about/secretarial_staff/Staff Under General Secretary"
                  className="title_link"
                  activeStyle={{}}
                >
                  Secretariat Staff
                </NavLink>
              </div>
            </div>
          </div>
          <div className="working_committee_desc_wrapper">
            <div className="working_committee_description">
            {parse(`${state.description}`)}
            </div>
          </div>
          {/* <div className="working_committee_officers_synod_wrapper">
            <div className="working_committee_officers_synod_title_wrapper">
              <div className="working_committee_officers_synod_title h2">
                Officers of the SYNOD
              </div>
              <div className="working_committee_officers_line_wrapper"> </div>
            </div>
          </div> */}
          {/* <div className="working_committee_content_wrapper">
            <div className="working_committee_content">
              <div className="working_committee_officer_wrapper">
                  {officers && officers.map((staff) => {
                      return (
                    <div className="working_committee_card">
                        <OfficerCard
                          types="officers"
                          name={staff.user_name}
                          profession={staff.designation}
                          profilePicture={staff.profile_picture}
                          className="officer_card"
                        />
                    </div>
                      )
                  })}
              </div>
            </div>
          </div>
          <div className="working_committee_members_wrapper">
            <div className="working_committee_members_title_wrapper">
              <div className="working_committee_members_title h2">Members</div>
              <div className="working_committee_members_line_wrapper"> </div>
            </div>
            <div className="working_committee_members_content_wrapper">
              <div className="working_committee_members_content">
              {members && members.map((staff) => {
                      return (
                    <div className="working_committee_member_card">
                        <OfficerCard
                          types="officers"
                          name={staff.user_name}
                          profession={staff.designation}
                          profilePicture={staff.profile_picture}
                          className="member_card_image"
                        />
                    </div>
                      )
                  })}
              </div>
            </div>
          </div>
          <div className="working_committee_members_wrapper">
            <div className="working_committee_members_title_wrapper">
              <div className="working_committee_members_title h2">
                Regional Women
              </div>
              <div className="working_committee_members_line_wrapper"> </div>
            </div>
            <div className="working_committee_members_content_wrapper">
              <div className="working_committee_members_content">
              {regionalWomen && regionalWomen.map((staff) => {
                      return (
                    <div className="working_committee_member_card">
                        <OfficerCard
                          types="officers"
                          name={staff.user_name}
                          profession={staff.designation}
                          profilePicture={staff.profile_picture}
                          className="member_card_image"
                        />
                    </div>
                      )
                  })}
              </div>
            </div>
          </div> */}
          <div className="working_committee_footer_wrapper">
            <div className="working_committee_footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
