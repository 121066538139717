import React from 'react';
import './button.ui.scss';

interface ButtonProps {
  icon?: string,
  text: string,
  color?: string,
  textColor?: string,
  type: string,
  width?: string,
  height?: string,
  className?: string,
  fontSize?: string,
  onClick: any
}

const Button = (props: ButtonProps) => {
  const { icon, text, color, textColor, type, width, height,
    className, fontSize, onClick } = props;
  return (
    <div className={`button_container ${className || ''}`} onClick={onClick}>
      {type === "custom"
        ? <div className="button_wrapper" style={{ backgroundColor: color }}>
          {icon
            && <div className="button_icon_container"><img className="button_icon" src={icon} alt="plus_icon" /></div>}
            <div className="button_name p5" style={{ fontSize, color: textColor, width, height }}>{text}</div>
          </div>
        : <div className={`button_wrapper ${type === "primary" ? "btn_primary" : 'btn_secoundary'}`}>
          {icon
            && <div className="button_icon_container"><img className="button_icon" src={icon} alt="plus_icon" /></div>}
            <div className="button_name p5" style={{ fontSize, color: textColor, width, height }}>{text}</div>
          </div>}
    </div>
  )
}

Button.defaultProps = {
  textColor: '',
  width: "",
  height: "",
  className: "",
  icon: '',
  color: "",
  fontSize: ""
}

export default Button;
