import React, { useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './publication.screen.scss';
import '../../index.scss';
import Pagination from 'react-js-pagination';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import ResourcesCard from 'components/resources_card/resources_card';
import PaginationComponent from 'components/pagination/pagination.component';
import Navbar from 'components/navbar/navbar.component';
import { GET_BOOKS } from 'utils/types.utils';
import { getAllPublication } from "models/public.model";
import { useDispatch, useSelector } from 'react-redux';
import { throwError, useSetState } from "utils/common.utils";
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Shop from '../../assets/icons/Shop.svg'

export default function PublicationScreen(props) {
  const dispatch = useDispatch();
  const books = useSelector((state: any) => state.publication.books)
  const [state, setState] = useSetState({
    loading: true,
    limit: 21,
    page: 1,
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };

  const handleGetAllBooks = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        sort: true,
        search: "",
        type: "books",
        activated: true,
      };
      const res: any = await getAllPublication(data);
      const doc = res.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      })
      res.data.docs = doc;
      dispatch({
        type: GET_BOOKS,
        payload: res.data
      })
      setState({ loading: false })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Publication details")
    }
  }
  useEffect(() => {
    handleGetAllBooks()
  }, [state.page])
  return (
    <div className="screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
          <Navbar {...props} />
        </div>
      </div>
      <div className="publication_screen_container">
        <div className="publication_screen_header_wrapper">
          <div className="publication_screen_header h2">
            Publications
            <div className="header_line" />
          </div>
        </div>
        <div className="publication_screen_body_wrapper">
          {state.spinner ? <Spinner /> : books && books.docs.length > 0 ? books.docs.map((book) => {
            return (
          <div className="publication_card_wrapper">
              <ResourcesCard onClick={() => { props.history.push(`/publication_details/${book._id}`) }} icon={Shop} hoverText="Order" title={book.title} type="publications" url={book.thumbnail} />
          </div>
            )
          }) : "No Resources found"}
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            books && books.totalDocs ? books.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
