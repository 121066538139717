import React from 'react';
import Div100vh from 'react-div-100vh';
import Input from 'common_components/ui/input_box/input_box.ui';
import Button from 'common_components/ui/button/button.ui';
import { toast } from 'react-toastify';
import { userLogin } from 'models/auth.model';
import { useSetState } from '../../utils/common.utils';
import ActivePassword from '../../assets/icons/Password_Active.svg';
import InactivePassword from '../../assets/icons/Password_Inactive.svg';
import ActiveEmail from '../../assets/icons/Username_Active.svg';
import InactiveEmail from '../../assets/icons/Username_Inactive.svg';
import Background from "../../assets/images/Footer.png";
import Logo from '../../assets/images/CSI_Logo.png';
import './login.screen.scss';

const Login = (props) => {
  const [state, setState] = useSetState({ email: "", password: "" });

  const handleInputChange = (eve, key) => {
    setState({ [key]: eve.target.value });
  }

  const login = async () => {
    try {
      const user = await userLogin(state);
      if (user.response && user.response.status === 422) {
        toast.error(user.response ? user.response.data.message : "Invalid credentials")
      } else {
        localStorage.setItem('token', user.token);
        localStorage.setItem('role', user.role);
        localStorage.setItem('user', user.data._id);
        if (user.role === "department_user") {
          localStorage.setItem('department', user.data.department);
          window.location.href = '/departments';
        } else if (user.role === 'diocese_user') {
          localStorage.setItem('diocese', user.data.diocese);
          window.location.href = '/dioceses';
        } else {
          localStorage.removeItem('diocese');
          localStorage.removeItem('department');
          window.location.href = '/users';
        }
      }
    } catch (err) {
      toast.error(err ? err.message : "Invalid credentials")
    }
  }

  return (
    <Div100vh>
      <div className="login_screen" style={{ backgroundImage: `url(${Background})` }}>
        <div className="login_container">
          <div className="login_wrapper login_detail">
            <div className="login_detail_container">
              <div className="login_head_container">
                <div className="login_intro h1">Welcome</div>
                <div className="login_desc p5">Please Login to Access Admin Panel</div>
              </div>
              <div className="login_input_container">
                <div className="login_input_box">
                  <img className="input_icon" src={ActiveEmail} alt="input_icon" />
                  <div className="input">
                    <Input value={state.email} fontSize="13px" onChange={handleInputChange} background="transparent" name="Email" inputKey="email" />
                  </div>
                </div>
                <div className="login_input_box">
                  <img className="input_icon" src={ActivePassword} alt="input_icon" />
                  <div className="input">
                    <Input value={state.password} fontSize="13px" onChange={handleInputChange} background="transparent" name="Password" inputKey="password" type="password" />
                  </div>
                </div>
              </div>
              <div className="login_btn_container">
                <div className="login_btn">
                  <Button text="Login" onClick={login} type="primary" />
                </div>
              </div>
            </div>
          </div>
          <div className="login_wrapper">
            <div className="intro_content">
              <div className="intro_logo_container">
                <img className="csi_logo" alt="login_logo" src={Logo} />
              </div>
              <div className="intro_head_container">
                <div className="intro_head">Church of South India</div>
              </div>
              <div className="intro_description_text">
                <div className="intro_description p4">
                  That they all may be one, and that the world may believe that you have send me
                </div>
              </div>
              <div className="intro_note">
                <div className="intro_note_text p4">
                  -John 17:21
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  )
}

export default Login;
