import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import "screens/CSIvideos/csi_videos.screen.scss";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Spinner from "common_components/ui/spinner/spinner.ui";
import Table from "components/table/table.component";
import Input from "common_components/ui/input_box/input_box.ui";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import EditIcon from "assets/icons/Edit.svg";
import PlusIcon from "assets/icons/Plus_White.svg";
import Inactive from "assets/icons/Inactive_Pink.svg";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import Tick from "assets/icons/Tick_White.svg";
import Button from "common_components/ui/button/button.ui";
import { toast } from "react-toastify";
import DeleteIcon from "assets/icons/Delete.svg";
import { csiVideoTableHeading, csiUserSuggessionData, advertisementSelectBoxData } from "helper/constants.helper";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import { uploadMediaFiles, useSetState } from "../../utils/common.utils";
import { GET_VIDEOS, THROW_ERROR } from "../../utils/types.utils";
import { getAllVideo, addVideo, getVideoById, editVideo, deleteVideo, uploadMedia, removeMedia } from '../../models/video.model';

export default function CsiVideo(props) {
  const videoData = useSelector((state: any) => state.video);
  const dispatch = useDispatch();

  const [state, setState] = useSetState({
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    fileUploaded: false,
    fileUploading: false,
    videoUploaded: false,
    videoUploading: false,
    order: '',
    title: "",
    description: "",
    youtube_url: "",
    video_url: "",
    thumbnail: "",
    page: 1,
    limit: 20,
    search: "",
    activated: "all",
    filterPage: 1,
    id: ""
  });

  const getVideos = async ({ page, limit, search }) => {
    try {
      const videos: any = await getAllVideo({ page, limit, search, activated: state.activated });
      dispatch({
        type: GET_VIDEOS,
        payload: videos.data
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get videos");
    }
  }

  const loadMore = async ({ page, limit, search }) => {
    try {
      const videos: any = await getAllVideo({ page, limit, search, activated: state.activated });
      videos.data.docs = [...videoData.docs ? videoData.docs : [], ...videos.data.docs];
      dispatch({
        type: GET_VIDEOS,
        payload: videos.data
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get videos");
    }
  }

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }

  useEffect(() => {
    getVideos(state);
  }, []);

  useEffect(() => {
    getVideos(state);
  }, [state.activated, state.search]);

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const getVideoData = () => {
    return {
      title: state.title,
      description: state.description,
      video_url: state.video_url,
      youtube_url: state.youtube_url,
      thumbnail: state.thumbnail,
      order: state.order
    }
  }

  const setVideoState = (data) => {
    setState({
      title: data ? data.title : "",
      description: data ? data.description : "",
      video_url: data ? data.video_url : "",
      youtube_url: data ? data.youtube_url : "",
      thumbnail: data ? data.thumbnail : "",
      id: data ? data._id : "",
      order: data ? Number(data.order) : '',
      fileUploaded: false,
      fileUploading: false,
      videoUploaded: false,
      videoUploading: false,
    })
  }

  const handleEditVideo = async (id) => {
    try {
      setState({ openModal: true, edit: true })
      const video: any = await getVideoById(id);
      setVideoState(video.data);
      setState({
        fileUploaded: video.data.thumbnail ? true : false,
        videoUploaded: video.data.video_url ? true : false
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get video");
    }
  }

  const handleDeleteVideo = async (id) => {
    setState({ confirmModal: true, id })
  }

  const handleMakeInactive = async (id) => {
    try {
      const video: any = await getVideoById(id);
      const activated = !video.data.activated;
      await editVideo(id, { activated });
      getVideos({ page: 1, limit: state.page * state.limit, search: state.search });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to update video");
    }
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditVideo,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteVideo,
      icon: DeleteIcon
    }
  ];

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleOnChangeInSearch = (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ activated: selectedOption.value, page: 1 });
  }

  const handleUploadFile = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              fileUploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Formats jpg, jpeg, png, gif');
        }
      } else {
        setState({ videoUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            fileUploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  }

  const handleUploadVideo = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /\.(mp4|MP4|mov|MOV)$/;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
        setState({ bannerUploading: true })
        const data = new FormData();
        const ext = e.dataTransfer.files[0].name.split('.').pop();
        const filename = e.dataTransfer.files[0].name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.dataTransfer.files[0]], newFileName);
        data.append('file', newFile);
        const banner: any = await uploadMedia(data);
        if (banner) {
          setState({ video_url: banner.url, videoUploaded: true, videoUploading: false })
        }
      } else {
        props.throwError("File is not supported! Please Upload Video");
      }
      } else {
        setState({ bannerUploading: true })
        const data = new FormData();
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const banner: any = await uploadMedia(data);
        if (banner) {
          setState({ video_url: banner.url, videoUploaded: true, videoUploading: false })
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Video");
    }
  }

  const handleOnDeleteVideo = async () => {
    try {
      const video = await deleteVideo(state.id);
      setState({ confirmModal: false });
      getVideos({ page: 1, limit: state.page * state.limit, search: state.search });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to delete video");
    }
  }

  const updateVideo = async () => {
    if (state.youtube_url !== "" && state.video_url !== "") {
      toast.error("Enter either one video link, Youtube url or upload video")
    } else if (state.youtube_url === "" && state.video_url === "") {
      toast.error("Atleast enter one video link, Youtube url or upload video ")
    } else {
      try {
        const video = await editVideo(state.id, getVideoData());
        setState({ openModal: false });
        setVideoState(undefined);
        getVideos({ page: 1, limit: state.page * state.limit, search: state.search });
      } catch (err) {
        props.throwError(err.data ? err.data.desc : "Failed to update video");
      }
    }
  }

  const createVideo = async () => {
    if (state.youtube_url !== "" && state.video_url !== "") {
      toast.error("Enter either one video link, Youtube url or upload video")
    } else if (state.youtube_url === "" && state.video_url === "") {
      toast.error("Atleast enter one video link, Youtube url or upload video ")
    } else {
      try {
      const video = await addVideo(getVideoData());
        setState({ openModal: false })
        setVideoState(undefined);
        getVideos({ page: 1, limit: state.page * state.limit, search: state.search });
      } catch (err) {
        props.throwError(err.data ? err.data.desc : "Failed to add video");
      }
    }
  }
  const removeVideo = async () => {
    try {
      const data = {
        resource_url: state.video_url,
      };
      await removeMedia(data);
      setState({ video_url: '', videoUploaded: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to remove video")
    }
  }
  const openAddVideo = () => {
    setVideoState(undefined);
    setState({ openModal: true, edit: false });
  }

  return (
    <div className="csi_videos_screen">
      <div className="csi_videos_container">
        <div className="csi_videos_wrapper">
        <div className="csi_videos_header_wrapper">
            <div>
              <h1>CSI Videos</h1>
            </div>
          <div className="csi_video_wrapper">
          <div className="csi_videos_search_box_wrapper">
              <SearchBar suggession="" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
          </div>
          <div className="csi_videos_select_box_wrapper">
              <SelectBox data={advertisementSelectBoxData} name="" selectedItem={state.activated} onChange={handleOnChangeInSelectBox} />
          </div>
            <div className="csi_videos_button_wrapper">
              <Button type="primary" text="Add Video" onClick={() => openAddVideo()} icon={PlusIcon} />
            </div>
          </div>
        </div>
          <div className="csi_videos_table_wrapper">
            {state.loading ? <Spinner />
              : <Table
                  header={csiVideoTableHeading}
                  body={videoData.docs ? videoData.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  error="Videos not found"
                  hasMore={videoData.hasNextPage}
                  active
                  onClick={handleMakeInactive}
                  actionButton={actionArray}
              />}
          </div>
          <div className="csi_videos_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'csi_videos_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="csi_videos_modal_header_wrapper">
                <div className="csi_videos_modal_title_wrapper">
                  <div className="csi_videos_modal_title h4">{state.edit !== true ? "Add Videos" : "Edit Videos"}</div>
                  <div className="csi_videos_modal_icon_wrapper">
                    <img className="csi_videos_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="csi_videos_modal_line_wrapper"> </div>
              </div>
              <div className="csi_videos_modal_input_wrapper">
                <div className="csi_videos_modal_input">
                  <Input name="Title" onChange={handleOnChange} inputKey="title" value={state.title} />
                </div>
                <div className="csi_videos_modal_input">
                <Input name="Youtube URL" onChange={handleOnChange} inputKey="youtube_url" value={state.youtube_url} />
                </div>
                <div className="csi_videos_modal_input">
                <Input name="Sort Order" type="number" onChange={handleOnChange} inputKey="order" value={state.order} />
                </div>
                <div className="csi_videos_modal_input">
                  <div className="csi_videos_modal_label_wrapper">Or</div>
                </div>
                <div className="csi_videos_modal_input">
                  <div className="upload_box_wrapper">
                    Upload Videos
                    <div className="upload_box">
                      <UploadBox name="video" className="uploadvideo" loading={state.videoUploading} uploaded={state.videoUploaded} accept="video/*" text="Drag and Drop or" onChange={handleUploadVideo} />
                    </div>
                  </div>
                </div>
                {state.video_url !== "" && state.video_url !== undefined
                  ? <div className="video_file_upload_wrapper">
                    <div className="file_upload_file_name">{state.video_url}</div>
                    <div className="file_upload_inactive_icon-wrapper">
                      <img className="file_upload_delete_icon" src={Inactive} alt="delete_icon" onClick={() => removeVideo()} />
                    </div>
                    </div>
                  : null}
                <div className="csi_videos_modal_input">
                  <div className="upload_box_wrapper">
                      Thumbnail
                      <div className="upload_box">
                        <UploadBox name="thumbnail" loading={state.fileUploading} uploaded={state.fileUploaded} className="uploadThumbnail" accept="image/*" text="Drag and Drop or" onChange={handleUploadFile} />
                      </div>
                  </div>
                </div>
              </div>
              <div className="csi_videos_modal_button_wrapper">
                <div className="csi_videos_modal_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={() => state.edit ? updateVideo() : createVideo()} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteVideo} />
        </div>
      </div>
    </div>
  )
}
