import React from "react";
import "./subscribe.component.scss";

interface SubscribeProps{
    onClick: any
}

export default function Subscribe(props:SubscribeProps) {
    const { onClick } = props;
    return (
        <div className="subscribe_container">
            <div className="subscribe_wrapper">
                <div className="subscribe_heading_wrapper">
                    <div className="subscribe_heading p2">CSI SYNOD Newsletter</div>
                </div>
                <div className="subscribe_line_wrapper">
                    <div className="subscribe_line"> </div>
                </div>
                <div className="subscribe_description_wrapper">
                    <div className="subscribe_description h4">Subscribe to receive the latest information from CSI SYNOD in your Inbox !</div>
                </div>
                <div className="subscribe_input_wrapper" onClick={onClick}>
                    <div className="subscribe_button p3">Subscribe</div>
                </div>
            </div>
        </div>
    )
}
