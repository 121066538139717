import React from 'react';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import ImageIcon from 'assets/icons/Image.svg';
import Input from 'common_components/ui/input_box/input_box.ui';
import TextArea from '../../components/text_area/text_area.component';
import './add_description.screen.scss';

interface AddNewsProps {
  text: any,
  textAreaOnchange: any,

}

const AddDescription = (props: AddNewsProps) => {
  const { text, textAreaOnchange, } = props;
  return (
    <div className="add_news_component">
      <div className="add_news_container">
        <div className="add_news_wrapper">
          <TextArea text={text} onChange={textAreaOnchange} />
        </div>
      </div>
    </div>
  )
}

export default AddDescription;
