import { storeAction } from "interfaces/common.interface";
import { GET_USERS } from "utils/types.utils";

const userReducer = (state = {}, action: storeAction) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        user_list: action.payload
      };
    default:
      return state;
  }
}
export default userReducer;
