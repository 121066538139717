import React from 'react';
import './button.component.scss';

interface ButtonProps {
  text: String;
  className?: String;
  onClick: any;
  icon?: string;
}

const Button = (props: ButtonProps) => {
  const { text, onClick, className, icon } = props;

  return (
    <div className={`button_component ${className}`} onClick={onClick}>
        {icon && (
          <div className="icon">
            <img src={icon} alt="" />
          </div>
        )}
        <div className="text h5">{text}</div>
    </div>
  );
};
Button.defaultProps = {
  icon: '',
  className: ''
};
export default Button;
