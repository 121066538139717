import { storeAction } from "interfaces/common.interface";
import { GET_PARTNER_AND_NETWORK_DETAILS } from "utils/types.utils";

const initialState = {}

const partnerAndNetworkReducer = (state = initialState, action: storeAction) => {
    switch (action.type) {
        case GET_PARTNER_AND_NETWORK_DETAILS:
            return { ...state, partner_network: action.payload }
        default:
            return state
    }
}
export default partnerAndNetworkReducer;
