import { instance } from '../helper/axios.helper';

export const getAllNews = (body) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_news_list', body)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const getAllDiocesesBySubDomain = (subDomain) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_diocese_by_sub_domain', subDomain)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const getAllDepartmentsBySubDomain = (subDomain) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_department_by_sub_domain', subDomain)
        .then((res) => resolve(res.data))
        .catch((err) => {
            if (err.response) {
                reject(err.response)
            } else {
                reject(err)
            }
        })
    })
    return promise
}
export const getNews = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_news/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllEvents = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`public/get_events_list`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getEventsById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_event/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllVideo = (body) => {
    const promise = new Promise((resolve, reject) => {
        instance().post("public/get_videos_list", body)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            });
    });
    return promise;
}

export const getVideoById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_video/:id/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err)
                }
            })
    })
    return promise;
}

export const getAllPublication = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`public/get_publications_list`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getPublicationById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_publication/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getCampaignDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`public/get_campaigns_list`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getCampaignDetailsById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_campaign/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllAdvertismentWithTypes = () => {
    const promise = new Promise((resolve, reject) => {
        instance().post("public/get_promotions_list")
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            });
    });
    return promise;
}

export const getAdvertisementById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_promotion/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err)
                }
            })
    })
    return promise;
}

export const getAllDepartments = () => {
    const promise = new Promise((resolve, reject) => {
        instance().get('public/get_departments_list')
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getDepartmentById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_department/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllDioceses = () => {
    const promise = new Promise((resolve, reject) => {
        instance().get('public/get_diocese_list')
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getDioceseById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_diocese/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const globalSearch = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`public/global_search_news`, data)
        .then((res) => resolve(res.data))
        .catch((err) => {
            if (err.response) {
                reject(err.response)
            } else {
                reject(err)
            }
        })
    })
    return promise
}

export const getAllDescription = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`public/get_descriptions_list`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getDescriptionById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_description/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllPartnerAndNetworkDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_partners_and_networks_list', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    })
    return promise
}

export const getPartnerAndNetworkById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_partners_and_networks/${id}`)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    })
    return promise
}

export const getPrayerCalendarList = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_prayer_calendars_list', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    })
    return promise
}

export const getPrayerCalendarDetails = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`public/get_prayer_calendars/${id}`)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    })
    return promise
}

export const getAllDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance()
            .post('public/get_statements_list', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            });
    });
    return promise;
};

export const getStatementById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance()
            .get(`public/get_statement/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            });
    });
    return promise;
};

export const addSubscriber = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/add_subscriber', data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const getAllUser = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/get_users_list', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    })
    return promise
}

export const sendQuery = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/add_querys', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    });
    return promise;
  };

  export const getMoreRelateNews = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/more_related_news', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    });
    return promise;
  };
  export const orderPublication = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post('public/place_order', data)
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    });
    return promise;
  };
export const getAllPromotionsList = () => {
    const promise = new Promise((resolve, reject) => {
        instance().get('public/get_all_promotions_list')
            .then((res) => resolve(res.data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response)
                } else {
                    reject(error)
                }
            })
    });
    return promise;
}
