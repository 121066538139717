import { storeAction } from "interfaces/common.interface";
import { GET_PRAYER_CALENDAR_LIST, GET_LECTIONARY_LIST, GET_SERMON_OUTLINE_LIST } from "utils/types.utils";

const worshipReducer = (state = {}, action: storeAction) => {
   switch (action.type) {
      case GET_PRAYER_CALENDAR_LIST:
         return {
            ...state,
            prayer_calendar_list: action.payload
         };
      case GET_SERMON_OUTLINE_LIST:
         return {
            ...state,
            sermon_outline_list: action.payload
         };
      case GET_LECTIONARY_LIST:
         return {
            ...state,
            csi_lectionary_list: action.payload
         };
      default:
         return state;
   }
}
export default worshipReducer;
