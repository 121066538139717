import React from "react";
// import Carousel from 'react-elastic-carousel';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import ArrowButton from "../../assets/icons/chevron-backward-1.svg";
import ArrowButton2 from "../../assets/icons/chevron-forwardBOX.svg";
import ArrowRight from '../../assets/icons/Arrow_right.svg'
import "./news_card_small.scss";

interface NewsProps {
  className?: string,
  url: string,
  date: string,
  text: string,
  name?: string,
  data: any,
  Click: any,
  types: string
}

export default function NewsCardSmall(props: NewsProps) {
  const { className, url, date, text, data, Click, types, name } = props;
  return (
    <Carousel
      className="swiper_news_card_container"
      infiniteLoop
      stopOnHover={false}
      autoPlay
      showThumbs={false}
      showStatus={false}
      showArrows
      showIndicators
      swipeable
    //   onClickItem={() => console.log(index)}
    >
      {data.map((m, index) =>
        <div className="news_card_small_component" onClick={() => types === "synodNews" ? { } : Click(m._id)}>
          <div className={types === 'synodNews' ? 'news_card_small_container' : 'news_card_small_padding news_card_small_container'}>
            <div className="news_card_small_wrapper">
              <div className="news_card_small_image_container">
                <img src={m.thumbnail} alt="news_card_small_img" className={types === "synodNews" ? `synod_news_card_small_image ${className}` : `news_card_small_image ${className}`} />
              </div>
              <div className={types === "synodNews" ? "synod_news_card_small_overlay small_overlay" : "news_card_small_overlay small_overlay"}> </div>
              {
                types === "synodNews"
                  ? <div className="synod_news_text_block_container">
                        <div className="synod_news_text_block_wrapper">
                          <div className="synod_news_date_wrapper h6">{moment(m.date).format('dddd, MMM D, YYYY')}</div>
                          <div className="synod_news_title heading_synod h2">{m.title}</div>
                        <div className="synod_news_title h5 dept_name">{m.department_name}</div>
                          <div className="synod_news_button_wrapper h6" onClick={() => Click(m._id)}>
                            <div className="synod_news_button_title h6">Read More Synod News</div>
                          </div>
                        </div>
                    </div>
                     : <div className="text_block_container">
                          <div className="text_block_wrapper">
                            <div className="text_wrapper">
                            <div className="date_wrapper">{moment(m.date).format('dddd, MMM D, YYYY')}</div>
                              <div className="text h4 dept_head">{m.title}</div>
                              <div className="synod_news_title h5">{m.department_name}</div>
                            </div>
                          </div>
                       </div>
              }
            </div>
          </div>
        </div>
      )}
    </Carousel>
  )
}

NewsCardSmall.defaultProps = {
  className: "",
  name: ''
}
