import React from 'react';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import ImageIcon from 'assets/icons/Image.svg';
import Button from 'common_components/ui/button/button.ui';
import TextArea from '../text_area/text_area.component';
import './add_news.component.scss';

interface AddNewsProps {
  text: any,
  thumbnail: string,
  gallery: string[],
  type?: string,
  textAreaOnchange: any,
  uploadThumbnail: any,
  removeGallery: Function,
  removeThumbnail: any,
  loading?: boolean,
  uploaded?: boolean,
  pdfUpload : Function,
  uploadGallery: Function
}

const AddNews = (props: AddNewsProps) => {
  const { text, thumbnail, gallery, textAreaOnchange, uploadThumbnail,
    removeThumbnail, uploadGallery, removeGallery, pdfUpload, type, loading, uploaded } = props;
  return (
    <div className="add_news_component">
      <div className="add_news_container">
        <div className="add_news_wrapper">
          <TextArea text={text} onChange={textAreaOnchange} />
        </div>
        <div className="add_news_wrapper add_news_small_wrapper">
          <div className="add_news_form_control">
            <div className="add_news_label_container">
              <div className="add_news_lable_icon">
                <img src={ImageIcon} alt="image_icon" />
              </div>
              <div className="add_news_lable_text h5">Upload Thumbnail</div>
            </div>
            {thumbnail
              ? <div className="tumbnail_content">
                  <div className="img_wrap">
                    <div className="clear h6" onClick={removeThumbnail}>X</div>
                    <img className="news_thumbnail" alt="news_thumbnail" src={thumbnail} />
                  </div>
                </div>
              : <div className="add_news_thumbnail_input">
                  {/* <UploadBox
                    name="thumbnail"
                    className="UploadThumbnail"
                    accept="image/x-png,image/gif,image/jpeg"
                    text="Drag and Drop"
                    onChange={uploadThumbnail}
                  /> */}
                   <Button
                     type="primary"
                     text="Add thumbnail"
                     className="crop_btn"
                     onClick={uploadThumbnail}
                   />
                </div>}
                {type === 'news'
                && <div className="add_news_label_container">
                <div className="upload_box_wrapper">
                  Upload PDF
                  <div className="add_news_thumbnail_input">
                    <UploadBox
                      className="pdf"
                      name="upload_pdf"
                      loading={loading}
                      uploaded={uploaded}
                      accept=".pdf"
                      text="Drag and Drop or"
                      onChange={pdfUpload}
                    />
                  </div>
                </div>
                   </div>}
          </div>
          <div className="add_news_form_control space_between">
            <div className="add_news_label_container">
              <div className="add_news_lable_icon">
                <img src={ImageIcon} alt="image_icon" />
              </div>
              <div className="add_news_lable_text h5">Upload Gallery Images</div>
            </div>
            {gallery.length < 12
              && <div className="add_news_thumbnail_input">
                  <UploadBox name="gallery" className="uploadGallery" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop" onChange={uploadGallery} />
                 </div>}
            {gallery && gallery.length > 0
              && gallery.map((doc, index) =>
                <div className="tumbnail_content gallery_content">
                  <div className="img_wrap gallery_image_wrap">
                    <div className="clear h6" onClick={() => removeGallery(index)}>X</div>
                    <img className="news_thumbnail" alt="news_thumbnail" src={doc} />
                  </div>
                </div>)}
          </div>
        </div>
      </div>
    </div>
  )
}
AddNews.defaultProps = {
  type: '',
  loading: false,
  uploaded: false,
}
export default AddNews;
