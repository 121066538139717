import { GET_DIOCESE_NEWS_DETAILS, GET_DIOCESE_NEWS, GET_DIOCESE, GET_DIOCESE_EVENTS, GET_ALL_DIOCESES, GET_SINGLE_DIOCESE } from "utils/types.utils";
import { storeAction } from "interfaces/common.interface";

const initialState = {}

const dioceseReducer = (state = initialState, action: storeAction) => {
    switch (action.type) {
        case GET_DIOCESE_NEWS_DETAILS:
            return { ...state, news_details: action.payload }
        case GET_DIOCESE_NEWS:
            return { ...state, news: action.payload }
        case GET_DIOCESE:
            return { ...state, diocese: action.payload }
        case GET_DIOCESE_EVENTS:
            return { ...state, events: action.payload }
        case GET_SINGLE_DIOCESE:
            return { ...state, singleDiocese: action.payload }
        case GET_ALL_DIOCESES:
            return { ...state, allDioceses: action.payload }
        default:
            return state
    }
}

export default dioceseReducer
