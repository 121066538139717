import React from 'react';
import './filter.component.scss';
import News from "../../assets/icons/Tab_Bar/Grey/News.svg";
import Campaign from "../../assets/icons/Tab_Bar/Grey/Campaign.svg";
import Publication from "../../assets/icons/Tab_Bar/Grey/Publication.svg";
import Resources from "../../assets/icons/Tab_Bar/Grey/Resources.svg";
import Videos from "../../assets/icons/Tab_Bar/Grey/Video.svg";
import NewsFill from "../../assets/icons/Tab_Bar/Violet/News.svg";
import CampaignFill from "../../assets/icons/Tab_Bar/Violet/Campaign.svg";
import PublicationFill from "../../assets/icons/Tab_Bar/Violet/Publication.svg";
import ResourcesFill from "../../assets/icons/Tab_Bar/Violet/Resources.svg";
import VideosFill from "../../assets/icons/Tab_Bar/Violet/Video.svg";

interface FilterProps {
  selected?: string,
  onClick: any
}

export default function Filter(props: FilterProps) {
  const { selected, onClick } = props;
  return (
    <div className="filter_component">
      <div className="filter_container">
        <div className={selected === "news" ? "selected_filter_wrapper h5" : "filter_wrapper"} onClick={() => onClick("news")}>
          <div className="filter_img">
            <img src={selected === "news" ? NewsFill : News} alt="news_filter" className="filter_img" />
          </div>
          <div className={selected === "news" ? `filter_text_selected h4` : `filter_text`}>News</div>
        </div>
        <div className={selected === "videos" ? "selected_filter_wrapper" : "filter_wrapper"} onClick={() => onClick("videos")}>
          <div className="filter_img">
            <img src={selected === "videos" ? VideosFill : Videos} alt="news_filter" className="filter_img" />
          </div>
          <div className={selected === "videos" ? "filter_text_selected h4" : `filter_text`}>Videos</div>
        </div>
        <div className={selected === "resources" ? "selected_filter_wrapper" : "filter_wrapper"} onClick={() => onClick("resources")}>
          <div className="filter_img">
            <img src={selected === "resources" ? ResourcesFill : Resources} alt="news_filter" className="filter_img" />
          </div>
          <div className={selected === "resources" ? "filter_text_selected h4" : "filter_text"}>Resources</div>
        </div>
        <div className={selected === "publications" ? "selected_filter_wrapper" : "filter_wrapper"} onClick={() => onClick("publications")}>
          <div className="filter_img">
            <img src={selected === "publications" ? PublicationFill : Publication} alt="news_filter" className="filter_img" />
          </div>
          <div className={selected === "publications" ? "filter_text_selected h4" : "filter_text"}>Publication</div>
        </div>
        <div className={selected === "campaigns" ? "selected_filter_wrapper" : "filter_wrapper"} onClick={() => onClick("campaigns")}>
          <div className="filter_img">
            <img src={selected === "campaigns" ? CampaignFill : Campaign} alt="news_filter" className="filter_img" />
          </div>
          <div className={selected === "campaigns" ? "filter_text_selected h4" : "filter_text"}>Campaign</div>
        </div>
      </div>
    </div>
  )
}

Filter.defaultProps = {
  selected: "",
}
