import PlusIcon from 'assets/icons/Plus_White.svg';
import TableImage from 'assets/images/wallpaper.jpg';

export const userNavData = [
  {
    navName: 'CSI User',
    navValue: 'csi_user',
    navUrl: '/users',
  },
  {
    navName: 'Diocese User',
    navValue: 'diocese_user',
    navUrl: '/users/diocese_user',
  },
  {
    navName: 'Department User',
    navValue: 'department_user',
    navUrl: '/users/department_user',
  },
];
export const dioceseStateData = [
  {
    label: 'Andhra',
    value: 'Andhra',
  },
  {
    label: 'Karnataka',
    value: 'Karnataka',
  },
  {
    label: 'Kerala',
    value: 'Kerala',
  },
  {
    label: 'Tamil Nadu',
    value: 'Tamil Nadu',
  },
  {
    label: 'Telengana',
    value: 'Telengana',
  },
  {
    label: 'Jaffna',
    value: 'Jaffna',
  },
];

export const departmentNavData = [
  {
    navName: 'Dept. News',
    navValue: 'department_news',
    navUrl: '/departments/department_news',
  },
  {
    navName: 'Dept. Events',
    navValue: 'department_events',
    navUrl: '/departments/department_events',
  },
  {
    navName: 'Dept. Information',
    navValue: 'department_information',
    navUrl: '/departments/department_information',
  },
];
export const dioceseNavData = [
  {
    navName: 'Diocese News',
    navValue: 'diocese_news',
    navUrl: '/dioceses/diocese_news',
  },
  {
    navName: 'Diocese Events',
    navValue: 'diocese_events',
    navUrl: '/dioceses/diocese_events',
  },
  {
    navName: 'Diocese Information',
    navValue: 'diocese_information',
    navUrl: '/dioceses/diocese_information',
  },
];
export const workshipAndResourcesNavData = [
  {
    navName: 'Prayer Calendar',
    navValue: 'prayer_calendar',
    navUrl: '/worship_resources',
  },
  {
    navName: 'Sermon Outlines',
    navValue: 'sermon_outlines',
    navUrl: '/worship_resources/sermon_outlines',
  },
  {
    navName: 'CSI Lectionary',
    navValue: 'csi_lectionary',
    navUrl: '/worship_resources/csi_lectionary',
  },
];

export const AboutCsiNavData = [
  {
    navName: 'Officers',
    navValue: 'officers',
    navUrl: '/about',
  },
  {
    navName: 'Working Committee',
    navValue: 'working_commitee',
    navUrl: '/about/working_commitee',
  },
  {
    navName: 'Executive Committee',
    navValue: 'executive_committee',
    navUrl: '/about/executive_commitee',
  },
  {
    navName: 'CSITA',
    navValue: 'csita',
    navUrl: '/about/csita/content',
  },
  {
    navName: 'Secretarial Staff',
    navValue: 'secretarial_staff',
    navUrl: '/about/secretarial_staff',
  },
];

export const PublicationNavData = [
  {
    navName: 'Resources',
    navValue: 'resources',
    navUrl: '/publications',
  },
  {
    navName: 'CSI Life',
    navValue: 'csi_life',
    navUrl: '/publications/csi_life',
  },
  {
    navName: 'Books',
    navValue: 'books',
    navUrl: '/publications/books',
  },
  {
    navName: 'Library Archives',
    navValue: 'library_archives',
    navUrl: '/publications/library_archives',
  },
];

export const csitaData = [
  {
    navName: 'Content',
    navValue: 'content',
    navUrl: '/about/csita/content',
  },
  {
    navName: 'CSITA Officers',
    navValue: 'csita_officers',
    navUrl: '/about/csita/csita_officers',
  },
];

export const NewsLetterNavData = [
  {
    navName: 'Newsletter',
    navValue: 'newsletter',
    navUrl: '/news_letter/departments',
  },
  {
    navName: 'Subscriber',
    navValue: 'subscriber',
    navUrl: '/subscriber',
  },
];

export const NewsNavData = [
  {
    navName: 'Department',
    navValue: 'departments',
    navUrl: '/news_letter/departments',
  },
  {
    navName: 'Diocese',
    navValue: 'dioceses',
    navUrl: '/news_letter/dioceses',
  },
  {
    navName: 'All',
    navValue: 'all',
    navUrl: '/news_letter/all',
  },
];

export const AnnouncementNavData = [
  {
    navName: 'Govt. Notification Schemes',
    navValue: 'govt',
    navUrl: '/announcements/govt_notification_schemes/govt',
  },
  {
    navName: 'Press Release',
    navValue: 'press',
    navUrl: '/announcements/press_release/press',
  },
  {
    navName: 'Statements',
    navValue: 'statement',
    navUrl: '/announcements/statement/statement',
  },
];

export const departmentViewData = [
  {
    navName: 'Aims and Objectives',
    navValue: 'aims_and_objectives',
    navUrl: '/departments/department_information/aimsandobjectives',
  },
  {
    navName: 'Personnels',
    navValue: 'personnels',
    navUrl: '/departments/department_information/personnels',
  },
];

export const dioceseViewData = [
  {
    navName: 'Address',
    navValue: 'address',
    navUrl: '/dioceses/diocese_information/address',
  },
  {
    navName: 'Banner Images',
    navValue: 'banner images',
    navUrl: '/dioceses/diocese_information/banner_images',
  },
  {
    navName: 'Gallery',
    navValue: 'gallery',
    navUrl: '/dioceses/diocese_information/gallery',
  },
  {
    navName: 'Personnels',
    navValue: 'personnels',
    navUrl: '/dioceses/diocese_information/personnels',
  },
  {
    navName: 'Description',
    navValue: 'description',
    navUrl: '/dioceses/diocese_information/description',
  },
  {
    navName: 'Ministerial Roll',
    navValue: 'ministerial_roll',
    navUrl: '/dioceses/diocese_information/ministerial_roll',
  },
];

export const csiUserTableHeading = [
  {
    title: 'User Name',
    name: 'user_name',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'User Email',
    name: 'email',
  },
  {
    title: 'User Mobile',
    name: 'mobile',
  },
  {
    title: 'User Type',
    name: 'user_type',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const csiUserBody = [
  {
    _id: '1',
    name: 'mukunthan',
    email: 'svmukunthan12345566@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
  {
    _id: '2',
    name: 'mukunthan',
    email: 'svmukunthan12345566@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
];
export const bannerImageData = [
  {
    title: 'Banner',
    name: 'url',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const galleryData = [
  {
    title: 'Gallery image',
    name: 'url',
    type: 'image',
  },
  {
    title: 'Added date time',
    name: 'date',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const ministerialRollData = [
  {
    title: 'Title',
    name: 'url',
    type: 'pdf',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const descriptionData = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const DiocesePersonnelsData = [
  {
    title: 'Diocese',
    name: 'diocese',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Name',
    name: 'user_name',
  },
  {
    title: 'Type',
    name: 'role',
  },
  {
    title: 'Image',
    name: 'profile_picture',
    type: 'image',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'Sort',
    name: 'sort',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const csiUserSuggessionData = [
  {
    icon: PlusIcon,
    name: 'sunil',
  },
  {
    icon: PlusIcon,
    name: 'prakash',
  },
  {
    icon: PlusIcon,
    name: 'Mukunthan',
  },
];

export const dioceseTableHeading = [
  {
    title: 'Diocese',
    name: 'diocese_name',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'User Name',
    name: 'user_name',
  },
  {
    title: 'User Mail',
    name: 'email',
  },
  {
    title: 'User Mobile',
    name: 'mobile',
  },
  {
    title: 'User Type',
    name: 'user_type',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const departmentTableHeading = [
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'User Name',
    name: 'user_name',
  },
  {
    title: 'User Email',
    name: 'email',
  },
  {
    title: 'User Mobile',
    name: 'mobile',
  },
  {
    title: 'User Type',
    name: 'user_type',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const departmentEventsTableHeading = [
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Event',
    name: 'name',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const dioceseEventsTableHeading = [
  {
    title: 'Diocese',
    name: 'diocese',
  },
  {
    title: 'Event',
    name: 'description',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const departmentInformationTableHeading = [
  {
    title: 'Department',
    name: 'department',
  },
  {
    title: 'Event',
    name: 'event',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Action',
    name: 'action',
  },
];

export const departmentNewsTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const dioceseNewsTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Diocese',
    name: 'diocese_name',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const aimsAndObjectivesTableHeading = [
  {
    title: 'Department Name',
    name: 'name',
  },
  {
    title: 'Aims and Objectives',
    name: 'aim',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const addressTableHeading = [
  {
    title: 'Diocese Name',
    name: 'name',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'E-mail',
    name: 'email',
  },
  {
    title: 'Mobile/Phone/Fax',
    name: 'number',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const addressBody = [
  {
    _id: '1',
    diocese: 'christian-education',
    name: 'christian-education',
    address: 'brownbutton.io',
    email: 'admin@gmail.com',
    mobile: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
  {
    _id: '2',
    diocese: 'christian-education',
    name: 'christian-education',
    address: 'sai@brownbutton.io',
    email: 'admin@gmail.com',
    mobile: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
];

export const departmentpersonnelsTableHeading = [
  {
    title: 'Department',
    name: 'department',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Name',
    name: 'user_name',
  },
  {
    title: 'Type',
    name: 'role',
  },
  {
    title: 'Sort order',
    name: 'sort',
  },
  {
    title: 'Image',
    name: 'profile_picture',
    type: 'image',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const personnelsTableHeading = [
  {
    title: 'Diocese',
    name: 'diocese',
  },
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Type',
    name: 'type',
  },
  {
    title: 'Image',
    name: 'image',
    type: 'image',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const dioceseBody = [
  {
    _id: '1',
    diocese: 'karnataka-northern',
    user_name: 'karnataka-northern',
    email: 'svmukunthan97@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
  {
    _id: '2',
    diocese: 'karnataka-northern',
    user_name: 'karnataka-northern',
    email: 'svmukunthan97@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
];

export const departmentBody = [
  {
    _id: '1',
    department: 'christian-education',
    name: 'christian-education',
    email: 'admin@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
  {
    _id: '2',
    department: 'christian-education',
    name: 'christian-education',
    email: 'admin@gmail.com',
    phone: '9876543210',
    user_type: 'csi_user',
    action: true,
  },
];

export const departmentEventsBody = [
  {
    _id: '1',
    department: 'Mission & Evangelism',
    event: 'CSI - EMS Liaison Office: Trienial Report 2017 - 2020',
    date: 'Jan 24, 2020',
    action: true,
  },
];
export const dioceseEventsBody = [
  {
    _id: '1',
    diocese: 'Mission & Evangelism',
    event: 'CSI - EMS Liaison Office: Trienial Report 2017 - 2020',
    date: 'Jan 24, 2020',
    action: true,
  },
];

export const departmentInformationBody = [
  {
    department: 'Malabar',
    event: 'CSI - EMS Liaison Office: Trienial Report 2017 -2020',
    date: 'Jan 24, 2020',
    action: true,
  },
];

export const departmentNewsBody = [
  {
    _id: '1',
    department: 'Malabar',
    date: 'Jan 24, 2020',
    title: 'CSI - EMS Liaison Office: Trienial Report 2017 - 2020',
    description:
      'The Department  of the Church Of South India Synod adopted a novel way to present the',
    image: TableImage,
    action: true,
  },
];
export const dioceseNewsBody = [
  {
    _id: '1',
    diocese: 'Malabar',
    date: 'Jan 24, 2020',
    title: 'CSI - EMS Liaison Office: Trienial Report 2017 - 2020',
    description:
      'The Department  of the Church Of South India Synod adopted a novel way to present the',
    image: TableImage,
    action: true,
  },
];
export const aimsAndObjectivesBody = [
  {
    _id: '1',
    department:
      "'KORIND' is the name acquired by a programme between the churches in korea and the rural churches in India. This programme is rooted through the CSI Synod and different dioceses to the local congregations of remote rural areas",
    action: true,
  },
];

export const personnelsBody = [
  {
    _id: '1',
    department: 'Christian-Education',
    name: 'Prof.Dr.B. Vimal Sukumar',
    type: 'Synod Staff',
    image: TableImage,
    address:
      'Plot No:27, Road No:3, Dhanalaxmi Colony, Mahendra Hills,Maredpalli, Securandarabad-500 025',
    action: true,
  },
];

export const titleCardData = [
  'Christian Education',
  'Communication',
  'CSI Board of Child Care',
  'CSI EMS',
  'CSI Orders Of Sisters',
  'CSI SEVA',
  'Dalit And Aadivasi Concerns',
  'Ecological Concerns',
  'KORIND',
];
export const departmentData = [
  'Pastrol Concerns',
  'Youth',
  'Communication',
  'Mission & Evangelism',
  'Prakash',
  'Mukunthan',
  'Communication',
  'Communication',
];
export const dioceseData = [
  'Karnataka North',
  'Karnataka South',
  'Karnataka Central',
  'Mission&Evangalism',
  'Cochin',
  'East Kerala',
  'Malabar',
  'Madras',
  'Vellore',
  'Medak',
];
export const dioceseInfoData = [
  'Karnataka Northern',
  'Karnataka Southern',
  'Karnataka Central',
  'Madhya Kerala',
  'Cochin',
  'East Kerala',
  'Kollam Kottarakka',
  'Malabar',
  'South Kerala',
];

export const dioceseUserData = [{ label: 'Malabar', value: 'diocese' }];

export const departmentUserData = [
  { label: 'Communication', value: 'communication' },
];

export const personnelsData = [
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'Department Executive',
    value: 'Department Executive',
  },
  {
    label: 'Director In-Charge',
    value: 'Director In-Charge',
  },
  {
    label: 'Hon. Director',
    value: 'Hon. Director',
  },
  {
    label: 'Hon. Associate Director',
    value: 'Hon. Associate Director',
  },
  {
    label: 'Hon. Advisor',
    value: 'Hon. Advisor',
  },
  {
    label: 'Hon. Director In-Charges',
    value: 'Hon. Director In-Charges',
  },
  {
    label: 'Associate director',
    value: 'Associate director',
  },
];
export const diocesePersonnelsData = [
  {
    label: 'Diocesan officer',
    value: 'diocesan_officer',
  },
  {
    label: 'Bishop',
    value: 'bishop',
  },
];

export const selectBoxData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Partner',
    value: 'partner',
  },
  {
    label: 'Network',
    value: 'network',
  },
];

export const partnersTableHeading = [
  {
    title: 'Title',
    name: 'name',
  },
  {
    title: 'Category',
    name: 'category',
  },
  {
    title: 'URL',
    name: 'url',
  },
  {
    title: 'Image',
    name: 'image',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const partnerBody = [
  {
    _id: '1',
    title: 'The United Reformed Church',
    category: 'partner',
    url: 'http://www.urc.org.uk/',
    image: TableImage,
    action: true,
  },
  {
    _id: '2',
    title: 'The United Reformed Church',
    category: 'network',
    url: 'http://www.urc.org.uk/',
    image: TableImage,
    action: true,
  },
  {
    _id: '3',
    title: 'The United Reformed Church',
    category: 'network',
    url: 'http://www.urc.org.uk/',
    image: TableImage,
    action: true,
  },
  {
    _id: '4',
    title: 'The United Reformed Church',
    category: 'network',
    url: 'http://www.urc.org.uk/',
    image: TableImage,
    action: true,
  },
  {
    _id: '5',
    title: 'The United Reformed Church',
    category: 'network',
    url: 'http://www.urc.org.uk/',
    image: TableImage,
    action: true,
  },
];

export const advertismentTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'URL',
    name: 'url',
  },
  {
    title: 'Order',
    name: 'type',
  },
  {
    title: 'Thumbnail',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const advertisementBody = [
  {
    _id: '1',
    title: '74th CSI FORMATION DAY CELEBRATIONS ',
    url: 'http://www.urc.org.uk/',
    order: 1,
    image: TableImage,
    action: true,
  },
  {
    _id: '2',
    title: '75th CSI FORMATION DAY CELEBRATIONS',
    url: 'http://www.urc.csi.uk/',
    order: 2,
    image: TableImage,
    action: true,
  },
];

export const advertisementSelectBoxData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'true',
  },
  {
    label: 'Inactive',
    value: 'false',
  },
];

export const sermonOutlineTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Category',
    name: 'sermon_category',
  },
  {
    title: 'PDF',
    name: 'pdf',
    type: 'pdf',
  },
  {
    title: 'Thumbnail',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const sermonOutlineBody = [
  {
    _id: '1',
    title: '74th CSI FORMATION DAY CELEBRATIONS! CSI SYNOD',
    category: 'Lent',
    pdf: 'CSI life.pdf',
    image: TableImage,
    action: true,
  },
];

export const sermonOutlinesSelectionData = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Sermon Outlines',
    value: 'sermon_outlines',
  },
  {
    label: 'Lent',
    value: 'lent',
  },
  {
    label: 'Advent',
    value: 'advent',
  },
  {
    label: 'Bible Study',
    value: 'bible_study',
  },
];

export const prayerCalendarTableHeading = [
  {
    title: 'Date',
    name: 'dates',
  },
  {
    title: 'Text',
    name: 'description',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const prayerCalendarBody = [
  {
    _id: '1',
    date: '2019-10-31',
    text:
      '1.Meclak Diocese of church of south india. Rajasthan Diocese of church of North India. Kunnukulam - Malabar Dioceses of Mar Thomas Church. Anglican Communication, London ',
    action: true,
  },
];

export const lectionaryTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'PDF',
    name: 'pdf',
    type: 'pdf',
  },
  {
    title: 'Thumbnail',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const lectionaryBody = [
  {
    _id: '1',
    title:
      'NAYA SAVERA - Free Coaching and Allied Scheme for the Candidates/Students. Belonging to Minority Communication',
    pdf: '15625346788097.PDF',
    image: TableImage,
    action: true,
  },
];

export const campaignTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const campaignBody = [
  {
    _id: '1',
    title: 'COMMUNICATION FOR AN ALTERNATE HUMANITY',
    description:
      'Christian Education Department of the CSI Triennial Report 2017-2020',
    image: TableImage,
    action: true,
  },
];

export const campaignSelectBoxData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'true',
  },
  {
    label: 'Inactive',
    value: 'false',
  },
];

export const officersTableHeading = [
  {
    title: 'Name',
    name: 'user_name',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Image',
    name: 'profile_picture',
    type: 'image',
  },
  {
    title: 'Designation',
    name: 'designation',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'E-mail',
    name: 'email',
  },
  {
    title: 'Mobile',
    name: 'mobile',
  },
  {
    title: 'Phone/Fax',
    name: 'phone_fax',
  },
  {
    title: 'Sort Order',
    name: 'sort',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const officersBody = [
  {
    name: 'Prof Dr.B. Vimal Sukumar',
    image: TableImage,
    designation: 'HON. TREASURER CSI & CSITA',
    address:
      'Plot No: 127 road, No: 3, Dhanalaxmi Colony, Mahendra hills. Maredpalli, Secunderabad - 500 026',
    mail: 'treasurercsi@gmail.com',
    mobile: '+91 9937373657',
    phone_fax: '(O) 044-2567 1542 / 4166 (W) 044-222223',
    sort_order: '2',
  },
];
export const dioceseBannerBody = [
  {
    banner:
      'https://images-na.ssl-images-amazon.com/images/I/71oYDA4q4CL._UX569_.jpg',
  },
];
export const dioceseDescriptionBody = [
  {
    title: 'Diocese description',
    description: 'Description 1',
  },
];
// export const workingCommitteeTableHeading = [
//   {
//     title: "Name",
//     name: "user_name"
//   },
//   {
//     title: "Author",
//     name: "author"
//   },
//   {
//     title: "Image",
//     name: "profile_picture",
//     type: "image"
//   },
//   {
//     title: 'Designation',
//     name: 'designation',
//   },
//   {
//     title: "Category",
//     name: "role"
//   },
//   {
//     title: "Dioceses",
//     name: "diocese_name"
//   },
//   {
//     title: 'Sort',
//     name: 'sort',
//   },
//   {
//     title: 'Action',
//     name: 'action',
//     type: 'action',
//   },
// ];
export const workingCommitteeTableHeading = [
  {
    title: 'Description',
    name: 'desc',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];
export const workingCommitteeBody = [
  {
    name: 'Prof Dr.B. Vimal Sukumar',
    image: TableImage,
    designation: 'HON. TREASURER CSI & CSITA',
    category: 'Officer',
    dioceses: 'Karim Nagar',
    sort: '1',
  },
];

export const committeeSelectBoxData = [
  {
    label: 'Officers',
    value: 'officer',
  },
  {
    label: 'Member',
    value: 'member',
  },
  {
    label: 'Regional Women',
    value: 'regional_women',
  },
];

export const executiveCommitteeTableHeading = [
  {
    title: 'Description',
    name: 'desc',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const executiveCommitteeBody = [
  {
    description:
      'OFFICERS OF THE SYNOD: The Most Rev, A. Dharmaraj Rasalam, Moderator CSI & Bishop in South Kerala. The Rt.Rev Dr.K. Reuben Mark',
  },
];

export const staffTableHeading = [
  {
    title: 'Staff Category',
    name: 'role',
  },
  {
    title: 'Name',
    name: 'user_name',
  },
  {
    title: 'Image',
    name: 'profile_picture',
    type: 'image',
  },
  {
    title: 'Sort',
    name: 'sort',
  },
  {
    title: 'E-mail',
    name: 'email',
  },
  {
    title: 'Mobile',
    name: 'mobile',
  },
  {
    title: 'Designation',
    name: 'designation',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const staffBody = [
  {
    staff_category: 'Guest House Staff',
    name: 'Mr. A. Mariya Louis Staff',
    image: TableImage,
    mail: 'treasurercsi@gmail.com',
    mobile: '9937373657',
    designation: 'Head',
  },
];

export const contentTableHeading = [
  {
    title: 'Description',
    name: 'desc',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const contentBody = [
  {
    description:
      'The Church of South India is the successor of a number of Protestant denominations in India, including the Church of England, Church of India, Burma and Ceylon (Anglican), the United Church of Christ (Congregationalist), the British Methodist Church and the Church of Scotland after Indian Independence. It combined the South India United Church (union of the British Congregationalists and the British Presbyterians); the then 14 Anglican Dioceses of South India and one in Sri Lanka; and the South Indian District of the Methodist church.[8] With a membership of nearly four million,[4][5] CSI is one of four united Protesant churches in the Anglican Communion, the others being the Church of North India, the Church of Pakistan and the Church of Bangladesh; it also is a member of the World Methodist Council and World Communion of Reformed Churches',
  },
];

export const csitaOfficersTableHeading = [
  {
    title: 'Name',
    name: 'user_name',
  },
  {
    title: 'Image',
    name: 'profile_picture',
    type: 'image',
  },
  {
    title: 'Designation',
    name: 'designation',
  },
  {
    title: 'Address',
    name: 'address',
  },
  {
    title: 'E-mail',
    name: 'email',
  },
  {
    title: 'Mobile',
    name: 'mobile',
  },
  {
    title: 'Phone_Fax',
    name: 'phone_fax',
  },
  {
    title: 'Sort Order',
    name: 'sort',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const csitaOfficersBody = [
  {
    name: 'Prof Dr B. Vimal Sukumar',
    image: TableImage,
    designation: 'Head',
    address:
      'Plot No: 127 road, No: 3, Dhanalaxmi Colony, Mahendra hills. Maredpalli, Secunderabad - 500 026',
    mail: 'treasurercsi@gmail.com',
    mobile: '9937373657',
    phone_fax: '(O) 044-2567 1542 / 4166 (W) 044-222223',
    sort_order: '2',
  },
];

export const resourceTableHeading = [
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Year',
    name: 'year',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Resource',
    name: 'pdf',
    type: 'pdf',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const resourceBody = [
  {
    department: 'Pastrol concerns',
    year: '2021',
    image: TableImage,
    resource:
      'Christian Education Department of the CSI Trennial Report 2017-2020',
  },
];

export const resourceSelectionData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Pastoral concerns',
    value: 'pastoral_concerns',
  },
  {
    label: 'communication',
    value: 'communication',
  },
  {
    label: 'Youth',
    value: 'youth',
  },
  {
    label: 'Mission & Evangalism',
    value: 'mission_evangalism',
  },
];

export const resourceYearSelectionData = [
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2022',
    value: '2022',
  },
];

export const csiMonthSelectionData = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'Febraury',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];

export const csiLifeTableHeading = [
  {
    title: 'CSI Life',
    name: 'title',
  },
  {
    title: 'Year',
    name: 'year',
  },
  {
    title: 'Month',
    name: 'month',
  },
  {
    title: 'Special Edition',
    name: 'specialEdition',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const csiLifeBody = [
  {
    csi_life: 'CSI Life.pdf',
    year: '2021',
    month: 'Febraury',
    special_edition: '-- -- --',
    image: TableImage,
  },
  {
    csi_life: 'CSI Life.pdf',
    year: '2021',
    month: 'Febraury',
    special_edition: 'New Year Edition',
    image: TableImage,
  },
];

export const booksTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Image',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const booksBody = [
  {
    title: 'COMMUNICATION FOR AN ALTERNATIVE HUMANITY',
    description:
      'Christian Education Department of the CSI Triennial Report 2017-2020',
    image: TableImage,
  },
];

export const libraryTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Image',
    name: 'image',
    type: 'image',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const libraryBody = [
  {
    title: 'COMMUNICATION FOR AN ALTERNATIVE HUMANITY',
    description:
      'Christian Education Department of the CSI Triennial Report 2017-2020',
    image: TableImage,
  },
];
export const DepartmentTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Author',
    name: 'author_name',
  },
];
export const DioceseTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Diocese',
    name: 'diocese_name',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Author',
    name: 'author_name',
  },
];
export const AllNewsTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Diocese',
    name: 'diocese_name',
  },
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Author',
    name: 'author',
  },
];
export const newsletterDepartmentTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Department',
    name: 'department_name',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Author',
    name: 'author',
  },
];
export const newsletterDioceseTableHeading = [
  {
    type: 'check',
    name: '_id',
  },
  {
    title: 'Diocese',
    name: 'diocese_name',
  },
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Description',
    name: 'heading',
  },
  {
    title: 'Author',
    name: 'author',
  },
];
export const newsletterDepartmentBody = [
  {
    _id: 1,
    department: 'Pastrol concerns',
    dioceses: '2021',
    title: 'The CSI Almanac for 2021 has been Released',
    description:
      'At a function held at the CSI Synod Centre in chennai. Most Rev Dhararaj Rasalam the moderator of the Church of south india (CSI) has released',
    action: true,
  },
];

export const newsletterDioceseBody = [
  {
    _id: 1,
    department: 'Pastrol concerns',
    dioceses: '',
    title: 'The CSI Almanac for 2021 has been Released',
    description:
      'At a function held at the CSI Synod Centre in chennai. Most Rev Dhararaj Rasalam the moderator of the Church of south india (CSI) has released',
    action: true,
  },
];

export const newsletterAllBody = [
  {
    _id: 1,
    department: '',
    dioceses: 'Karnataka North',
    title: 'The CSI Almanac for 2021 has been Released',
    description:
      'At a function held at the CSI Synod Centre in chennai. Most Rev Dhararaj Rasalam the moderator of the Church of south india (CSI) has released',
    action: true,
  },
  {
    _id: 2,
    department: 'Pastoral Concerns',
    dioceses: '',
    title: 'The CSI Almanac for 2021 has been Released',
    description:
      'At a function held at the CSI Synod Centre in chennai. Most Rev Dhararaj Rasalam the moderator of the Church of south india (CSI) has released',
    action: true,
  },
];

export const subscriberTableHeading = [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'phone',
    name: 'phone',
  },
  {
    title: 'Registered Date',
    name: 'created_at',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const subscriberBody = [
  {
    id: '19934',
    name: 'Solomon',
    mail: 'samraj3125@gmail.com',
    phone: '9934363728',
    diocese: '2021',
    registered_date: '2020-11-29 04:26:11',
  },
];

export const subscriberBoxData = [
  {
    label: 'karnataka North',
    value: 'karnataka_north',
  },
  {
    label: 'Karnataka South',
    value: 'karnataka_south',
  },
  {
    label: 'Karnataka Central',
    value: 'karnataka_central',
  },
  {
    label: 'Cochin',
    value: 'cochin',
  },
  {
    label: 'East Kerala',
    value: 'east_kerala',
  },
  {
    label: 'Malabar',
    value: 'malabar',
  },
];

export const csiVideoTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Video Thumbnail',
    name: 'thumbnail',
    type: 'image',
  },
  {
    title: 'Sort',
    name: 'order',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const csiVideoBody = [
  {
    title: '74th CSI FORMATION DAY CELEBRATION! CSI SYNOD',
    description: 'https://www.youtube.com/embeded/Dgkjhkhhshlwh_noisa_1',
    image: TableImage,
  },
];

export const urlSelectBoxData = [
  {
    label: 'http://www.youtube.com/agsjashajshajha_ja812121',
    value: 'http://www.youtube.com/agsjashajshajha_ja812121',
  },
  {
    label: 'http://www.youtube.com/agsjashajshajha_ja812121',
    value: 'http://www.youtube.com/agsjashajshajha_ja812121',
  },
  {
    label: 'http://www.youtube.com/agsjashajshajha_ja812121',
    value: 'http://www.youtube.com/agsjashajshajha_ja812121',
  },
];

export const govtNotificationTableHeading = [
  {
    title: 'Title',
    name: 'title',
  },
  {
    title: 'Author',
    name: 'author',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Pdf',
    name: 'pdf',
    type: 'pdf',
  },
  {
    title: 'Date',
    name: 'date',
  },
  {
    title: 'Action',
    name: 'action',
    type: 'action',
  },
];

export const govtNotificationBody = [
  {
    title:
      'NAYA SAVERA Free coaching and Allied Scheme for The Candidates/ Students Belonging to Ministry Communities',
    author: 'iallamaran',
    description:
      'This scheme by the Ministry Affairs of the government of india aims to empower the students belonging to minority communtiy and prepares them for competitive examination',
    pdf: '1523378732.pdf',
    date: '13-01-2021 05:26:01',
  },
];

export const homeTaps = [
  {
    key: 'about_us',
    tabs: [
      {
        head: 'About',
        tap_list: [
          {
            url: 'https://csi1947.com/about/history',
            name: 'History',
          },
          {
            url: 'https://csi1947.com/about/history',
            name: 'Vision',
          },
          {
            url: 'https://csi1947.com/about/history',
            name: 'Mission',
          },
          {
            url: 'https://csi1947.com/about/history',
            name: 'Logo',
          },
        ],
      },
      {
        head: 'Administration',
        tap_list: [
          {
            url: 'https://csi1947.com/about/officer',
            name: 'Officers',
          },
          {
            url: 'https://csi1947.com/about/working_committee',
            name: 'Working Commitee',
          },
          {
            url: 'https://csi1947.com/about/executive_committee',
            name: 'Executive Comitee',
          },
          {
            url: 'https://csi1947.com/about/csita',
            name: 'CSITA',
          },
          {
            url: 'https://csi1947.com/about/secretarial_staff/Staff Under General Secretary',
            name: 'Secreterial Staff',
          },
        ],
      },
      {
        head: 'Partners & Networks',
        tap_list: [
          {
            url: 'https://csi1947.com/about/partners_screen',
            name: 'Partners',
          },
          {
            url: 'https://csi1947.com/about/networks_screen',
            name: 'Networks',
          },
          {
            url: 'https://csi1947.com/about/campaign_list_screen',
            name: 'Campaigns',
          },
        ],
      },
      {
        head: 'Contact',
        tap_list: [
          {
            url: 'https://csi1947.com/about/contact_us',
            name: 'Contact Us ',
          },
        ],
      },
    ],
  },
  {
    key: 'resources',
    tabs: [
      {
        tap_list: [
          {
            url: 'https://csi1947.com/resources/worship_resource/prayer_calendar',
            name: 'Worship Resources',
          },
          {
            url: 'https://csi1947.com/resources/publications_screen',
            name: 'Publications',
          },
          {
            url: 'https://csi1947.com/resources/csi_life_screen',
            name: 'CSI Life',
          },
          {
            url: '/resources/resource_docs',
            name: 'Resource Docs',
          },
        ],
      },
    ],
  },
  {
    key: 'others',
    tabs: [
      {
        tap_list: [
          {
            url: 'https://csi1947.com/others/press_release',
            name: 'Press Release',
          },
          {
            url: 'https://csi1947.com/others/statement',
            name: 'Statements',
          },
          {
            url: 'https://csi1947.com/others/library_archive',
            name: 'Library & Archives',
          },
          {
            url: 'https://csi1947.com/others/government_notification_schemes',
            name: 'Govt.Notification Schemes',
          },
        ],
      },
    ],
  },
];

export const videoContent = [
  {
    head: 'Christian Education Department of the CSI',
    date: 'Friday, Jan 24, 2020',
    index: 0,
    url: 'https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4',
  },
  {
    head: 'Christian Education Department of the CSI',
    date: 'Friday, Jan 24, 2020',
    index: 0,
    url: 'https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4',
  },
  {
    head: 'Christian Education Department of the CSI',
    date: 'Friday, Jan 24, 2020',
    index: 0,
    url: 'https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4',
  },
  {
    head: 'Christian Education Department of the CSI',
    date: 'Friday, Jan 24, 2020',
    index: 0,
    url: 'https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4',
  },
];

export const ads = [
  {
    title: 'Christian Education Department of the CSI',
    description: 'Friday, Jan 24, 2020',
    link:
      'https://4.img-dpreview.com/files/p/E~TS590x0~articles/3925134721/0266554465.jpeg',
    onClick: () => console.log('loll'),
    type: 'campaign',
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: 'Christian Education Department of the CSI',
    description: 'Friday, Jan 24, 2020',
    link:
      'https://4.img-dpreview.com/files/p/E~TS590x0~articles/3925134721/0266554465.jpeg',
    onClick: () => console.log('loll'),
    type: 'campaign',
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: 'Christian Education Department of the CSI',
    description: 'Friday, Jan 24, 2020',
    link:
      'https://4.img-dpreview.com/files/p/E~TS590x0~articles/3925134721/0266554465.jpeg',
    onClick: () => console.log('loll'),
    type: 'campaign',
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export const staffCategoryData = [
  {
    label: 'Staff Under General Secretary',
    value: 'Staff Under General Secretary',
  },
  {
    label: 'Staff Under Treasurer Office',
    value: 'Staff Under Treasurer Office',
  },
  {
    label: 'Staff of CSITA',
    value: 'Staff of CSITA',
  },
  {
    label: 'Guest House Staff',
    value: 'Guest House Staff',
  },
];

export const designationData = [
  {
    label: 'Head',
    value: 'Head',
  },
  {
    label: 'Staff',
    value: 'Staff',
  },
];

export const advertisementOrder = [
  {
    label: 'Platinum',
    value: 'platinum',
  },
  {
    label: 'Gold',
    value: 'gold',
  },
  {
    label: 'Silver',
    value: 'silver',
  },
];
