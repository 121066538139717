import React from 'react';
import './resource_card.component.scss';
import Button from 'common_components/ui/button/button.ui';
import Arrow from '../../assets/icons/Arrow_right.svg'

interface ResourcesCardProps {
  link: string,
  type?: string,
  name: string,
  onClick: any
}

const ResourcesCard = (props: ResourcesCardProps) => {
  const { link, type, name, onClick } = props;
  return (
    <div className="resource_card_container">
      <div className="resource_card_image_container" onClick={onClick}>
        <img src={link} alt="" className="resource_card_image" />
        <div className="resources_overlay"> </div>
        {type === "primary"
          ? <div>
              <div className="resource_card_text_container">
                <div className="resource_card_text_wrapper">
                  <div className="resource_card_text h3">{name}</div>
                </div>
              </div>
              <div className="view_more_container">
                <div className="view_more_text h6">View More</div>
                <img src={Arrow} alt="arrow" className="arrow_image" />
              </div>
              <div className="resource_button_wrapper">
                <div className="h4 resource_text_wrapper">View</div>
              </div>
            </div>
          : <div className="resource_card_text_container" onClick={onClick}>
              <div className="resource_card_text_wrapper">
                <div className="resources_card_text h4">{name}</div>
              </div>
            </div>}
      </div>
    </div>
  )
}

export default ResourcesCard;

ResourcesCard.defaultProps = {
  type: ""
}
