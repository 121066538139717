import { instance } from '../helper/axios.helper';

export const getCampaignDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`campaigns/get`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const addCampaignDetails = (data) => {
    const promise = new Promise((resolve, reject) => {
        instance().post(`campaigns/create`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const getCampaignDetailsById = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().get(`campaigns/get/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}

export const editCampaignDetails = (id, data) => {
    const promise = new Promise((resolve, reject) => {
        instance().put(`campaigns/edit/${id}`, data)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
export const deleteCampaign = (id) => {
    const promise = new Promise((resolve, reject) => {
        instance().delete(`campaigns/delete/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                }
            })
    })
    return promise;
}
