import React, { Suspense, useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Test from 'screens/test/test.screen';
import CsiUsers from 'screens/users/users.screen';
import DioceseUser from 'screens/users/diocese_user.screen';
import DepartmentUser from 'screens/users/department_user.screen';
import DepartmentNews from 'screens/department/department_news.screen';
import DepartmentEvents from 'screens/department/department_events.screen';
import DepartmentInformation from 'screens/department/department_information.screen';
import DepartmentView from 'screens/department/department_view.screen';
import Personnels from 'screens/department/personnels.screen';
import PartnerAndNetwork from 'screens/partnersandnetworks/partnersandnetworks.screen';
import Advertisement from 'screens/advertisement/advertisement.screen';
import PrayerCalendar from 'screens/workship_resources/prayer_calendar.screen';
import SermonOutline from 'screens/workship_resources/sermon_outlines.screen';
import CSILectionary from 'screens/workship_resources/csi_lectionary.screen';
import Campaign from 'screens/campaign/campaign.screen';
import AddCampaign from 'screens/campaign/add_campaign.screen';
import Officers from 'screens/aboutCSI/officers.screen';
import AddOfficers from 'screens/aboutCSI/add_officers.screen';
import ExecutiveCommittee from 'screens/aboutCSI/executive_committee.screen';
import Contents from 'screens/aboutCSI/content.screen';
import Login from 'screens/auth/login.screen';
import AddNews from 'screens/department/add_news.screen';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from 'common_components/hoc/main.hoc';
import store from 'store/store';
import { findSubDomain, useSetState } from 'utils/common.utils';
import { Provider } from 'react-redux';
import WorkingCommittee from 'screens/aboutCSI/working_committee.screen';
import SecretarialStaff from 'screens/aboutCSI/secretarial_staff.screen';
import AddStaff from 'screens/aboutCSI/add_staff.screen';
import CsitaOfficer from 'screens/aboutCSI/csita_officers.screen';
import AddCsitaOfficer from 'screens/aboutCSI/add_csita_officer.screen';
import Resources from 'screens/publication/resources.screen';
import Books from 'screens/publication/books.screen';
import LibraryArchives from 'screens/publication/library_archives.screen';
import CsiLife from 'screens/publication/csi_life.screen';
import NewsLetterDepartment from 'screens/newsletter/newsletter_department.screen';
import NewsLetterDiocese from 'screens/newsletter/newsletter_diocese.screen';
import NewsLetterAll from 'screens/newsletter/newsletter_all.screen';
import Subscribe from 'components/subscribe/subscribe.component';
import Subscriber from 'screens/newsletter/subscriber.screen';
import CsiVideo from 'screens/CSIvideos/csi_videos.screen';
import NotificationSchemes from 'screens/announcement/govt_notification_schemes.screen';
import PressRelease from 'screens/announcement/press_release.screen';
import Statements from 'screens/announcement/statement.screen';
import DioceseNews from 'screens/diocese/diocese_news.screen';
import DioceseEvents from 'screens/diocese/diocese_events.screen';
import DioceseInformation from 'screens/diocese/diocese_information.screen';
import DioceseView from 'screens/diocese/diocese_view.screen';
import DioceseAddNews from 'screens/diocese/diocese_add_news.screen';
import BannerImages from 'screens/diocese/banner_images.screen';
import DioceseGallery from 'screens/diocese/diocese_gallery.screen';
import DiocesePersonnels from 'screens/diocese/diocese_personnels.screen';
import DioceseDescription from 'screens/diocese/diocese_description.screen';
import DioceseMinsterialRoll from 'screens/diocese/diocese_ministerial_roll.screen';
import DioceseAddDesc from 'screens/diocese/diocese_add_description.screen';
import Home from 'screens/user_screens/home.screen';
import DepartmentScreen from 'screens/user_screens/department.screen';
import Diocese from 'screens/user_screens/diocese.screen';
import Event from 'screens/user_screens/event.screen';
import Press from 'screens/user_screens/press_release.screen';
import Schemes from 'screens/user_screens/notification_schemes.screen';
import Statement from 'screens/user_screens/statement.screen';
import Officer from 'screens/user_screens/officer.screen';
import Committee from 'screens/user_screens/working_committee.screen';
import PublicationDetails from 'screens/user_screens/publication_details.screen';
import Staffs from 'screens/user_screens/secretarial_staff.screen';
import Committees from 'screens/user_screens/executive_committee.screen';
import Csita from 'screens/user_screens/csita.screen';
import AimScreen from 'screens/user_screens/aim_and_objectives.screen';
import AboutCsi from 'screens/user_screens/about_csi.screen';
import PublicationScreen from 'screens/user_screens/publication.screen';
import CsiLifeScreen from 'screens/user_screens/csi_life.screen';
import ResourceDocs from 'screens/user_screens/resource_docs.screen';
import LibraryArchive from 'screens/user_screens/library_archives.screen';
import PartnersScreen from 'screens/user_screens/partners.screen';
import NetworksScreen from 'screens/user_screens/network.screen';
import CampaignListScreen from 'screens/user_screens/campaign_list.screen';
import DioceseNewsScreen from 'screens/user_screens/diocese_news_user.screen';
import CampaignViewScreen from 'screens/user_screens/campaign_view.screen';
import NewsScreen from 'screens/user_screens/news_view.screen';
import WorshipResource from 'screens/user_screens/worship_resources.screen';
import CsiLectionaryUserScreen from 'screens/user_screens/csi_lectionary.user.screen';
import SermonOutlinesUserScreen from 'screens/user_screens/sermon_outlines.user.screen';
import PaginationComponent from 'components/pagination/pagination.component';
import ContactScreen from 'screens/user_screens/contact_us.screen';
import CsiVideos from 'screens/user_screens/csi_video.screen';
import AdvertisementHome from 'screens/user_screens/advertisement_user_screen';
import LandingPageScreen from 'screens/Landing/Landing_page.screen';

const token = localStorage.getItem('token');

const NavRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Main>
          <Component {...props} />
        </Main>
      ) : (
        <Login />
      )
    }
  />
);
function App(props) {
  const [state, setState] = useSetState({
    token: localStorage.getItem('token'),
    isLoggedIn: false,
  });

  const routeNames = [
    {
      name: 'dashboard',
      location: '/home',
    },
    {
      name: 'csilife',
      location: '/resources/csi_life_screen',
    },
    {
      name: 'csinews',
      location: '/news_list_screen/departments',
    },
    {
      name: 'publications',
      location: '/resources/publications_screen',
    },
  ];

  const validateRoute = (name) => {
    routeNames.map((path) => {
      if (path.name === name) {
        window.location.href = path.location;
      }
      return true;
    });
  };
  useEffect(() => {
    validateRoute(window.location.href.split('/')[3]);
    if (state.token) {
      setState({ isLoggedIn: true });
    } else {
      setState({ isLoggedIn: false });
    }
  }, [state.token]);
  const parsedData = window.location.host.split('.');
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          limit={2}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Suspense fallback={<div />}>
          <Switch>
            {parsedData.length >= 3 && parsedData[0] !== 'www' ? (
              <>
                {findSubDomain(parsedData[0]) ? (
                  <Route exact path="/" component={Diocese} />
                ) : (
                  <Route exact path="/" component={DepartmentScreen} />
                )}
                <Route exact path="/aim/:id" component={AimScreen} />
                <Route
                  exact
                  path="/news_screen/:type/:id"
                  component={NewsScreen}
                />
                <Route
                  exact
                  path="/news_list_screen/:type/:id?"
                  component={DioceseNewsScreen}
                />
              </>
            ) : (
              <Switch>
                <Route exact path="/" component={LandingPageScreen} />
                <Route exact path="/diocese" component={Diocese} />
                <Route exact path="/dep" component={DepartmentScreen} />
                <Route exact path="/dio" component={Diocese} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/login" component={Login} />
                <NavRoute exact path="/users" component={CsiUsers} />
                <NavRoute
                  exact
                  path="/users/diocese_user"
                  component={DioceseUser}
                />
                <NavRoute
                  exact
                  path="/users/department_user"
                  component={DepartmentUser}
                />
                <NavRoute exact path="/dioceses" component={DioceseNews} />
                <NavRoute
                  exact
                  path="/dioceses/diocese_news"
                  component={DioceseNews}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_events"
                  component={DioceseEvents}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information"
                  component={DioceseInformation}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/address/:id"
                  component={DioceseView}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/banner_images/:id"
                  component={BannerImages}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/gallery/:id"
                  component={DioceseGallery}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/personnels/:id"
                  component={DiocesePersonnels}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/description/:id"
                  component={DioceseDescription}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/ministerial_roll/:id"
                  component={DioceseMinsterialRoll}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/add_description/"
                  component={DioceseAddDesc}
                />
                <NavRoute
                  exact
                  path="/dioceses/diocese_information/edit_description/:id"
                  component={DioceseAddDesc}
                />
                <NavRoute
                  exact
                  path="/dioceses/add_news/"
                  component={DioceseAddNews}
                />
                <NavRoute
                  exact
                  path="/dioceses/edit_news/:id"
                  component={DioceseAddNews}
                />
                <NavRoute
                  exact
                  path="/departments"
                  component={DepartmentNews}
                />
                <NavRoute
                  exact
                  path="/departments/department_news"
                  component={DepartmentNews}
                />
                <NavRoute
                  exact
                  path="/departments/department_events"
                  component={DepartmentEvents}
                />
                <NavRoute
                  exact
                  path="/departments/department_information"
                  component={DepartmentInformation}
                />
                <NavRoute
                  exact
                  path="/departments/department_information/aimsandobjectives/:id"
                  component={DepartmentView}
                />
                <NavRoute
                  exact
                  path="/departments/department_information/personnels/:id"
                  component={Personnels}
                />
                <NavRoute exact path="/about" component={Officers} />
                <NavRoute
                  exact
                  path="/about/working_commitee"
                  component={WorkingCommittee}
                />
                <NavRoute
                  exact
                  path="/about/executive_commitee"
                  component={ExecutiveCommittee}
                />
                <NavRoute
                  exact
                  path="/about/csita/content"
                  component={Contents}
                />
                <NavRoute
                  exact
                  path="/about/secretarial_staff"
                  component={SecretarialStaff}
                />
                <NavRoute exact path="/csi_videos" component={CsiVideo} />
                <NavRoute
                  exact
                  path="/worship_resources/sermon_outlines"
                  component={SermonOutline}
                />
                <NavRoute
                  exact
                  path="/worship_resources/csi_lectionary"
                  component={CSILectionary}
                />
                <NavRoute
                  exact
                  path="/departments/add_news"
                  component={AddNews}
                />
                <NavRoute
                  exact
                  path="/departments/edit_news/:id"
                  component={AddNews}
                />
                <NavRoute exact path="/add_news" component={AddNews} />
                <NavRoute exact path="/news" component={Test} />
                <NavRoute exact path="/about" component={SermonOutline} />
                <NavRoute exact path="/campaigns" component={Campaign} />
                <NavRoute
                  exact
                  path="/campaigns/add_campaign"
                  component={AddCampaign}
                />
                <NavRoute
                  exact
                  path="/campaigns/edit_campaign/:id"
                  component={AddCampaign}
                />
                <NavRoute exact path="/news" component={Test} />
                <NavRoute
                  exact
                  path="/worship_resources/prayer_calendar"
                  component={PrayerCalendar}
                />
                <NavRoute
                  exact
                  path="/worship_resources/"
                  component={PrayerCalendar}
                />
                <NavRoute
                  exact
                  path="/about_csi/officers"
                  component={Officers}
                />
                <NavRoute
                  exact
                  path="/about_csi/working_commitee"
                  component={WorkingCommittee}
                />
                <NavRoute
                  exact
                  path="/about_csi/addofficers"
                  component={AddOfficers}
                />
                <NavRoute
                  exact
                  path="/about_csi/addofficers/:id"
                  component={AddOfficers}
                />
                <NavRoute
                  exact
                  path="/about_csi/secretarial_staff"
                  component={SecretarialStaff}
                />
                <NavRoute
                  exact
                  path="/about_csi/addstaff"
                  component={AddStaff}
                />
                <NavRoute
                  exact
                  path="/about_csi/addstaff/:id"
                  component={AddStaff}
                />
                <NavRoute
                  exact
                  path="/about/csita/content"
                  component={Contents}
                />
                <NavRoute
                  exact
                  path="/about/csita/csita_officers"
                  component={CsitaOfficer}
                />
                <NavRoute
                  exact
                  path="/about/csita/add_officers"
                  component={AddCsitaOfficer}
                />
                <NavRoute
                  exact
                  path="/about/csita/add_officers/:id"
                  component={AddCsitaOfficer}
                />
                <NavRoute exact path="/publications" component={Resources} />
                <NavRoute
                  exact
                  path="/publications/csi_life"
                  component={CsiLife}
                />
                <NavRoute exact path="/publications/books" component={Books} />
                <NavRoute
                  exact
                  path="/publications/library_archives"
                  component={LibraryArchives}
                />
                <NavRoute
                  exact
                  path="/news_letter_test"
                  component={NewsLetterDiocese}
                />
                <NavRoute
                  exact
                  path="/news_letter/all"
                  component={NewsLetterAll}
                  key={window.location.pathname}
                />
                <NavRoute
                  exact
                  path="/news_letter/dioceses"
                  component={NewsLetterDiocese}
                  key={window.location.pathname}
                />
                <NavRoute
                  exact
                  path="/news_letter/departments"
                  component={NewsLetterDepartment}
                  key={window.location.pathname}
                />
                <NavRoute exact path="/subscriber" component={Subscriber} />
                <NavRoute exact path="/csivideos" component={CsiVideo} />
                <NavRoute
                  exact
                  path="/announcements/govt_notification_schemes/:type"
                  component={NotificationSchemes}
                  key={window.location.pathname}
                />
                <NavRoute
                  exact
                  path="/announcements/press_release/:type"
                  component={NotificationSchemes}
                  key={window.location.pathname}
                />
                <NavRoute
                  exact
                  path="/announcements/statement/:type"
                  component={NotificationSchemes}
                  key={window.location.pathname}
                />
                <NavRoute
                  exact
                  path="/advertisement"
                  component={Advertisement}
                />
                <NavRoute
                  exact
                  path="/partners"
                  component={PartnerAndNetwork}
                />
                <NavRoute exact path="/sunil" component={Test} />
                <Route exact path="/home/diocese" component={Diocese} />
                <Route exact path="/dep" component={DepartmentScreen} />
                <Route exact path="/home/diocese/:id" component={Diocese} />
                <Route
                  exact
                  path="/department/:id"
                  component={DepartmentScreen}
                />
                <Route exact path="/aim/:id" component={AimScreen} />
                <Route exact path="/history" component={AboutCsi} />
                <Route exact path="/about/history" component={AboutCsi} />
                <Route exact path="/about/officer" component={Officer} />
                <Route
                  exact
                  path="/about/working_committee"
                  component={Committee}
                />
                <Route exact path="/csi_videos_screen" component={CsiVideos} />
                <Route
                  exact
                  path="/about/secretarial_staff/:type"
                  component={Staffs}
                />
                <Route
                  exact
                  path="/about/executive_committee"
                  component={Committees}
                />
                <Route exact path="/about/csita" component={Csita} />
                <Route exact path="/tester" component={AdvertisementHome} />
                <Route
                  exact
                  path="/campaign_screen/:id"
                  component={CampaignViewScreen}
                />
                <Route
                  exact
                  path="/news_screen/:type/:id/"
                  component={NewsScreen}
                />
                <Route
                  exact
                  path="/resources/publications_screen"
                  component={PublicationScreen}
                />
                <Route
                  exact
                  path="/publication_details"
                  component={PublicationDetails}
                />
                <Route
                  exact
                  path="/publication_details/:id"
                  component={PublicationDetails}
                />
                <Route
                  exact
                  path="/resources/csi_life_screen"
                  component={CsiLifeScreen}
                />
                <Route
                  exact
                  path="/resources/resource_docs"
                  component={ResourceDocs}
                />
                <Route
                  exact
                  path="/about/partners_screen"
                  component={PartnersScreen}
                />
                <Route
                  exact
                  path="/about/networks_screen"
                  component={NetworksScreen}
                />
                <Route exact path="/home/event" component={Event} />
                <Route exact path="/home/event/:type" component={Event} />
                <Route exact path="/home/event/:type/:id" component={Event} />
                <Route
                  exact
                  path="/resources/worship_resource/prayer_calendar"
                  component={WorshipResource}
                />
                <Route
                  exact
                  path="/resources/worship_resource/csi_lectionary"
                  component={CsiLectionaryUserScreen}
                />
                <Route
                  exact
                  path="/resources/worship_resource/sermon_outlines"
                  component={SermonOutlinesUserScreen}
                />
                <Route
                  exact
                  path="/pagination"
                  component={PaginationComponent}
                />
                <Route
                  exact
                  path="/about/contact_us"
                  component={ContactScreen}
                />
                <Route
                  exact
                  path="/about/campaign_list_screen"
                  component={CampaignListScreen}
                />
                <Route
                  exact
                  path="/news_list_screen/:type/:id?"
                  component={DioceseNewsScreen}
                />
                <Route exact path="/others/press_release" component={Press} />
                <Route exact path="/others/statement" component={Statement} />
                <Route
                  exact
                  path="/others/library_archive"
                  component={LibraryArchive}
                />
                <Route
                  exact
                  path="/others/government_notification_schemes"
                  component={Schemes}
                />
                <Route
                  exact
                  path="/home/advertisement"
                  component={AdvertisementHome}
                />
              </Switch>
            )}
          </Switch>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={2}
            transition={Slide}
          />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
