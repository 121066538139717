import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {
  addEvents,
  deleteEvents,
  editEvents,
  getAllEvents,
  getEventsById,
} from 'models/event.model';
import { GET_ALL_DIOCESES, GET_DIOCESE, GET_DIOCESE_EVENTS } from 'utils/types.utils';
import _ from 'lodash';
import Input from 'common_components/ui/input_box/input_box.ui';
import Button from 'common_components/ui/button/button.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import ActionButton from 'common_components/ui/action_button/action_button.ui';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import { getAllDiocese, getAllDioceses } from 'models/diocese.model';
import {
  dioceseNavData,
  dioceseEventsTableHeading,
  csiUserSuggessionData,
  dioceseUserData,
  dioceseEventsBody,
} from 'helper/constants.helper';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import 'screens/diocese/diocese_events.screen.scss';
import moment from 'moment';
import { uploadMedia } from 'models/news.model';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import { toast } from 'react-toastify';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import Table from '../../components/table/table.component';
import { uploadMediaFiles, useSetState } from '../../utils/common.utils';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Filter from '../../assets/icons/Filter.svg';

export default function DioceseEvents(props) {
  const dispatch = useDispatch();
  const events = useSelector((state: any) => state.diocese.events);
  const dioceses = useSelector((state: any) => state.diocese.allDioceses);
  const dioceseUser = localStorage.getItem('diocese');
  const [state, setState] = useSetState({
    selectedData: 'diocese_events',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: '',
    user_name: '',
    limit: 20,
    page: 1,
    search: '',
    events: [],
    select_box_data: [],
    id: '',
    diocese: '',
    title: '',
    description: '',
    fromDate: '',
    toDate: '',
    url: '',
    thumbnail: '',
    loading: true,
    fileUploading: false,
    uploaded: false,
  });

  const handleGetAllDioceseEvents = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        page,
        admin: true,
        search: {
          diocese: search,
        },
        type: 'dioceses',
        diocese: dioceseUser ? [dioceseUser] : ""
      };
      const res: any = await getAllEvents(data);
      const details = res.data.docs.map((doc) => {
        doc.diocese = doc.dioceses.name;
        doc.date = moment(doc.from_date).format('DD-MM-YYYY');
        return doc;
      });
      res.data.docs = details
      dispatch({
        type: GET_DIOCESE_EVENTS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to fetch events');
    }
  };
  // const handleGetAllDiocese = async () => {
  //   try {
  //     // const data = {
  //     //   limit: 20,
  //     //   page: state.page,
  //     // };
  //     const res: any = await getAllDioceses();
  //     const value = res.data.map((dio) => {
  //       return { label: dio.name, value: dio._id };
  //     });
  //     setState({
  //       select_box_data: value,
  //     });
  //   } catch (err) {
  //     props.throwError(err.data ? err.data.desc : 'Failed to get diocese');
  //   }
  // };
  interface DioceseArray {
    label: string,
    value: string,
  }
  const dioceseData: DioceseArray[] = [];
  const dio = dioceses && dioceses.length !== 0
    && dioceses.map((diocese) => {
      dioceseData.push({ label: diocese.name, value: diocese._id });
      return "";
    });
  const getDioceses = async () => {
    try {
      const res: any = await getAllDioceses()
      dispatch({
        type: GET_ALL_DIOCESES,
        payload: res.data
      })
    } catch (err) {
         props.throwError(err.data ? err.data.desc : "Failed to Get Dioceses");
    }
  }
  useEffect(() => {
    handleGetAllDioceseEvents(state);
    getDioceses();
  }, [state.search]);
  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const handleOnClick = (edit) => {
    if (edit) {
      setState({ openModal: true, edit: true });
    } else {
      setState({ openModal: true, edit: false });
    }
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = dioceseEventsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };
  const handleAddEvents = async () => {
    try {
      const data = {
        diocese: dioceseUser ? dioceseUser : state.diocese,
        name: state.title,
        description: state.description,
        url: state.url,
        thumbnail: state.thumbnail,
        type: 'dioceses',
        from_date: new Date(state.fromDate),
        to_date: new Date(state.toDate),
      };
      if (state.thumbnail === "" || state.fromDate === '' || state.toDate === '') {
        toast.error(state.thumbnail ? "Please add Date" : "Please upload thumbnail")
      } else {
        const event = await addEvents(data);
        handleGetAllDioceseEvents({
          page: 1,
          limit: state.page * state.limit,
          search: state.search
        });
        setState({ openModal: false });
      }
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to add diocese event',
      );
    }
  };
  const handleEditEvents = async (id) => {
    try {
      setState({ openModal: true, edit: true, id });
      const res: any = await getEventsById(id);
      setState({
        title: res.data.name,
        diocese: res.data.diocese._id,
        description: res.data.description,
        url: res.data.url,
        thumbnail: res.data.thumbnail,
        fromDate: moment(res.data.from_date).format('YYYY-MM-DDTHH:MM:SS'),
        toDate: moment(res.data.to_date).format('YYYY-MM-DDTHH:MM:SS'),
        uploaded: res.data.thumbnail !== '' ? true : false,
      });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to edit diocese event',
      );
    }
  };

  const handleUpdateDioceseEvents = async () => {
    try {
      const data = {
        user_type: 'diocese',
        diocese: dioceseUser ? dioceseUser : state.diocese,
        name: state.title,
        description: state.description,
        url: state.url,
        from_date: new Date(state.fromDate),
        from_to: new Date(state.toDate),
        thumbnail: state.thumbnail,
      };
      if (state.thumbnail === "" || state.fromDate === '' || state.toDate === '') {
        toast.error(state.thumbnail ? "Please add Date" : "Please upload thumbnail")
      } else {
        const event = await editEvents(state.id, data);
        handleGetAllDioceseEvents({
          page: 1,
          limit: state.page * state.limit,
          search: state.search
        });
        setState({ openModal: false });
      }
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to update diocese event',
      );
    }
  };

  const handleDeleteEvents = async () => {
    try {
      const event = await deleteEvents(state.id);
      handleGetAllDioceseEvents({
        page: 1,
        limit: state.page * state.limit,
        search: state.search
      });
      setState({ confirmModal: false });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to delete diocese event',
      );
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search: {
          diocese: search,
        },
        type: 'dioceses',
      }
      const res: any = await getAllEvents(data)
      const details = res.data.docs.map((doc) => {
        doc.diocese = doc.dioceses.name;
        doc.date = moment(doc.from_date).format('DD-MM-YYYY');
        return doc;
      });
      res.data.docs = details
      res.data.docs = [...events.docs, ...res.data.docs]
      dispatch({
        type: GET_DIOCESE_EVENTS,
        payload: res.data
      })
      setState({
        loading: false
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get events')
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 })
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }
  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
  };
  const handleConfirm = (id) => {
    setState({ confirmModal: true, id });
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit Event',
      onClick: handleEditEvents,
      icon: Edit,
    },
    {
      type: 'secondary',
      text: 'Delete Event',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  return (
    <div className="diocese_events_screen">
      <div className="diocese_events_container">
        <div className="diocese_events_navbar_wrapper">
          <CustomNavBar
            data={dioceseNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add Event"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={() =>
              setState({
                openModal: true,
                edit: false,
                id: '',
                diocese: '',
                title: '',
                description: '',
                date: '',
                url: '',
                thumbnail: '',
                fromDate: '',
                toDate: '',
              })}
            button
            search
            onSearchChange={searchChange}
          />
        </div>
        <div className="diocese_events_table_wrapper">
          {state.loading ? (
            <Spinner />
          ) : (
              <Table
                header={dioceseEventsTableHeading}
                body={events ? events.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMoreInitial}
                hasMore={events && events.hasNextPage ? events.hasNextPage : ''}
                actionButton={actionArray}
                error="No events found"
              />
            )}
        </div>
        <div>
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'department_events_modal',
              closeButton: 'close_button',
            }}
            showCloseIcon
          >
            <div className="department_events_modal_title_wrapper">
              <div className="department_events_modal_title h4">
                {state.edit !== true ? 'Add Event' : 'Edit Event'}
              </div>
            </div>
            <div className="department_events_modal_line_wrapper"> </div>
            <div className="department_events_modal_input_wrapper">
              <div className="department_events_modal_input">
                {dioceseUser ? null
              : <div className="department_events_input">
                  <SelectBox
                    data={dioceseData ? dioceseData : []}
                    name="Select Diocese"
                    selectedItem={state.diocese}
                    onChange={(selectedOption) =>
                      setState({ diocese: selectedOption.value })}
                  />
                </div>}
                <div className="department_events_input">
                  <Input
                    name="Title"
                    onChange={handleOnChange}
                    inputKey="title"
                    value={state.title}
                  />
                </div>
                <div className="department_events_input">
                  <TextArea
                    name="Description"
                    onChange={handleOnChange}
                    inputKey="description"
                    value={state.description}
                  />
                </div>
                <div className="department_events_input">
                  <Input
                    name="URL"
                    onChange={handleOnChange}
                    inputKey="url"
                    value={state.url}
                  />
                </div>
              </div>
              <div className="department_events_modal_input">
                <div className="department_events_input_from_date">
                  <Input
                    name="From"
                    type="datetime-local"
                    onChange={handleOnChange}
                    inputKey="fromDate"
                    value={state.fromDate}
                  />
                </div>
                <div className="department_events_input_to_date">
                  <Input
                    name="To"
                    type="datetime-local"
                    onChange={handleOnChange}
                    inputKey="toDate"
                    value={state.toDate}
                  />
                </div>
                <div className="department_events_thumbnail_wrapper">
                  Thumbnail
                </div>
                <div className="department-events_modal_uploadbox">
                  <UploadBox
                    className="upload"
                    name="thumbnail"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={handleUploadThumbnail}
                    text="Drag and Drop"
                    loading={state.fileUploading}
                    uploaded={state.uploaded}
                  />
                </div>
              </div>
            </div>
            <div className="department_events_button_wrapper">
              <div className="department_events_button">
                <Button
                  text={state.edit ? 'Update Events' : 'Add Event'}
                  type="primary"
                  onClick={
                    state.edit ? handleUpdateDioceseEvents : handleAddEvents
                  }
                />
              </div>
            </div>
          </Modal>
        </div>
        <ConfirmModal
          openModal={state.confirmModal}
          description="Are you sure want to delete the Diocese News ?"
          closeModal={() => setState({ confirmModal: false })}
          deleteConfirm={handleDeleteEvents}
        />
      </div>
    </div>
  );
}
