import React from 'react';
import './worship_resources.component.scss';
import Calendar from "assets/images/Prayer_calender_Thumbnail for home page.jpg";
import Lectionary from 'assets/images/CSI Lectionary_Home page Thumbnail.jpg';
import Sermon from 'assets/images/Sermons_Home page thumnail.jpg';
import ResourcesCard from '../resource_card/resource_card.component';
import Background from '../../assets/images/wallpaper.jpg';

interface WorshipResourcesProps {
  data: any
}

const WorshipResources = (props) => {
  return (
    <div className="worship_resources_main_container">
      <div className="worship_resources_main_content_1_wrapper">
        <div className="worship_resources_main_content_1_content">
          <div className="worship_resources_title_wrapper">
            <div className="worship_resources_title p3">Worship Resources</div>
            <div className="underline_wrapper" />
            <div className="worship_resources_subheading p2">Download our Worship Resources</div>
          </div>
          <div className="worship_resources_resources_card_wrapper">
            <div className="worship_resources_resource_card_wrapper">
              <div className="worship_resources_resource_card">
                <ResourcesCard link={Calendar} type="primary" name="Prayer Calendar" onClick={() => props.history.push('/resources/worship_resource/prayer_calendar')} />
              </div>
              <div className="worship_resources_resource_card">
                <ResourcesCard type="primary" link={Lectionary} name="CSI Lectionary" onClick={() => props.history.push('/resources/worship_resource/csi_lectionary')} />
              </div>
              <div className="worship_resources_resource_card">
                <ResourcesCard link={Sermon} name="Sermons" type="primary" onClick={() => props.history.push('/resources/worship_resource/sermon_outlines')} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="worship_resources_main_content_2"> </div>
    </div>
  )
}

export default WorshipResources;
