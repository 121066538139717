import React from 'react';
import './magazine.component.scss';
import Carousel from 'react-elastic-carousel';
import Button from 'components/button/button.component';
import moment from 'moment';
import MagazineBackground from "assets/images/background.jpg";
import MagazineCard from '../magazine_card/magazine_card.component';
import arrow from '../../assets/icons/Arrow_right_fill.svg';
import ArrowLarge from '../../assets/icons/Arrow Line_Right Large Copy 11.svg';
import Magazine1 from '../../assets/images/wallpaper.jpg';
// import Magazine2 from '../../assets/images/CSI_life.png';
// import Magazine3 from '../../assets/images/CSI_life.png';

interface MagazineProps{
  data: any,
  Click: any
}

const Magazine = (props: MagazineProps) => {
  const { data, Click } = props;
  const arrows = ({ type, onClick }) => {
    return (
      <div onClick={onClick} className="arrow_container">
        {type === 'PREV' ? <img src={ArrowLarge} className="arrow_left" alt="arrow_previous" /> : <img src={ArrowLarge} className="arrow_right" alt="arrow_next" />}
      </div>
    )
  }

  return (
    <div className="magazine_main_container">
      <div className="magazine_background_image_container">
        <div className="magazine_main_wrapper">
          <div className="heading_main_container">
            <div className="magazine_heading_container">
              <div className="magazine_heading_wrapper">
                <div className="magazine_heading h2">CSI Life Magazine</div>
                <div className="underline_wrapper" />
              </div>
              <div className="magazine_viewarchives_wrapper" onClick={Click}>
                <div className="viewarchives">View Archives
                  <img src={arrow} alt="" className="arrow_image" />
                </div>
              </div>
            </div>
          </div>
          <div className="magazine_container">
            <div className="magazine_wrapper">
            <Carousel
              renderArrow={arrows}
              className="carousel_synod_video"
              pagination={false}
              itemsToScroll={1}
              itemsToShow={window.innerWidth <= 600 ? 1 : 3}
            >
              {data.map((m, index) =>
                <div className="magazine_card">
                  <MagazineCard link={m.thumbnail} url={m.pdf[0]} magazineDate={m.title} />
                </div>)}
            </Carousel>
            </div>
          </div>
          {/* <div className="magazine_viewarchives_wrapper" onClick={Click}> */}
                {/* <div className="viewarchives">View Archives
                  <img src={arrow} alt="" className="arrow_image" />
                </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default Magazine;
