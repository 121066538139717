import { instance } from '../helper/axios.helper';

export const getAllNews = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('/news/get', body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const addNews = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('news/create', body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const editNews = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`news/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getNews = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`news/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getNewsDetails = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`news/get_news_details`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const updateActiveMultiple = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`news/active_many`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const deleteNews = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`news/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const uploadMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/upload_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const removeMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/remove_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
