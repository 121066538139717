import { instance } from '../helper/axios.helper';

export const addEvents = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('events/create', data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const editEvents = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`events/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getAllEvents = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`events/get`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const getEventsById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`events/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}

export const deleteEvents = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`events/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      })
  })
  return promise;
}
