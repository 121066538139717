import React from 'react';
import Select from "react-select";
import _ from 'lodash';
import './select_box.ui.scss';

interface DataArray {
  label: string,
  value: string,
}

interface SelectProps {
  data: DataArray[],
  selectedItem: string,
  onChange: Function,
  name: string
}

const SelectBox = (props: SelectProps) => {
  const { data, selectedItem, onChange, name } = props;
  const style = {
    control: (base, state) => ({
      ...base,
      border: 0,
      borderRadius: 0,
      fontSize: '12px',
      textColor: "#585871",
      // marginLeft: '-2px',
      borderBottom: state.isFocused
        ? "1.5px solid #8E033F" : "1.5px solid #585871",
      '&:hover': {
        borderBottom: "1.5px solid #8E033F"
      },
      // This line disable the blue border
      boxShadow: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused
        ? '#8E033F' : '#585871'
    })
  };
  return (
    <div className="select_box_component">
      <div className="select_box_container">
        <div className="select_box_wrapper">
          <div className="select_box_content">
            <Select
              components={{ IndicatorSeparator: () => null }}
              value={_.filter(data, { value: selectedItem })[0]}
              options={data}
              styles={style}
              className="select_box"
              onChange={onChange}
            />
            <div className="select_box_label p6">{name}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectBox;
