import React from 'react';
import './text_area.ui.scss';

interface TextAreaProps {
  value: string,
  onChange: any,
  fontSize?: string,
  marginX?: string,
  marginY?: string,
  className?: string,
  multiline?: boolean,
  name: string,
  maxLength?: number,
  inputKey: string,
  background?: string
}

const TextArea = (props: TextAreaProps) => {
  const { value, onChange, fontSize, marginX, marginY,
    className, multiline, name, inputKey, background, maxLength } = props;
  return (
    <div className={`text_area_container ${className || ''}`}>
      <div className="text_area_wrapper">
        <div className="styled-input">
          <textarea
            value={value}
            style={{ fontSize, backgroundColor: background }}
            onChange={(eve) => onChange(eve, inputKey)}
            required
            maxLength={maxLength}
          />
          <div className="label" style={{ fontSize }}>{name}</div>
          <span> </span>
        </div>
      </div>
    </div>
  )
}

TextArea.defaultProps = {
  fontSize: '',
  marginX: '',
  maxLength: '',
  marginY: '',
  className: '',
  multiline: false,
  background: ""
}

export default TextArea;
