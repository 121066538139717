import React, { useEffect } from "react";
import "./upload_box.ui.scss";
import Tick from "assets/icons/Tick_White.svg";
import Spinner from "assets/images/loader.gif";
import ArrowIcon from "../../../assets/icons/Arrow_right.svg";

interface UploadBoxProps {
  text: String,
  onChange: any,
  name: string,
  accept: string,
  multiple?: boolean,
  uploaded?: boolean,
  loading?: boolean,
  className: string
}
export default function UploadBox(props: UploadBoxProps) {
  const { text, onChange, name, accept, multiple, uploaded, loading, className } = props;

  useEffect(() => {
    // const dropArea: any = document.querySelector(`.upload_box_container`);
    const dropArea: any = document.getElementById(className);
    console.log("DropArea", dropArea);
    const inputContainer = document.getElementById(`${name}`);
    dropArea.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropArea.classList.add("upload_box_container_hover");
    })
    dropArea.addEventListener('dragleave', () => {
      dropArea.classList.remove("upload_box_container_hover");
    })
    dropArea.addEventListener("drop", (e) => {
      e.preventDefault();
      dropArea.classList.remove("upload_box_container_hover");
      onChange(e);
    })
  }, [])

  return (
    <div className="upload_box_container" id={className}>
      <div className="upload_box_wrapper">
        <label className="upload_box_label_content" htmlFor={name}>
          {
            loading
              ? <div className="upload_box_icon_loading_content">
                <img alt="" src={Spinner} className="upload_box_loading_icon" />
                </div>
              : <div className="upload_box_icon_content">
                <img alt="" src={uploaded ? Tick : ArrowIcon} className={uploaded ? "upload_box_uploaded_icon" : "upload_box_icon"} />
                </div>
          }
          <div className="upload_box_title p6">
            {uploaded ? "Files Uploaded" : text}
            {uploaded ? "" : <span className="span_link">Browse</span>}
          </div>
          <input id={name} className="upload_box_input" accept={accept} multiple type="file" onChange={onChange} />
        </label>
      </div>
    </div>
  )
}

UploadBox.defaultProps = {
  multiple: '',
  uploaded: '',
  loading: '',
}
