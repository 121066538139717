import React from "react";
import "./info_card.ui.scss"

interface InfoCardProps {
  title: String,
  icon?: string,
  text: String
}

export default function InfoCard(props: InfoCardProps) {
  const { title, icon, text } = props;
  return (
    <div className="info_card_container">
      <div className="info_card_wrapper">
        <div className="info_card_content">
          <img alt="" src={icon} className="info_card_icon" />
        </div>
        <div className="info_card_title h6">{title}</div>
      </div>
      <div className="info_card_text_wrapper">
        <div className="info_card_text h1">{text}</div>
      </div>
    </div>
  )
}

InfoCard.defaultProps = {
  icon: ""
}
