import { instance } from '../helper/axios.helper';

export const getAllUser = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('public/get_users_list', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const getUserById = async (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`user/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}

export const addUser = async (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('user/add', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}

export const editUser = async (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`user/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const deleteUser = async (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`user/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}

export const searchUser = async (searchTerm) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('user/get/', searchTerm)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const dioceseDetails = async (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('diocese/get', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const departmentDetails = async (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('department/get', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
