import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import 'screens/workship_resources/sermon_outlines.screen.scss';
import Table from 'components/table/table.component';
import _ from 'lodash';
import PlusIcon from 'assets/icons/Plus_White.svg';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import Button from 'common_components/ui/button/button.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import EditIcon from 'assets/icons/Edit.svg';
import DeleteIcon from 'assets/icons/Delete.svg';
import InactiveIcon from 'assets/icons/Inactive_Pink.svg';
import {
  workshipAndResourcesNavData,
  sermonOutlineTableHeading,
  csiUserSuggessionData,
  sermonOutlinesSelectionData,
} from 'helper/constants.helper';
import { GET_SERMON_OUTLINE_LIST } from 'utils/types.utils';
import {
  getAllPublication,
  editPublication,
  getPublicationById,
  deletePublication,
  uploadMedia,
  addPublication,
} from 'models/publication.model';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import { uploadMediaFiles, useSetState } from '../../utils/common.utils';

export default function SermonOutline(props) {
  const { throwError } = props;
  const dispatch = useDispatch();
  const sermonList = useSelector(
    (state: any) => state.worship_resources.sermon_outline_list,
  );
  const [state, setState] = useSetState({
    selectedData: 'sermon_outlines',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    user_name: '',
    mobile: '',
    loading: true,
    email: '',
    password: '',
    pdf: [],
    uploaded: false,
    pdfUploaded: false,
    pdfLoaded: false,
    fileUploading: false,
    resourceName: '',
    type: 'sermons',
    thumbnail: '',
    sermon_category: '',
    sermon_filter: '',
    user_id: '',
    search: '',
    limit: 30,
    page: 1,
  });

  const fetchSermonDetailsList = async ({ page, limit, search, }) => {
    try {
      const data = {
        page,
        limit,
        search,
        type: state.type,
        sermon_category: state.sermon_filter
      };

      const res: any = await getAllPublication(data);
      dispatch({
        type: GET_SERMON_OUTLINE_LIST,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Unable to fetch details');
      setState({
        loading: true,
      });
    }
  };
  useEffect(() => {
    fetchSermonDetailsList(state);
  }, [state.search, state.sermon_filter]);

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleEditSermon = async (id) => {
    setState({ openModal: true, edit: true });
    try {
      const res: any = await getPublicationById(id);
      setState({
        title: res.data.title,
        pdf: res.data.pdf,
        sermon_category: res.data.sermon_category,
        edit: true,
        user_id: res.data._id,
        resourceName: res.data.resource_name,
        thumbnail: res.data.thumbnail,
        uploaded: res.data.thumbnail ? true : false,
        pdfUploaded: res.data.pdf ? true : false,
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Unable to get details');
    }
  };

  const handleDeleteSermon = async () => {
    setState({ confirmModal: true });
    try {
      await deletePublication(state.user_id);
      fetchSermonDetailsList({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
      setState({
        confirmModal: false,
        sermon_filter: '',
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Unable to delete details');
    }
  };

  const handleAddSermon = async () => {
    try {
      const data = {
        thumbnail: state.thumbnail,
        pdf: state.pdf,
        title: state.title,
        uploaded: false,
        fileUploading: false,
        pdfUploaded: false,
        pdfLoading: false,
        type: state.type,
        resource_name: state.resourceName,
        sermon_category: state.sermon_category,
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        await addPublication(data);
        fetchSermonDetailsList({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
        setState({
          title: '',
          openModal: false,
          edit: false,
          description: '',
          sermon_category: '',
          sermon_filter: '',
        });
      }
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Unable to add details');
    }
  };
  const handleUpdateSermonDetails = async () => {
    try {
      const data = {
        title: state.title,
        type: state.type,
        sermon_category: state.sermon_category,
        pdf: state.pdf,
        resource_name: state.uploaded_file,
        thumbnail: state.thumbnail,
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        await editPublication(state.user_id, data);
        setState({
          openModal: false,
          edit: true,
          sermon_filter: '',
        });
        fetchSermonDetailsList({
          page: 1,
          limit: state.page * state.limit,
          search: state.search
        });
      }
    } catch (err) {
      throwError(err.data ? err.data.desc : 'Unable to update details');
    }
  };
  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        type: state.type,
        sermon_category: state.sermon_filter
      };
      const res: any = await getAllPublication(data);
      res.data.docs = [...sermonList.docs, ...res.data.docs]
      dispatch({
        type: GET_SERMON_OUTLINE_LIST,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get users');
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      limit: state.limit,
      page: state.page + 1,
      search: state.search,
    });
  };
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
  };

  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: pdf.url });
            const fileName = pdf.url.split('/')[4];
            setState({
              resourceName: fileName,
              pdfUploaded: true,
              pdfLoading: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        const data = new FormData();
        setState({ pdfLoading: true });
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const pdf: any = await uploadMedia(data);
        if (pdf) {
          setState({ pdf: pdf.url });
          const fileName = pdf.url.split('/')[4];
          setState({
            resourceName: fileName,
            pdfUploaded: true,
            pdfLoading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const handleDelete = (id) => {
    setState({ confirmModal: true, user_id: id });
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditSermon,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDelete,
      icon: DeleteIcon,
    },
  ];
  const selectBoxData = [
    {
      label: 'Sermon Outlines',
      value: 'sermon_outlines',
    },
    {
      label: 'Lent',
      value: 'lent',
    },
    {
      label: 'Advent',
      value: 'advent',
    },
    {
      label: 'Bible Study',
      value: 'bible_study',
    },
  ];
  return (
    <div className="sermon_outline_screen">
      <div className="sermon_outline_container">
        <div className="sermon_outline_wrapper">
          <div className="sermon_outline_header_wrapper">
          <div className="sermon_outline_title_wrapper">
          <div className="officers_name_wrapper h2">
              Worship Resources
          </div>
          </div>
          <div className="sermon_outline_action_wrapper">
            <div className="sermon_outline_search_box_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                suggessionData={csiUserSuggessionData}
                onChange={searchChange}
              />
            </div>
            <div className="sermon_outline_select_box_wrapper">
              <SelectBox
                data={sermonOutlinesSelectionData}
                name=""
                selectedItem={state.sermon_filter}
                onChange={(selectedOption) => {
                  setState({ sermon_filter: selectedOption.value, page: 1 });
                }}
              />
            </div>
            <div className="sermon_outline_button_wrapper">
              <Button
                type="primary"
                className="sermon_outline_button"
                text="Add Sermon Outlines"
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    title: '',
                    thumbnail: '',
                    pdfUploaded: false,
                    pdf: '',
                    fileUploading: false,
                    uploaded: false,
                    pdfLoading: false,
                  })}
                icon={PlusIcon}
              />
            </div>
          </div>
          </div>
          <div className="sermon_outline_custom_navbar_wrapper">
            <CustomNavBar
              data={workshipAndResourcesNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddSermon}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          {state.loading ? (
            <Spinner />
          ) : (
              <>
                <div className="sermon_outline_table-wrapper">
                  <Table
                    header={sermonOutlineTableHeading}
                    body={sermonList ? sermonList.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    hasMore={sermonList.hasNextPage}
                    actionButton={actionArray}
                    error="No details found"
                  />
                </div>
                <div className="sermon_outline_modal_container">
                  <Modal
                    open={state.openModal}
                    onClose={() => setState({ openModal: false })}
                    center
                    classNames={{
                      overlay: 'customOverlay',
                      modal: 'sermon_outline_customModal',
                      closeButton: 'close_button',
                    }}
                    showCloseIcon
                  // closeIcon={inactiveIcon}
                  >
                    <div className="sermon_outline_modal_title_wrapper">
                      <div className="sermon_outline_modal_title h4">
                        {state.edit !== true ? 'Add Sermon' : 'Edit Sermon'}
                      </div>
                    </div>
                    <div className="sermon_outline_modal_line_wrapper"> </div>
                    <div className="sermon_outline_modal_input_wrapper">
                      <div className="sermon_outline_modal_input">
                        <Input
                          name="Title"
                          onChange={handleOnChange}
                          type="text"
                          inputKey="title"
                          value={state.title}
                        />
                      </div>
                      <div className="sermon_outline_modal_input">
                        <SelectBox
                          data={selectBoxData}
                          name="Category"
                          selectedItem={state.sermon_category}
                          onChange={(selectedOption) => {
                            setState({ sermon_category: selectedOption.value });
                          }}
                        />
                      </div>
                      <div className="sermon_outline_modal_input">
                        <div className="upload_box_wrapper">
                          Thumbnail
                        <div className="upload_box">
                            <UploadBox
                              className="image"
                              loading={state.fileUploading}
                              uploaded={state.uploaded}
                              name="thumbnail"
                              accept="image/x-png,image/gif,image/jpeg"
                              text="Drag and Drop or"
                              onChange={handleUploadThumbnail}
                            />
                        </div>
                        </div>
                      </div>
                      <div className="sermon_outline_modal_input">
                        <div className="upload_box_wrapper">
                          Upload PDF
                        <div className="upload_box">
                            <UploadBox
                              className="upload"
                              loading={state.pdfLoading}
                              uploaded={state.pdfUploaded}
                              name="uploadpdf"
                              accept=".pdf,.docx"
                              text="Drag and Drop or"
                              onChange={handleUploadPDF}
                            />
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="sermon_outline_modal_button_wrapper">
                      <div className="sermon_outline_modal_button">
                        <Button
                          text={state.edit ? 'Update Sermon' : 'Add Sermon'}
                          type="primary"
                          onClick={
                            state.edit
                              ? handleUpdateSermonDetails
                              : handleAddSermon
                          }
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
                <ConfirmModal
                  openModal={state.confirmModal}
                  description="Are you sure want to delete this ?"
                  closeModal={() => setState({ confirmModal: false })}
                  deleteConfirm={handleDeleteSermon}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
}
