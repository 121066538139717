import { GET_VIDEOS } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  loading: true,
  error: false
};

const videoReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_VIDEOS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default videoReducer;
