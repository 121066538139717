import { instance } from "../helper/axios.helper";

export const getAllVideo = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post("videos/get", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response)
        } else {
          reject(err)
        }
      });
  });
  return promise;
}

export const addVideo = (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/create`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const getVideoById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`videos/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const editVideo = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`videos/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const deleteVideo = async (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`videos/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const uploadMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/upload_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}

export const removeMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/remove_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
