import React, { useRef, useEffect } from 'react';
import './library_archives.screen.scss';
import '../../index.scss';
import Pagination from 'react-js-pagination';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import ResourcesCard from 'components/resources_card/resources_card';
import { getMonth, getYear, throwError, useSetState } from 'utils/common.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { csiMonthSelectionData } from 'helper/constants.helper';
import PaginationComponent from 'components/pagination/pagination.component';
import Navbar from 'components/navbar/navbar.component';
import { getAllPublication } from "models/public.model";
import { GET_ARCHIVES } from 'utils/types.utils';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Eye from '../../assets/icons/Eye.svg'

export default function LibraryArchive(props) {
  const dispatch = useDispatch();
  const libraryArchives = useSelector((state: any) => state.publication.archives);
  const [state, setState] = useSetState({
    searchValue: "",
    loading: true,
    year: '',
    page: 1,
    activePage: 1,
    limit: 21,
    month: ''
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };

  const handleGetAllArchives = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        sort: true,
        search: state.searchValue,
        type: "library_archives"
      };
      const archives: any = await getAllPublication(data);
      const doc = archives.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      })
      archives.data.docs = doc;
      dispatch({
        type: GET_ARCHIVES,
        payload: archives.data
      })
      setState({ loading: false })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Publication details")
    }
  }
  const handleApplyFilters = async (month, year) => {
    try {
      const data = {
        limit: 20,
        page: state.page,
        search: "",
        type: "library_archives",
        special_edition: "all",
        month,
        year,
      };
      const archives: any = await getAllPublication(data);
      const doc = archives.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      })
      archives.data.docs = doc;
      dispatch({
        type: GET_ARCHIVES,
        payload: archives.data
      })
      setState({ filterModal: false })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get filter Publications")
    }
  }

  useEffect(() => {
    handleGetAllArchives()
  }, [state.page])
  return (
    <div className="screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="lib_archives_screen_container">
        <div className="lib_archives_screen_header_wrapper">
          <div className="lib_archives_screen_header h2">
            Library & Archives
            <div className="header_line" />
          </div>
        </div>
        <div className="select_box_wrapper">
          <div className="select_box">
            <SelectBox
              data={csiMonthSelectionData}
              name=""
              selectedItem={state.month}
              onChange={(selectedOption) => {
                 handleApplyFilters(selectedOption.value, state.year)
                 setState({
                   month: selectedOption.value
                 })
                }}
            />
          </div>
          <div className="select_box">
            <SelectBox
              data={getYear(1947)}
              name=""
              selectedItem={state.year}
              onChange={(selectedOption) => {
                handleApplyFilters(state.month, selectedOption.value)
                setState({
                  year: selectedOption.value
                })
               }}
            />
          </div>
        </div>
        <div className="lib_archives_screen_body_wrapper">
          {state.spinner ? <Spinner /> : libraryArchives && libraryArchives.docs.length > 0
          ? libraryArchives.docs.map((library) => {
            return (
          <div className="card_wrapper">
            <ResourcesCard
              onClick={() => {}}
              pdfLink={library.pdf}
              hoverText="View"
              title={library.title}
              icon={Eye}
              type="publications"
              url={library.thumbnail}
            />
          </div>
            )
            }) : <div className="error_text">No Resources found</div> }
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            libraryArchives && libraryArchives.totalDocs ? libraryArchives.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
