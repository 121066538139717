import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PlusIcon from 'assets/icons/Plus_White.svg';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import Table from 'components/table/table.component';
import EditIcon from 'assets/icons/Edit.svg';
import DeleteIcon from 'assets/icons/Delete.svg';
import Active from 'assets/icons/Tick_White.svg';
import Inactive from 'assets/icons/Inactive_Pink.svg';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import {
  deleteCampaign,
  editCampaignDetails,
  getCampaignDetails,
  getCampaignDetailsById,
} from 'models/campaign.model';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import Button from 'common_components/ui/button/button.ui';
import { GET_CAMPAIGNS } from 'utils/types.utils';
import 'screens/campaign/campaign.screen.scss';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import {
  campaignTableHeading,
  campaignBody,
  csiUserSuggessionData,
  campaignSelectBoxData,
} from 'helper/constants.helper';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import { useSetState } from '../../utils/common.utils';

export default function Campaign(props) {
  const dispatch = useDispatch();
  const campaignDetails = useSelector((state: any) => state.campaigns);
  const [state, setState] = useSetState({
    selectedData: 'campaigns',
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    title: '',
    description: '',
    thumbnail: '',
    limit: 25,
    search: '',
    loading: true,
    id: '',
    page: 1,
    imageUploaded: false,
    activated: 'all',
    imageUploading: false,
    gallery: [],
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const handleGetAllCampaignDetails = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        search,
        activated: state.activated,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        return doc
      });
      res.data.docs = details
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to get campaign details',
      );
    }
  };

  useEffect(() => {
    handleGetAllCampaignDetails(state);
  }, [state.search, state.activated]);
  const handleToggleActive = async (id) => {
    try {
      const res: any = await getCampaignDetailsById(id);
      const data = {
        activated: !res.data.activated,
      };
      await editCampaignDetails(id, data);
      handleGetAllCampaignDetails({
         page: 1,
         limit: state.page * state.limit,
         search: state.search,
         });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to toggle campaign');
    }
  };
  const handleFilterCampaign = async (selectedOption) => {
    setState({ activated: selectedOption.value, page: 1 });
  };
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleDeleteCampaign = (id) => {
    setState({ confirmModal: true, id });
  };
  const handleAddCampaign = () => {
    props.history.push('/campaigns/add_campaign');
  };

  const handleEditCampaign = (id) => {
    props.history.push(`/campaigns/edit_campaign/${id}`);
  };

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        activated: state.activated,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        return doc
      });
      res.data.docs = details
      res.data.docs = [...campaignDetails.docs, ...res.data.docs]
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to get campaign details',
      );
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 })
    loadMore({
      page: state.page + 1, limit: state.limit, search: state.search
    })
  }
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditCampaign,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDeleteCampaign,
      icon: DeleteIcon,
    },
  ];

  const handleOnDeleteCampaign = async () => {
    try {
      await deleteCampaign(state.id);
      setState({ confirmModal: false });
      handleGetAllCampaignDetails({
        page: 1, limit: state.page * state.limit, search: state.search
        })
      } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to delete resource');
    }
  };

  return (
    <div className="campaign_screen">
      <div className="campaign_container">
        <div className="campaign_wrapper">
          <div className="campaign_header_wrapper">
          <div className="campaign_title_wrapper">
          <div className="officers_name_wrapper h2">
              Campaigns
          </div>
          </div>
          <div className="campaign_action_wrapper">
            <div className="campaign_search_box_wrapper">
              <SearchBar
                suggession=""
                suggessionData={csiUserSuggessionData}
                type="secondary"
                onChange={searchChange}
              />
            </div>
            <div className="campaign_select_box_wrapper">
              <SelectBox
                data={campaignSelectBoxData}
                name=""
                selectedItem={state.activated}
                onChange={(selectedOption) => {
                  handleFilterCampaign(selectedOption);
                }}
              />
            </div>
            <div className="campaign_button_wrapper">
              <Button
                type="primary"
                text="Add Campaign"
                onClick={handleAddCampaign}
                icon={PlusIcon}
              />
            </div>
          </div>
          </div>
          <div className="sermon_outline_table-wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={campaignTableHeading}
                body={campaignDetails ? campaignDetails.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMoreInitial}
                hasMore={
                  campaignDetails && campaignDetails.hasNextPage
                  ? campaignDetails.hasNextPage : false
                }
                actionButton={actionArray}
                error="No campaigns found"
                active
                onClick={handleToggleActive}
              />
            )}
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete this resource?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleOnDeleteCampaign}
          />
        </div>
      </div>
    </div>
  );
}
