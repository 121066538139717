import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css'
import moment from 'moment';
import { Modal } from "react-responsive-modal";
import "screens/workship_resources/prayer_calendar.screen.scss";
import Table from "components/table/table.component";
import _ from "lodash";
import PlusIcon from "assets/icons/Plus_White.svg";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Button from "common_components/ui/button/button.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import EditIcon from "assets/icons/Edit.svg";
import Input from "common_components/ui/input_box/input_box.ui";
import DeleteIcon from "assets/icons/Delete.svg";
import { workshipAndResourcesNavData, prayerCalendarTableHeading, csiUserSuggessionData } from "helper/constants.helper";
import { useDispatch, useSelector } from 'react-redux';
import { getPrayerCalendarList, searchDetails, deletePrayerCalendarDetails, addPrayerCalendarList, editPrayerCalendar, getPrayerCalendarDetails } from 'models/prayer_calendar_model';
import { GET_PRAYER_CALENDAR_LIST } from 'utils/types.utils';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import { useSetState } from "../../utils/common.utils";

export default function PrayerCalendar(props) {
  const { throwError } = props
  const dispatch = useDispatch()
  const prayerList = useSelector((state: any) => state.worship_resources.prayer_calendar_list)
  const [state, setState] = useSetState(
    {
      selectedData: "prayer_calendar",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      confirmModal: false,
      loading: true,
      date: "",
      description: "",
      limit: 30,
      search: '',
      page: 1,
      user_id: ''
    });

  const fetchPrayerDetails = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
      }
      const res: any = await getPrayerCalendarList(data);
      const userList = res.data.map((user) => {
        user.dates = moment(user.date).format('DD-MM-YYYY');
        return user
      });
      res.data.docs = userList
      dispatch({
        type: GET_PRAYER_CALENDAR_LIST,
        payload: res.data
      })
      setState({
        loading: false
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to fetch prayer calendar list")
      setState({
        loading: true
      })
    }
  }

  useEffect(() => {
    fetchPrayerDetails(state)
  }, [state.search])

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleEditCalendar = async (id) => {
    setState({ openModal: true, edit: true })
    try {
      const res: any = await getPrayerCalendarDetails(id);
      setState({
        date: res.data.date,
        description: res.data.description,
        edit: true,
        user_id: res.data._id
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to get details")
    }
  }

  const handleUpdateCalendarDetails = async () => {
    try {
      const data = {
        date: state.date,
        description: state.description
      }
      await editPrayerCalendar(state.user_id, data)
      setState({
        openModal: false,
        edit: true
      })
      fetchPrayerDetails({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to update details")
    }
  }

  const handleDelete = (id) => {
    setState({ confirmModal: true, user_id: id })
  }
  const handleDeleteCalendar = async () => {
    try {
      await deletePrayerCalendarDetails(state.user_id)
      fetchPrayerDetails({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      })
      setState({
        confirmModal: false
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to delete detais")
    }
  }
  const handleAddCalendar = async () => {
    try {
      const data = {
        description: state.description,
        date: state.date,
      }
      await addPrayerCalendarList(data)
      fetchPrayerDetails({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      })
      setState({
        date: '',
        description: '',
        user_id: '',
        openModal: false
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to add details")
    }
  }

  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
      }
      const res: any = await getPrayerCalendarList(data);
      const userList = res.data.map((user) => {
        user.dates = moment(user.date).format('DD-MM-YYYY');
        return user
      });
      res.data.docs = userList
      res.data.docs = [...prayerList.docs, ...res.data.docs]
      dispatch({
        type: GET_PRAYER_CALENDAR_LIST,
        payload: res.data
      })
      setState({
        loading: false
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to fetch prayer calendar list")
      setState({
        loading: true
      })
    }
  }

const loadMoreInitial = () => {
  setState({ page: state.page + 1 })
  loadMore({
    page: state.page + 1,
    limit: state.limit,
    search: state.search
  })
}

  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditCalendar,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDelete,
      icon: DeleteIcon
    }
  ];

  return (
    <div className="prayer_calendar_screen">
      <div className="prayer_calendar_container">
        <div className="prayer_calendar_wrapper">
          <div className="prayer_calendar_header_wrapper">
          <div className="prayer_calendar_title_wrapper">
          <div className="officers_name_wrapper h2">
              Worship Resources
          </div>
          </div>
          <div className="prayer_calendar_action_wrapper">
            <div className="prayer_calendar_search_box_wrapper">
              <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={searchChange} />
            </div>
            <div className="prayer_calendar_button_wrapper">
              <Button type="primary" text="Add Prayer Calendar" onClick={() => setState({ openModal: true, edit: false, date: "", description: "" })} icon={PlusIcon} />
            </div>
          </div>
          </div>
          <div className="prayer_calendar_custom_navbar_wrapper">
            <CustomNavBar
              data={workshipAndResourcesNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={() => setState({
                openModal: true,
                edit: false,
              })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          {state.loading ? <Spinner />
            : <>
              <div className="prayer_calendar_table-wrapper">
                <Table
                  header={prayerCalendarTableHeading}
                  body={prayerList ? prayerList.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={prayerList.hasNextPage}
                  actionButton={actionArray}
                  error="No details found"
                />
              </div>
              <div className="prayer_calendar_modal_container">
                <Modal
                  open={state.openModal}
                  onClose={() => setState({ openModal: false })}
                  center
                  classNames={{
                    overlay: 'customOverlay', modal: 'prayer_calendar_customModal', closeButton: "close_button"
                  }}
                  showCloseIcon
                >
                  <div className="prayer_calendar_modal_title_wrapper">
                    <div className="prayer_calendar_modal_title h4">{state.edit !== true ? "Add" : "Edit"}</div>
                  </div>
                  <div className="prayer_calendar_modal_line_wrapper"> </div>
                  <div className="prayer_calendar_modal_input_wrapper">
                    <div className="prayer_calendar_modal_input">
                      <Input name="Date" onChange={handleOnChange} type="date" inputKey="date" value={moment(state.date).format("YYYY-MM-DD")} />
                    </div>
                    <div className="prayer_calendar_modal_input">
                    <TextArea name="Description" onChange={handleOnChange} inputKey="description" value={state.description} />
                    </div>
                  </div>
                  <div className="prayer_calendar_button_wrapper">
                    <div className="prayer_calendar_button">
                      <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateCalendarDetails : handleAddCalendar} />
                    </div>
                  </div>
                </Modal>
              </div>
              <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleDeleteCalendar} />
              </>}
        </div>
      </div>
    </div>
  )
}
