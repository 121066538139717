import { GET_STATEMENTS } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface';

const initialState = {};

const announcementReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_STATEMENTS:
      return { ...state, statements: action.payload };
    default:
      return state;
  }
}

export default announcementReducer
