import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import Button from 'common_components/ui/button/button.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import Table from 'components/table/table.component';
import PlusIcon from 'assets/icons/Plus_White.svg';
import EditIcon from 'assets/icons/Edit.svg';
import { GET_RESOURCES, GET_BOOKS } from 'utils/types.utils';
import DeleteIcon from 'assets/icons/Delete.svg';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import 'screens/publication/books.screen.scss';
import Input from 'common_components/ui/input_box/input_box.ui';
import TickIcon from 'assets/icons/Tick_White.svg';
import Inactive from 'assets/icons/Inactive_Pink.svg';
import InactiveIcon from 'assets/icons/Inactive_black.svg';
import {
  getAllPublication,
  addPublication,
  getPublicationById,
  editPublication,
  deletePublication,
  uploadMedia,
} from 'models/publication.model';
import {
  PublicationNavData,
  booksTableHeading,
  booksBody,
  csiUserSuggessionData,
  advertisementSelectBoxData,
} from 'helper/constants.helper';
import { uploadMediaFiles, useSetState } from 'utils/common.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { toast } from 'react-toastify';
import { removeMedia } from 'models/diocese.model';

export default function Books(props) {
  const dispatch = useDispatch();
  const books = useSelector((state: any) => state.publication.books);
  const [state, setState] = useSetState({
    selectedData: 'books',
    edit: false,
    openModal: false,
    filterModal: false,
    checkedList: [],
    checkList: [],
    isChecked: false,
    confirmModal: false,
    activated: 'all',
    title: '',
    description: '',
    thumbnail: '',
    contentImage: [],
    gallery: [],
    image: '',
    file: '',
    searchValue: '',
    page: 1,
    limit: 20,
    fromDate: '',
    toDate: '',
    bookId: '',
    loading: true,
    uploaded: false,
    fileUploading: false,
    contentUploaded: false,
    contentUploading: false,
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const searchChange = () => {};

  const handleGetAllBooks = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        sort: true,
        search,
        activated: state.activated,
        type: 'books',
      };
      const book: any = await getAllPublication(data);
      const doc = book.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      book.data.docs = doc;
      dispatch({
        type: GET_BOOKS,
        payload: book.data,
      });
      setState({ loading: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to load Books');
    }
  };

  const handleAddBooks = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        content_image: state.gallery,
        thumbnail: state.thumbnail,
        type: 'books',
      };
      if (state.thumbnail === '') {
        toast.error('Please upload thumbnail');
      } else {
        const book = await addPublication(data);
        await handleGetAllBooks({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false, edit: false });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to add books');
    }
  };

  const handleEditBooks = async (id) => {
    try {
      setState({ openModal: true, edit: true, bookId: id });
      const book: any = await getPublicationById(id);
      if (book && book.data) {
        const { title, description, thumbnail, pdf } = book.data;
        setState({
          title,
          description,
          thumbnail,
          pdf,
          uploaded: thumbnail ? true : false,
          gallery: book.data.content_image,
        });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to edit Books');
    }
  };

  const handleUpdateBooks = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        content_image: state.gallery,
        thumbnail: state.thumbnail,
      };
      if (state.thumbnail === '') {
        toast.error('Please upload thumbnail');
      } else {
        const book = await editPublication(state.bookId, data);
        await handleGetAllBooks({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false, edit: false });
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Update Books');
    }
  };

  const handleOnDeleteBooks = async () => {
    try {
      const book = await deletePublication(state.bookId);
      await handleGetAllBooks({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false, searchValue: '' });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to delete books');
    }
  };
  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        sort: true,
        limit,
        search,
        activated: state.activated,
        type: 'books',
      };
      const book: any = await getAllPublication(data);
      const doc = book.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      book.data.docs = doc;
      book.data.docs = [...books.docs, ...book.data.docs]
      dispatch({
        type: GET_BOOKS,
        payload: book.data,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get books');
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  useEffect(() => {
    handleGetAllBooks(state);
  }, [state.search]);

  const handleDeleteBook = (id) => {
    setState({ confirmModal: true, bookId: id });
  };

  const handleMakeActiveAndInactive = async (id) => {
    try {
      const book: any = await getPublicationById(id);
      if (book && book.data) {
        const { title, description, thumbnail, pdf } = book.data;
        setState({
          title,
          description,
          thumbnail,
          pdf,
          contentImage: book.data.content_image,
        });
      }
      const data = {
        activated: !book.data.activated,
      };
      await editPublication(id, data);
      await handleGetAllBooks({
        page: 1, limit: state.page * state.limit, search: state.search
      });
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to make active and inactive Books',
      );
    }
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditBooks,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDeleteBook,
      icon: DeleteIcon,
    },
  ];
  const removeVideo = async (url) => {
    try {
      const data = {
        resource_url: url,
      };
      const index = state.gallery.indexOf(url);
      const images = state.gallery.filter((image) => image !== url)
      setState({
        gallery: images
      })
      await removeMedia(data);
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to remove image');
    }
  };
  const handleOnChangeInSearch = async (e) => {
    setState({ page: 1, search: e.target.value })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     page: 1,
    //     search: e.target.value,
    //     type: 'books',
    //   };
    //   const book: any = await getAllPublication(data);
    //   setState({ searchValue: e.target.value });
    //   dispatch({
    //     type: GET_BOOKS,
    //     payload: book.data,
    //   });
    // } catch (err) {
    //   props.throwError(err.data ? err.data.desc : 'Failed to search books');
    // }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleOnUploadContentImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ contentUploading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const contentImage: any = await uploadMedia(data);
          const datas = [...state.gallery, ...[contentImage.url]];
          if (contentImage) {
            setState({
              gallery: datas,
              url: contentImage.url,
              contentUploaded: true,
              contentUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Formats jpg, jpeg, png, gif');
        }
      } else {
        setState({ contentUploading: true });
        const data = new FormData();
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const thumbnail: any = await uploadMedia(data);
        const datas = [...state.gallery, ...[thumbnail.url]];
        if (thumbnail) {
          setState({
            gallery: datas,
            url: thumbnail.url,
            contentUploaded: true,
            contentUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to upload content image',
      );
    }
  };

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Formats jpg, jpeg, png, gif');
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };

  const handleOnChangeInSelection = async (selectedOption) => {
    setState({ activated: selectedOption.value, page: 1 })
    try {
      const data = {
        limit: state.limit,
        page: 1,
        search: state.searchValue,
        type: 'books',
        activated: selectedOption.value,
      };
      const book: any = await getAllPublication(data);
      const doc = book.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      book.data.docs = doc;
      dispatch({
        type: GET_BOOKS,
        payload: book.data,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to load Books');
    }
  };

  return (
    <div className="books_screen">
      <div className="books_container">
        <div className="books_wrapper">
          <div className="books_header_wrapper">
            <div className="books-title_wrapper">
          <div className="officers_name_wrapper h2">
              Publications
          </div>
            </div>
            <div className="books_action_wrapper">
            <div className="books_search_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                suggessionData={csiUserSuggessionData}
                onChange={handleOnChangeInSearch}
              />
            </div>
            <div className="books_filter_select_box_wrapper">
              <SelectBox
                data={advertisementSelectBoxData}
                name=""
                selectedItem={state.searchValue}
                onChange={handleOnChangeInSelection}
              />
            </div>
            <div className="books_action_button_wrapper">
              <Button
                type="primary"
                text="Add Books"
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    title: '',
                    gallery: [],
                    description: '',
                    thumbnail: '',
                    pdf: [],
                    uploaded: false,
                    fileUploading: false,
                  })}
                icon={PlusIcon}
              />
            </div>
            </div>
          </div>
          <div className="books_custom_navbar_wrapper">
            <CustomNavBar
              data={PublicationNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddBooks}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="books_table-wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={booksTableHeading}
                body={books ? books.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMoreInitial}
                actionButton={actionArray}
                hasMore={books && books.hasNextPage ? books.hasNextPage : false}
                active
                onClick={handleMakeActiveAndInactive}
                error="No books are found"
              />
            )}
          </div>
          <div className="books_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'books_customModal',
                closeButton: 'close_button',
              }}
              showCloseIcon
            >
              <div className="books_modal_header_wrapper">
                <div className="books_modal_title_wrapper">
                  <div className="books_modal_title h4">
                    {state.edit !== true ? 'Add Books' : 'Edit Books'}
                  </div>
                  <div className="books_modal_icon_wrapper">
                    <img
                      className="books_modal_icon"
                      src={InactiveIcon}
                      alt="close_icon"
                      onClick={() => setState({ openModal: false })}
                    />
                  </div>
                </div>
                <div className="books_modal_line_wrapper"> </div>
              </div>
              <div className="books_modal_input_wrapper">
                <div className="books_modal_input">
                  <Input
                    name="Title"
                    onChange={handleOnChange}
                    inputKey="title"
                    value={state.title}
                  />
                </div>
                <div className="books_modal_input">
                  <TextArea
                    name="Description"
                    onChange={handleOnChange}
                    inputKey="description"
                    value={state.description}
                  />
                </div>
                <div className="books_modal_input">
                  <div className="upload_box_wrapper">
                    Thumbnail
                    <div className="upload_box">
                      <UploadBox
                        name="thumbnail"
                        className="uploadThumbnail"
                        accept="image/x-png,image/gif,image/jpeg"
                        uploaded={state.uploaded}
                        loading={state.fileUploading}
                        text="Drag and Drop or"
                        onChange={handleUploadImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="books_modal_input">
                  <div className="upload_box_wrapper">
                    Content Images
                    <div className="upload_box">
                      <UploadBox
                        name="uploadpdf"
                        className="UploadContent"
                        accept="image/x-png,image/jpeg"
                        text="Drag and Drop or"
                        uploaded={state.contentUploaded}
                        loading={state.contentUploading}
                        onChange={handleOnUploadContentImage}
                      />
                    </div>
                  </div>
                </div>
                {state.gallery.length > 0
                && state.gallery.map((image) => {
                    return (
                      <div className="video_file_upload_wrapper">
                        <div className="file_upload_file_name">{image}</div>
                        <div className="file_upload_inactive_icon-wrapper">
                          <img
                            className="file_upload_delete_icon"
                            src={Inactive}
                            alt="delete_icon"
                            onClick={() => removeVideo(image)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="books_modal_button_wrapper">
                <div className="books_modal_button">
                  <Button
                    text={state.edit ? 'Update Book' : 'Add Book'}
                    type="primary"
                    onClick={state.edit ? handleUpdateBooks : handleAddBooks}
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete this resource?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleOnDeleteBooks}
          />
        </div>
      </div>
    </div>
  );
}
