import { instance } from '../helper/axios.helper';

export const uploadMedia = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/upload_media`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
export const uploadMultiple = async (body) => {
  const promise = new Promise((resolve, reject) => {
    instance().post(`videos/multiple_file_upload`, body)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err)
        }
      })
  })
  return promise;
}
