import React, { useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import Spinner from "common_components/ui/spinner/spinner.ui";
import { GET_SUBSCRIBER, GET_DIOCESE_NEWS, GET_USERS, THROW_ERROR } from "utils/types.utils";
import "screens/newsletter/newsletter_diocese.screen.scss";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import moment from "moment";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import Button from "common_components/ui/button/button.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import Table from "components/table/table.component";
import { useSetState } from "utils/common.utils";
import PlusIcon from "assets/icons/Plus_White.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import { getAllSubscribers, addSubscriber, getSubscriberById, editSubscriber, deleteSubscriber } from "models/news_letter.model";
import { getAllDiocese } from "models/diocese.model";
import { NewsLetterNavData, NewsNavData, subscriberTableHeading, subscriberBody, csiUserSuggessionData, subscriberBoxData } from "helper/constants.helper";
import "screens/newsletter/subscriber.screen.scss";
import { useDispatch, useSelector } from "react-redux";

export default function Subscriber(props) {
  const subscribers = useSelector((state: any) => state.newsLetter.subscriber);
  const dioceses = useSelector((state: any) => state.diocese.news);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selectedData: "subscriber",
    selectedNavData: "diocese",
    edit: false,
    openModal: false,
    filterModal: false,
    newsModal: false,
    checkedList: [],
    checkList: [],
    dioceseList: [],
    isChecked: false,
    confirmModal: false,
    title: "",
    searchValue: "",
    fromDate: "",
    toDate: "",
    image: "",
    name: "",
    diocese: "",
    phone: "",
    email: "",
    subscriberId: "",
    limit: 20,
    search: "",
    page: 1,
    loading: true
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const loadMore = async () => {
    try {
      const data = { limit: state.limit, page: state.page + 1, search: state.search };
      const subscriber: any = await getAllSubscribers(data);
      if (subscriber !== undefined
        && subscriber.data !== undefined) {
        const doc = subscriber.data.docs.map((docs) => {
          docs.created_at = moment(docs.created_at).format('DD-MM-YYYY')
          return docs;
        })
        subscriber.data.docs = doc;
        subscriber.data.docs = [...subscribers.docs, ...subscriber.data.docs]
        dispatch({
          type: GET_SUBSCRIBER,
          payload: subscriber.data
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get Subscriber");
    }
  }

  const searchChange = () => { }

  const handleGetAllSubscriber = async ({ limit, page, search }) => {
    try {
      const data = { limit, page, search };
      const subscriber: any = await getAllSubscribers(data);
      if (subscriber !== undefined
        && subscriber.data !== undefined) {
        const doc = subscriber.data.docs.map((docs) => {
          docs.created_at = moment(docs.created_at).format('DD-MM-YYYY')
          return docs;
        })
        subscriber.data.docs = doc;
        dispatch({
          type: GET_SUBSCRIBER,
          payload: subscriber.data
        })
        setState({ loading: false })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get Subscriber");
    }
  }

  const handleGetAllDiocese = async () => {
    try {
      const diocese: any = await getAllDiocese(
        {
          limit: state.limit,
          page: state.page,
          search: state.search
        });
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: diocese.data
      })
      // }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get Subscriber");
    }
  }

  interface DepartmentArray {
    label: string,
    value: string,
  }
  const dioceseData: DepartmentArray[] = [];
  const dept = dioceses && dioceses.docs.length !== 0
    && dioceses.docs.map((diocese) => {
      dioceseData.push({ label: diocese.name, value: diocese._id });
      return "";
    });

  useEffect(() => {
    handleGetAllSubscriber(state);
    handleGetAllDiocese();
  }, [state.search]);

  const handleAddsubscriber = async () => {
    try {
      const data = {
        name: state.name,
        email: state.email,
        phone: state.phone,
      }
      const subscriber: any = await addSubscriber(data);
      await handleGetAllSubscriber({ limit: state.limit, search: state.search, page: state.page });
      setState({ openModal: false, edit: false });
    } catch (err) {
      props.throwError(err ? err.data.message : err.data.desc);
    }
  }

  const handleEditSubscriber = async (id) => {
    try {
      setState({ openModal: true, edit: true, subscriberId: id });
      const subscriber: any = await getSubscriberById(id);
      if (subscriber) {
        setState({
          name: subscriber.data.name,
          email: subscriber.data.email,
          phone: subscriber.data.phone,
          diocese: subscriber.data.diocese,
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to edit department personnels");
    }
  }

  const handleUpdatesubscriber = async () => {
    try {
      const data = {
        name: state.name,
        phone: state.phone,
        email: state.email,
        diocese: state.diocese
      }
      const personnels: any = await editSubscriber(state.subscriberId, data);
      await handleGetAllSubscriber({ limit: state.limit, search: state.search, page: state.page });
      setState({ openModal: false })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to update personnels");
    }
  }

  const handleDeleteSubscriber = (id) => {
    setState({ confirmModal: true, subscriberId: id })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditSubscriber,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteSubscriber,
      icon: DeleteIcon
    }
  ];
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore();
  };
  const handleOnChangeInSearch = (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ diocese: selectedOption.value });
  }

  const handleOnDeleteSubscriber = async () => {
    try {
      const subscriber = await deleteSubscriber(state.subscriberId);
      await handleGetAllSubscriber({ limit: state.limit, search: state.search, page: state.page });
      setState({ confirmModal: false })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to delete subscriber");
    }
  }
  return (
    <div className="subscriber_screen">
      <div className="subscriber_container">
        <div className="subscriber_wrapper">
          <div className="subscriber_header_wrapper">
          <div className="subscriber_title_wrapper">
          <div className="officers_name_wrapper h2">
             Subscribers
          </div>
          </div>
          <div className="subscriber_action_wrapper">
            <div className="subscriber_search_box_wrapper">
              <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
            </div>
            <div className="subscriber_action_button_wrapper">
              <Button type="primary" text="Add Subscriber" onClick={() => setState({ openModal: true, edit: false, name: '', phone: '', email: '' })} icon="" />
            </div>
          </div>
          </div>
          <div className="subscriber_navbar_wrapper">
            <CustomNavBar
              data={NewsLetterNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddsubscriber}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
            <div className="subscriber_table-wrapper">
              {
                state.loading ? <Spinner />
                  : <Table
                      header={subscriberTableHeading}
                      body={subscribers ? subscribers.docs : []}
                      isChecked={state.isChecked}
                      checkAll={checkAll}
                      checkOne={checkOne}
                      checkedData={state.checkedList}
                      loadMore={loadMoreInitial}
                      hasMore={subscribers.hasNextPage}
                      actionButton={actionArray}
                      error="Subscribers is not found"
                  />
              }
            </div>
            <div className="subscriber_modal_container">
              <Modal
                open={state.openModal}
                onClose={() => setState({ openModal: false })}
                center
                classNames={{
                  overlay: 'customOverlay', modal: 'subscriber_customModal', closeButton: "close_button"
                }}
                showCloseIcon
              // closeIcon={inactiveIcon}
              >
                <div className="subscriber_modal_title_wrapper">
                  <div className="subscriber_modal_title h4">{state.edit !== true ? "Add Subscriber" : "Edit Subscriber"}</div>
                </div>
                <div className="subscriber_modal_line_wrapper"> </div>
                <div className="subscriber_modal_input_wrapper">
                  <div className="subscriber_modal_input">
                    <Input name="Name" onChange={handleOnChange} inputKey="name" value={state.name} />
                  </div>
                  <div className="subscriber_modal_input">
                    <Input name="Phone Number" type="number" onChange={handleOnChange} inputKey="phone" value={state.phone} />
                  </div>
                  <div className="subscriber_modal_input">
                    <Input name="Email" onChange={handleOnChange} inputKey="email" value={state.email} />
                  </div>
                </div>
                <div className="subscriber_modal_button_wrapper">
                  <div className="subscriber_modal_button">
                    <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdatesubscriber : handleAddsubscriber} />
                  </div>
                </div>
              </Modal>
            </div>
            <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteSubscriber} />
          </div>
        </div>
      </div>
    </div>
  )
}
