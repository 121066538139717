import React from 'react';
import './video.component.scss';
import 'video.js/dist/video-js.css';

interface videoProps {
  url: string,
  thumbnail?: string
}

const Video = (props: videoProps) => {
  const { url, thumbnail } = props;
  return (
    <div className="video_container">
      <video className="video_media" poster="" controls>
        <source src={url} type="video/mp4" />
        <track kind="captions" {...props} />
      </video>
    </div>
  )
}

export default Video;

Video.defaultProps = {
  thumbnail: ""
}
