import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewsCard1 from 'components/news_card_1/news_card_1.component';
import NewsCard2 from 'components/news_card_2/news_card_2.component';
import { Link, NavLink } from 'react-router-dom';
import './department_screen.scss';
import '../../index.scss';
import EventCard from 'components/events_card/events_card.component';
import NewsCard3 from 'components/news_card_3/news_card_3.component';
import Button from 'components/button/button.component';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Navbar from 'components/navbar/navbar.component';
import { throwError, useSetState } from 'utils/common.utils';
import moment from 'moment';
import { getAllNews, getAllEvents, getDepartmentById, getAllDepartmentsBySubDomain } from "models/public.model";
import { GET_DEPARTMENT, GET_DEPARTMENT_EVENTS, GET_DEPARTMENT_NEWS, THROW_ERROR } from 'utils/types.utils';
import Spinner from 'common_components/ui/spinner/spinner.ui';

export default function DepartmentScreen(props) {
  const { match } = props;
  const departmentNews = useSelector((state: any) => state.department.news);
  const departmentData = useSelector((state: any) => state.department);
  const events = useSelector((state: any) => state.department.events);
  const parsedData = window.location.host.split('.');
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selectedData: 'department_news',
    limit: 20,
    page: 1,
    search: '',
    news: [],
    id: '',
    loading: true,
    activated: 'all',
    gallery: [],
    departmentName: '',
  });
  const handleApplyFilter = async (departmentId) => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        type: 'departments',
        activated: true,
        department: departmentId ? [departmentId] : '',
        // department: ['604b319fdd556a147f2abc56']
      };
      const news: any = await getAllNews(data);
      const doc = news.data.docs.map((docs) => {
        docs.department_name = docs.departments.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.date).format('DD MMMM YYYY');
        docs.created_at = moment().format('dddd, MMM D, YYYY');
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      news.data.docs = doc;
      dispatch({
        type: GET_DEPARTMENT_NEWS,
        payload: news.data,
      });
      setState({ openModal: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Department details")
    }
  };
  // const handleGetDepartment = async (departmentId) => {
  //   try {
  //     const res: any = await getDepartmentById(departmentId);
  //     setState({ departmentName: res.data.name, loading: false });
  //   } catch (err) {
  //     throwError(err.data ? err.data.desc : "Failed to get Department details")
  //   }
  // };
  const handleGetAllDepartmentEvents = async (departmentId) => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        type: 'departments',
        department: departmentId ? [departmentId] : '',
        // department: ['604b319fdd556a147f2abc56']
      };
      const event: any = await getAllEvents(data);
      const doc = event.data.docs.map((docs) => {
        docs.department_name = docs.departments.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.from_date).format('DD');
        docs.from_day = moment(docs.from_date).format('MMM');
        return docs;
      });
      event.data.docs = doc;
      dispatch({
        type: GET_DEPARTMENT_EVENTS,
        payload: event.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Department events")
    }
  };
  const handleGetAllDepartmentBySubdomain = async () => {
    try {
        const data = {
            limit: 35,
            page: state.page,
            search: state.search,
            sub_domain: parsedData ? parsedData[0] : ""
            // sub_domain: 'christianeducation'
        };

        const res: any = await getAllDepartmentsBySubDomain(data);
        if (res && res.data._id !== undefined) {
          handleApplyFilter(res.data._id);
          handleGetAllDepartmentEvents(res.data._id);
        }
        dispatch({
            type: GET_DEPARTMENT,
            payload: res.data,
        });
        setState({ loading: false, id: res.data._id, departmentName: res.data.name });
    } catch (err) {
        dispatch({
            type: THROW_ERROR,
            payload: err,
        });
    }
};
  useEffect(() => {
    // handleApplyFilter();
    handleGetAllDepartmentBySubdomain()
    // handleGetAllDepartmentEvents();
  }, []);
  return (
    <div className="department_screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper" style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
        <Navbar {...props} />
        </div>
      </div>
      <div className="department_screen_container">
        <div className="department_screen_header_wrapper">
          <div className="department_screen_header h2">
            {state.departmentName}
          </div>
          <div className="header_line" />
        </div>
        <div className="department_screen_title_wrapper">
          <div className="department_screen_title h1">
            <div className="title_links_wrapper">
              <div className="link_1 h3">
                <NavLink
                  // to={`/department/${match.params.id}`}
                  to="/"
                  className="title_link"
                  activeClassName="selected"
                >
                  News & Events
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink to={`/aim/${state.id}`} className="title_link">
                  Aim, Objectives & Personnel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="department_body_wrapper">
          <div className="department_left">
            <div className="left_title_wrapper">
              <div className="h3 left_heading">News</div>
              <div className="header_line_left" />
            </div>
            <div className="content_left">
              <div className="left_card1_wrapper">
                {state.loading ? <Spinner /> : departmentNews && departmentNews.docs.length > 0 ? (
                  <NewsCard1
                    link={
                      departmentNews.docs !== undefined
                        ? departmentNews.docs[0].thumbnail
                        : ''
                    }
                    title={
                      departmentNews.docs !== undefined
                        ? departmentNews.docs[0].heading
                        : ''
                    }
                    type="news_card"
                    onClick={() => {
                      props.history.push(
                        `/news_screen/departments/${
                          departmentNews.docs !== undefined
                            ? departmentNews.docs[0]._id
                            : ''
                        }`,
                      );
                    }}
                    description={
                      departmentNews.docs !== undefined
                        ? departmentNews.docs[0].date
                        : ''
                    }
                    name={departmentNews.docs !== undefined
                      ? departmentNews.docs[0].heading
                      : ''}
                  />
                ) : (
                  "No Latest News"
                )}
              </div>
              <div className="events_card_wrapper_left">
                {state.loading ? <Spinner /> : departmentNews
                && departmentNews.docs.slice(1, 5).map((item) => {
                    return (
                      <div className="card_left">
                        <NewsCard3
                          link={item.thumbnail ? item.thumbnail : ''}
                          title={item.title}
                          type="news_card_1"
                          className="image_size"
                          onClick={() => {
                            props.history.push(`/news_screen/departments/${item._id}`);
                          }}
                          description={item.heading}
                          text={`${item.department_name} | ${item.date}`}
                        />
                      </div>
                    );
                  })}
              </div>
              {departmentNews && departmentNews.docs.length > 6 && (
                <div className="button_wrapper">
                  <Button
                    text="Load More"
                    className="h6 color"
                    onClick={() => {
                      props.history.push(`/news_list_screen/departments/${state.id}`);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="department_right">
            <div className="right_content_wrapper">
              <div className="right_title_wrapper">
                <div className="h3 left_heading">Events</div>
                <div className="header_line_right" />
              </div>
              <div className="card_wrapper_right">
                {state.loading ? <Spinner /> : events
                && events.docs.length > 0 ? events.docs.slice(0, 3).map((event) => {
                  return (
                    <div className="card_right">
                        <EventCard
                          image={event.thumbnail}
                          day={event.date}
                          month={event.from_day}
                          title={event.department_name}
                          description={event.name}
                        />
                    </div>
                    );
                  }) : "No current events found"}
              </div>
            </div>
            <div className="button_wrapper">
              {events && events.docs.length > 3 ? (
                <Button
                  text="Load More"
                  className="h6 color"
                  onClick={() => {
                    props.history.push(`/home/event/departments/${match.params.id}`);
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="department_screen_footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
