import React from 'react';
import { Modal } from 'react-responsive-modal';
import { useSetState, getMonth, getType } from 'utils/common.utils';
import { toast } from 'react-toastify';
import { getAllNews, getAllEvents, getAllVideo, getAllPublication, getCampaignDetails, getAllAdvertismentWithTypes, addSubscriber } from "models/public.model";
import Button from "components/button/button.component";
import FooterBg from '../../assets/images/Footer.png';
import FB from '../../assets/icons/Group 1.svg';
import Instagram from '../../assets/icons/instagram 2.svg';
import Youtube from '../../assets/icons/Group 2.svg';
import Whatsapp from '../../assets/icons/Group 4.svg';
import './footer.component.scss';

interface FooterProps {
  onClick?: any
}

const Footer = (props: FooterProps) => {
  const { onClick } = props;
  const [state, setState] = useSetState({ selected_filter: "", limit: 20, page: 1, search: "", activated: "all", openModal: false, subscribeModal: false, name: "", email: "", mobile: "", url: "" });
  const handleInputChange = (e) => {
    setState({ [e.target.name]: e.target.value });
  }

  const handleAddsubscriber = async () => {
    if (state.email === "" || state.mobile === '' || state.name === '') {
      toast.error("Please enter all the details")
    } else {
    try {
      const data = {
        name: state.name,
        email: state.email,
        phone: state.mobile,
      }
      if (data.name !== "" && data.email !== "" && data.phone !== "") {
        const subscriber: any = await addSubscriber(data);
        setState({ subscribeModal: false, edit: false });
      }
      toast.error("Subscribed")
    } catch (err) {
      toast.error(err.data ? err.data.message || err.data.desc : "Failed to subscribe")
    }
  }
  }

  return (
    <div className="footer_component">
      <div className="footer_container" style={{ backgroundImage: `url(${FooterBg})` }}>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="footer_head h4">Get in Touch</div>
            <div className="footer_about">
              <div className="footer_name">Church of South india - SYNOD</div>
              <div className="footer_address"># 5, Whites Road Royapettah Chennai - 600 014.</div>
              <div className="footer_name"><a href="#/">csisynod@gmail.com</a></div>
              <div className="footer_phone_content">
                <div className="footer_phone">+91 44 2852 1566</div>
                <div className="footer_phone">+91 44 2852 4166</div>
              </div>
              <div className="footer_social_media">
                <a href="https://facebook.com/groups/195181353850157/"><img src={FB} className="icon" alt="fb_icon" /></a>
                <a href="https://www.instagram.com/csi_synod_communications/?igshid=17kzz9dce9iz8&fbclid=IwAR0XTfYUpXG6v0-8XCp_uZH07H1DLxp1-G1Cjwnu6Z5uSOHp1M5YwVXE-sQ"><img src={Instagram} className="icon" alt="twitter_icon" /></a>
                <a href="https://www.youtube.com/channel/UCjk01xPVy2mw1NP8w2JIRSQ"><img src={Youtube} className="icon" alt="youtube_icon" /></a>
                <a href="https://wa.me/+919840577404"><img src={Whatsapp} alt="icon" className="icon" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="footer_head h4">CSI</div>
            <div className="footer_about">
              <div className="footer_name cursor"><a href="/news_list_screen/dioceses">Diocese News</a></div>
              <div className="footer_name cursor"><a href="/news_list_screen/departments">Synod News</a></div>
              <div className="footer_name cursor"><a href="/about/partners_screen">Partners</a></div>
              <div className="footer_name cursor"><a href="/about/networks_screen">Network</a></div>
              <div className="footer_name cursor"><a href="/about/history">About Us</a></div>
              <div className="footer_name cursor"><a href="/about/contact_us">Contact Us</a></div>
            </div>
          </div>
        </div>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="footer_head h4">Latest</div>
            <div className="footer_about">
              <div className="footer_name cursor"><a href="/home/event/departments">Synod Events</a></div>
              <div className="footer_name cursor"><a href="/resources/csi_life_screen">CSI Life</a></div>
              <div className="footer_name cursor"><a href="/resources/publications_screen">Publications</a></div>
              <div className="footer_name cursor"><a href="/about/campaign_list_screen">Campaigns</a></div>
              <div className="footer_name cursor"><a href="/resources/worship_resource/prayer_calendar">Worship Resources</a></div>
            </div>
          </div>
        </div>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="footer_head h4">Quick Links</div>
            <div className="footer_about">
              <div className="footer_name cursor"><a href="/resources/resource_docs">Resource Documents</a></div>
              <div className="footer_name cursor"><a href="/others/library_archive">Library & Archive</a></div>
              <div className="footer_name cursor"><a href="/others/government_notification_schemes">Government Notification Schemes</a></div>
              <div className="footer_name cursor"><a href="/others/press_release">Press Release</a></div>
              <div className="footer_name cursor"><a href="/others/statement">Statements</a></div>
            </div>
          </div>
        </div>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="footer_head h4">CSI-SYNOD Newsletter</div>
            <div className="footer_about">
              <div className="footer_subscribe_text">Subscribe to receive the latest information from <span className="guest">csi synod</span> in your inbox !</div>
              <div className="footer_input_content" onClick={() => setState({ subscribeModal: true })}>
                <div className="footer_btn">Subscribe</div>
              </div>
              <div className="footer_desc p6">You are agreeing to our terms of service and privacy policy. You can unsubscribe at any time.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy_right_container">
        <div className="copy_right">Copyrights © 2020. All Rights Reserved. | CSI SYNOD | <a href="https://brownbutton.in/" className="footer_link">Designed by  Brown Button</a></div>
      </div>
          <div className="footer_modal_subscribe_container">
            <Modal
              open={state.subscribeModal}
              onClose={() => setState({ subscribeModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'footer_subscribeModal',
                closeButton: 'footer_subscribe_close_button',
              }}
              showCloseIcon
            >
              <div className="footer_subscription_modal_container">
                <div className="footer_subscription_title_wrapper">
                  <div className="footer_subscription_title h2">CSI SYNOD NewsLetter</div>
                  <div className="footer_subscription_line_wrapper"> </div>
                </div>
                <div className="footer_subscription_description_wrapper">
                  <div className="footer_subscription_description h4">
                    Subscribe to receive the latest information from CSI SYNOD in your Inbox !
                  </div>
                </div>
                <div className="footer_subscription_input_wrapper">
                  <input type="text" id="name" name="name" placeholder=" *  Enter Your Name" value={state.name} className="footer_subscription_input" onChange={handleInputChange} />
                  <input type="text" id="email" name="email" placeholder=" *  Enter Your Email ID" value={state.email} className="footer_subscription_input" onChange={handleInputChange} />
                  <input type="number" id="mobile" name="mobile" placeholder=" *  Enter Your Mobile Number" value={state.mobile} className="footer_subscription_input" onChange={handleInputChange} />
                </div>
                <div className="footer_subscription_button_wrapper">
                  <Button text="Subscribe" className="footer_subscription_button" onClick={handleAddsubscriber} />
                </div>
              </div>
            </Modal>
          </div>
    </div>
  )
}

export default Footer;

Footer.defaultProps = {
  onClick: ""
}
