import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import './popup.component.scss';
import { NavLink } from 'react-router-dom';
import TamilNadu from 'assets/icons/Dicoese_Maps/Tamil_Nadu.svg';
import TamilNaduHover from 'assets/icons/Dicoese_Maps/Tamil_Nadu_Hover.svg';
import Kerala from 'assets/icons/Dicoese_Maps/Kerala_Normal.svg';
import KeralaHover from 'assets/icons/Dicoese_Maps/Kerala_Hover.svg';
import Karnataka from 'assets/icons/Dicoese_Maps/Karnataka_Normal.svg';
import KarnatakaHover from 'assets/icons/Dicoese_Maps/Karnataka_Hover.svg';
import Telengana from 'assets/icons/Dicoese_Maps/Telengana_Normal.svg';
import TelenganaHover from 'assets/icons/Dicoese_Maps/Telengana_Hover.svg';
import Andhra from 'assets/icons/Dicoese_Maps/Andhra_Normal.svg';
import AndhraHover from 'assets/icons/Dicoese_Maps/Andhra_Hover.svg';
import _ from "lodash";
import { throwError, useSetState } from 'utils/common.utils';
import { getAllDepartments } from "models/public.model";
import { GET_ALL_DEPARTMENTS, GET_ALL_DIOCESES } from "utils/types.utils";
import { getAllDioceses } from "models/diocese.model";
import { homeTaps } from '../../helper/constants.helper';

interface PopupProps {
  type: string,
  popHover: any
}

export default function Popup(props: PopupProps) {
  const { type, popHover } = props;
  const dispatch = useDispatch()
  const departments = useSelector((state: any) => state.department.allDepartments)
  const dioceses = useSelector((state: any) => state.diocese.allDioceses)
  const tabBar = _.filter(homeTaps, { key: type })[0];
  const [state, setState] = useSetState({ hover: "" });
  const onHover = (key) => {
    setState({ hover: key });
  }
  const onLeave = () => {
    setState({ hover: "" });
  }
  const setBottomBorder = () => {
    popHover(type);
  }
  const removeBorder = () => {
    popHover("");
  }
  const getDepartments = async () => {
    try {
      const res: any = await getAllDepartments()
      dispatch({
        type: GET_ALL_DEPARTMENTS,
        payload: res.data
      })
    } catch (err) {
      throwError('Failed to get departments')
    }
  }
  useEffect(() => {
   getDepartments()
  }, [])
  return (
    <div className="popup_component" onMouseEnter={setBottomBorder} onMouseLeave={removeBorder}>
      <div className="popup_container">
        {type === "diocese"
        && <div className="popup_home">
            <div className="popup_wrapper" onMouseEnter={() => onHover('andhra')} onMouseLeave={(e) => onLeave()}>
              <div className="map_container">
                {state.hover === "andhra"
                ? <img src={AndhraHover} alt="map_svg" className="map_icon" />
                : <img src={Andhra} alt="map_svg" className="map_icon" />}
              </div>
              <div className="head_text">
                <div className="head_state h6">Andhra</div>
              </div>
              <div className="list_details">
                {_.filter(dioceses, { state: "Andhra" }).map((diocese) => {
                 return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav">{diocese.name}</div>
                })}
              </div>
            </div>
            <div className="popup_wrapper" onMouseEnter={() => onHover('karnataka')} onMouseLeave={(e) => onLeave()}>
              <div className="map_container">
                {state.hover === "karnataka"
                ? <img src={KarnatakaHover} alt="map_svg" className="map_icon" />
                : <img src={Karnataka} alt="map_svg" className="map_icon" />}
              </div>
              <div className="head_text">
                <div className="head_state h6">Karnataka</div>
              </div>
              <div className="list_details">
                {_.filter(dioceses, { state: "Karnataka" }).map((diocese) => {
                  return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav">{diocese.name}</div>
                })}
              </div>
            </div>
            <div className="popup_wrapper" onMouseEnter={() => onHover('kerala')} onMouseLeave={(e) => onLeave()}>
              <div className="map_container">
                {state.hover === "kerala"
                ? <img src={KeralaHover} alt="map_svg" className="map_icon" />
                : <img src={Kerala} alt="map_svg" className="map_icon" />}
              </div>
              <div className="head_text">
                <div className="head_state h6">Kerala</div>
              </div>
              <div className="list_details">
              {_.filter(dioceses, { state: "Kerala" }).map((diocese) => {
                 return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav">{diocese.name}</div>
                })}
              </div>
            </div>
            <div className="popup_wrapper" onMouseEnter={() => onHover('tamilnadu')} onMouseLeave={(e) => onLeave()}>
              <div className="map_container">
              {state.hover === "tamilnadu"
              ? <img src={TamilNaduHover} alt="map_svg" className="map_icon" />
              : <img src={TamilNadu} alt="map_svg" className="map_icon" />}
              </div>
              <div className="head_text">
                <div className="head_state h6">Tamil Nadu</div>
              </div>
              <div className="list_details">
              {_.filter(dioceses, { state: "Tamil Nadu" }).map((diocese) => {
                 return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav">{diocese.name}</div>
                })}
              </div>
            </div>
            <div className="popup_wrapper last_diocese" onMouseEnter={() => onHover('telengana')} onMouseLeave={(e) => onLeave()}>
              <div>
              <div className="map_container">
                {state.hover === "telengana"
                ? <img src={TelenganaHover} alt="map_svg" className="map_icon" />
                : <img src={Telengana} alt="map_svg" className="map_icon" />}
              </div>
              <div className="head_text">
                <div className="head_state h6">Telengana</div>
              </div>
              <div className="list_details">
              {_.filter(dioceses, { state: "Telengana" }).map((diocese) => {
                return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav">{diocese.name}</div>
              })}
              </div>
              </div>
              <div className="head_text" style={{ marginTop: '3em', cursor: 'pointer' }}>
                <div className="head_state h6">
                {_.filter(dioceses, { state: "Jaffna" }).map((diocese) => {
                  return <div onClick={() => window.open(`//${diocese.sub_domain}.csi1947.com`)} className="pop_up_nav diocese_text">{diocese.name}</div>
                })}
                </div>
              </div>
            </div>
           </div>}
          {type === "department"
          && <div className="department_nav_wrapper">
            <div className="tab_container_flex">
              {departments && departments.map((tab, index) =>
                <div className="list_details">
                <div onClick={() => window.open(`//${tab.sub_domain}.csi1947.com`)} className="pop_up_nav">{tab.name}</div>
                </div>
              )}
            </div>
             </div>}
          {type !== "diocese" && type !== "department"
          && <div className="home_wrapper">
              <div className={`${(type === "resources" || type === "others") ? "tab_container_flex" : "tab_container"}`}>
                {tabBar && tabBar.tabs.length
                && tabBar.tabs.map((tabs, index) =>
                  <div className="tab_lists">
                    {tabs.head
                    && <div className="head_wrapper h6">{tabs.head}</div>}
                    {tabs.contact_list && <a href="https://csi1947.com/contact_us" className="head_wrapper h6">{tabs.contact_list[0].name}</a>}
                    {tabs.head
                    ? tabs.tap_list && tabs.tap_list.map((tabLists, i) =>
                      <div className="topic_wrapper">
                        <a href={tabLists.url} className="pop_up_nav">{tabLists.name}</a>
                      </div>)
                    : <div className="topic_wrapper_flex_container">
                        {tabs.tap_list && tabs.tap_list.map((tabLists, i) =>
                        <div className="topic_wrapper_content">
                          <a href={tabLists.url} className="pop_up_nav">{tabLists.name}</a>
                        </div>)}
                      </div>}
                  </div>)}
              </div>
             </div>}
      </div>
    </div>
  )
}
