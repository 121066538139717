import { GET_RESOURCES, GET_CSI_LIFE, GET_ARCHIVES, GET_BOOKS, GET_ONE_BOOK } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  loading: true,
  error: false
};

const publicationReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_RESOURCES:
      return { ...state, loading: false, error: false, resources: action.payload };
    case GET_CSI_LIFE:
      return { ...state, loading: false, error: false, csi_life: action.payload };
    case GET_BOOKS:
      return { ...state, loading: false, error: false, books: action.payload };
    case GET_ONE_BOOK:
      return { ...state, loading: false, error: false, book: action.payload };
    case GET_ARCHIVES:
      return { ...state, loading: false, error: false, archives: action.payload };
    default:
      return state;
  }
}

export default publicationReducer;
