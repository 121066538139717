import React from 'react';
import './action_button.ui.scss';

interface Props {
  text: string,
  color?: string,
  icon: string,
  textColor?: string,
  className?: string,
  type: string,
  onClick: any
}

const ActionButton = (props: Props) => {
  const { text, icon, color, className, type, textColor, onClick } = props;
  return (
    <div className={`action_button_container ${className}`} style={{ backgroundColor: color }}>
      {
        type === "secondary"
          ? <div className="action_button_wrapper" onClick={onClick}>
              <div className="action_button_icon_container">
                <img className="action_icon" src={icon} alt="action_icon" />
              </div>
              <div className="action_button_name p5">{text}</div>
            </div>
          : type === "primary"
            ? <div className="action_button_wrapper secoundary" onClick={onClick}>
                <div className="action_button_icon_container secoundary_color_icon">
                  <img className="action_icon_secoundary" src={icon} alt="action_icon" />
                </div>
                <div className="action_button_name_secoundary p5">{text}</div>
              </div>
            : type === "custom"
            && <div className="action_button_wrapper" style={{ backgroundColor: color }} onClick={onClick}>
                  <div className="action_button_icon_container" style={{ backgroundColor: textColor }}>
                    <img className="action_icon" src={icon} alt="action_icon" />
                  </div>
                  <div className="action_button_name p5" style={{ color: textColor }}>{text}</div>
               </div>
      }
    </div>
  )
}

ActionButton.defaultProps = {
  textColor: '',
  className: "",
  color: ''
}

export default ActionButton;
