import { storeAction } from 'interfaces/common.interface';
import { GET_CAMPAIGNS } from '../../utils/types.utils'

const initialState = {}

const campaignReducer = (state = initialState, action: storeAction) => {
    switch (action.type) {
        case GET_CAMPAIGNS:
            return { ...state, ...action.payload }
        default:
          return state;
      }
}

export default campaignReducer
