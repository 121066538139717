import React from "react";
import PdfFile from "common_components/ui/pdf_file/pdf_file.component";
import "components/statement_card/statement_card.component.scss";

interface StatementCardProps {
    title: string,
    date?: string,
    heading?: string,
    description: string,
    pdfName: string
}

export default function StatementCard(props: StatementCardProps) {
    const { heading, description, date, title, pdfName } = props;
    return (
        <div className="statement_card_container">
            <div className="statement_card_wrapper">
                <div className="statement_card_title_wrapper">
                    <div className="statement_card_title h4">{title}  {date && `- ${date}`}</div>
                    <div className="statement_card_line_wrapper"> </div>
                </div>
                <div className="statement_card_content_wrapper">
                    {heading && <div className="statement_card_heading h5">{heading}</div>}
                    {description && <div className="statement_card_description">{description}</div>}
                    <div className="statement_card_pdf_file_wrapper">
                        <PdfFile title={pdfName} onClick={() => { window.open(pdfName) }} className="statement_card_pdf_file" />
                    </div>
                </div>
            </div>
        </div>
    )
}

StatementCard.defaultProps = {
    date: '',
    heading: ''
}
