import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import 'screens/partnersandnetworks/partnersandnetworks.screen.scss';
import {
  userNavData,
  partnersTableHeading,
  partnerBody,
  csiUserSuggessionData,
  selectBoxData,
} from 'helper/constants.helper';
import _ from 'lodash';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import Button from 'common_components/ui/button/button.ui';
import EditIcon from 'assets/icons/Edit.svg';
import DeleteIcon from 'assets/icons/Delete.svg';
import Table from 'components/table/table.component';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import PlusIcon from 'assets/icons/Plus_White.svg';
import {
  addPartnerAndNetwork,
  deletePartnerAndNetwork,
  editPartnerAndNetwork,
  getAllPartnerAndNetworkDetails,
  getPartnerAndNetworkById,
} from 'models/partner_network.model';
import { GET_PARTNER_AND_NETWORK_DETAILS } from 'utils/types.utils';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import { uploadMedia } from 'models/news.model';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import { uploadMediaFiles, useSetState } from '../../utils/common.utils';

export default function PartnerAndNetwork(props) {
  const dispatch = useDispatch();
  const details = useSelector(
    (state: any) => state.partner_and_network.partner_network,
  );
  const [state, setState] = useSetState({
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    uploaded: true,
    fileUploading: false,
    loading: true,
    category: 'all',
    filterCategory: 'all',
    name: '',
    image: '',
    thumbnail: '',
    id: '',
    url: '',
    limit: 20,
    page: 1,
    search: '',
    selectedData: 'partners',
    seachValue: '',
  });

  const handleGetAllPartnerAndNetworkDetails = async ({ page, limit, search, category }) => {
    try {
      const data = {
        limit,
        page,
        search,
        category,
      };
      const res: any = await getAllPartnerAndNetworkDetails(data);
      dispatch({
        type: GET_PARTNER_AND_NETWORK_DETAILS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (error) {
      props.throwError(
        error.data ? error.data.desc : 'Failed to get Partner details',
      );
    }
  };
  useEffect(() => {
    handleGetAllPartnerAndNetworkDetails(state);
  }, [state.search, state.category]);
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleUpdateDetails = async () => {
    try {
      const data = {
        url: state.url,
        name: state.name,
        category: state.category,
        image: state.thumbnail,
      };
      await editPartnerAndNetwork(state.id, data);
      setState({
        openModal: false,
        edit: false,
        filterCategory: 'all',
        category: 'all'
      });
      handleGetAllPartnerAndNetworkDetails({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
        category: state.category
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to update details');
    }
  };

  const handleDeleteUser = (id) => {
    setState({ confirmModal: true, id });
  };

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };

  const handleAddDetails = async () => {
    setState({
      openModal: false,
      edit: false,
      uploaded: false,
      fileUploading: false,
    });
    try {
      const data = {
        url: state.url,
        name: state.name,
        category: state.filterCategory,
        image: state.thumbnail,
      };
      await addPartnerAndNetwork(data);
      handleGetAllPartnerAndNetworkDetails({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
        category: state.category
      });
      setState({
        category: 'all',
        filterCategory: 'all',
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to add details');
    }
  };

  const handleEdit = async (id) => {
    setState({ openModal: true, edit: true });
    try {
      const res: any = await getPartnerAndNetworkById(id);
      setState({
        filterCategory: res.data.category,
        name: res.data.name,
        url: res.data.url,
        thumbnail: res.data.thumbnail,
        id,
        uploaded: false,
        fileUploading: false,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get details');
    }
  };

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        page,
        search,
        category: state.category,
      };
      const res: any = await getAllPartnerAndNetworkDetails(data);
      res.data.docs = [...details.docs, ...res.data.docs]
      dispatch({
        type: GET_PARTNER_AND_NETWORK_DETAILS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (error) {
      props.throwError(
        error.data ? error.data.desc : 'Failed to get Partner details',
      );
    }
  };
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 })
    loadMore({
      limit: state.limit,
      page: state.page + 1,
      search: state.search
    })
  }
  const filterList = async (option) => {
    setState({ category: option, page: 1 })
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEdit,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDeleteUser,
      icon: DeleteIcon,
    },
  ];

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ filterCategory: selectedOption.value });
  };

  const handleOnDeletePartners = async () => {
    try {
      await deletePartnerAndNetwork(state.id);
      handleGetAllPartnerAndNetworkDetails({
        page: state.page,
        limit: state.limit,
        search: state.search,
        category: state.category
      });
      setState({
        category: 'all',
        filterCategory: 'all',
        confirmModal: false,
      })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to delete detail');
    }
  };
  const selectBoxDetails = [
    {
      label: 'Partner',
      value: 'partner',
    },
    {
      label: 'Network',
      value: 'network',
    },
  ];
  return (
    <div className="partnerandnetworkscreen">
      <div className="partnerandnetworkcontainer">
        <div className="partnerandnetworkwrapper">
          <div className="partnerandnetworkheaderwrapper">
          <div className="partner_title_wrapper">
          <div className="officers_name_wrapper h2">
             Partners & Networks
          </div>
          </div>
          <div className="partner_action_wrapper">
            <div className="partnerandnetwork_search_bar_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                suggessionData={csiUserSuggessionData}
                onChange={searchChange}
              />
            </div>
            <div className="partnerandnetwork_select_box_wrapper">
              <SelectBox
                data={selectBoxData}
                name=""
                selectedItem={state.category}
                onChange={(selectedOption) => {
                  filterList(selectedOption.value);
                }}
              />
            </div>
            <div className="partnerandnetwork_button_wrapper">
              <Button
                type="primary"
                text="Add New"
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    uploaded: false,
                    fileUploading: false,
                    name: '',
                    url: '',
                    thumbnail: '',
                    filterCategory: ''
                  })}
                icon={PlusIcon}
              />
            </div>
          </div>
          </div>
          <div className="partnerandnetwork_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
                <Table
                  header={partnersTableHeading}
                  body={details ? details.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={details.hasNextPage}
                  actionButton={actionArray}
                  error="No details found"
                />
              )}
          </div>
          <div className="partnerandnetwork_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'partnerandnetwork_customModal',
              }}
              showCloseIcon
            // closeIcon={inactiveIcon}
            >
              <div className="partnerandnetwork_modal_title_wrapper">
                <div className="partnerandnetwork_modal_title h4">
                  {state.edit !== true ? 'Add New' : 'Edit'}
                </div>
              </div>
              <div className="partnerandnetwork_modal_line_wrapper"> </div>
              <div className="partnerandnetwork_modal_input_wrapper">
                <div className="partnerandnetwork_modal_input">
                  <SelectBox
                    data={selectBoxDetails}
                    name="Select Category"
                    selectedItem={state.filterCategory}
                    onChange={handleOnChangeInSelectBox}
                  />
                </div>
                <div className="partnerandnetwork_modal_input">
                  <Input
                    name="Title"
                    onChange={handleOnChange}
                    inputKey="name"
                    value={state.name}
                  />
                </div>
                <div className="partnerandnetwork_modal_input">
                  <Input
                    name="URL"
                    onChange={handleOnChange}
                    inputKey="url"
                    value={state.url}
                  />
                </div>
                <div className="partnerandnetwork_modal_input">
                  Image
                  <div className="upload_box_wrapper">
                    <UploadBox
                      name="image"
                      className="uploadImage"
                      accept="image/x-png,image/gif,image/jpeg"
                      text="Drag and Drop or"
                      onChange={handleUploadThumbnail}
                      uploaded={state.uploaded}
                      loading={state.fileUploading}
                    />
                  </div>
                </div>
              </div>
              <div className="partnerandnetwork_button_wrapper">
                <div className="partnerandnetwork_button">
                  <Button
                    text={state.edit ? 'Update' : 'Add'}
                    type="primary"
                    onClick={
                      state.edit ? handleUpdateDetails : handleAddDetails
                    }
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete this ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleOnDeletePartners}
          />
        </div>
      </div>
    </div>
  );
}
