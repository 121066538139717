import React from "react";
import "./title_card.ui.scss";
import EditIcon from "../../../assets/icons/Edit.svg";
import DeleteIcon from "../../../assets/icons/Delete.svg";

interface TitleProps {
  title: String,
  className?: String,
  onClick?: any,
  editAction: Function,
  deleteAction: Function,
}

export default function TitleCard(props: TitleProps) {
  const { title, editAction, deleteAction, className, onClick } = props;
  return (
    <div className={`title_card_container ${className}`}>
      <div className="title_wrapper">
        <div className="title_name h4" onClick={onClick}>{title}</div>
      </div>
      <div className="action_wrapper">
        <div className="edit_action" onClick={() => editAction()}>
          <img alt="" src={EditIcon} className="action_edit_icon" />
        </div>
        <div className="delete_action" onClick={() => deleteAction()}>
          <img alt="" src={DeleteIcon} className="action_delete_icon" />
        </div>
      </div>
    </div>
  )
}

TitleCard.defaultProps = {
  className: "",
  onClick: ''
}
