import React from "react";
import "components/events_card/events_card.component.scss";
import wallpaper from "assets/images/wallpaper.jpg";

interface EventCardProps {
    day: string,
    month: string,
    title: string,
    description: string,
    image: string,
    className?: string,
    onClick?: any
}

export default function EventCard(props: EventCardProps) {
    const { day, month, title, description, image, className, onClick } = props;
    return (
        <div className="event_card_container" onClick={onClick}>
            <div className="event_card_wrapper">
            <div className="event_card_overlay" />
                <div className="event_card_image_wrapper">
                    <div className="event_card_overlay" />
                    <img alt="background_image" src={image} className="event_card_background_image" />
                </div>
                <div className="event_card_information_wrapper">
                    <div className={`event_card_date_wrapper ${className}`}>
                        <div className="event_card_date h3">{day}</div>
                        <div className="event_card_month h3">{month}</div>
                    </div>
                    <div className="event_card_title_wrapper">
                        <div className="event_card_title h4">{title}</div>
                        <div className="event_card_description h2">{description}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

EventCard.defaultProps = {
    className: "",
    onClick: ""
}
