import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import PlusIcon from 'assets/icons/Plus_White.svg';
import Table from 'components/table/table.component';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import Button from 'common_components/ui/button/button.ui';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import moment from 'moment';
import EditIcon from 'assets/icons/Edit.svg';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import DeleteIcon from 'assets/icons/Delete.svg';
import {
  AnnouncementNavData,
  govtNotificationTableHeading,
  csiUserSuggessionData,
} from 'helper/constants.helper';
import { uploadMedia } from 'models/news.model';
import { GET_STATEMENTS } from 'utils/types.utils';
import { useSetState } from '../../utils/common.utils';
import 'screens/announcement/govt_notification_schemes.screen.scss';
import {
  getAllDetails,
  addStatement,
  getStatementById,
  editStatement,
  deleteStatement,
} from '../../models/announcement.model';

export default function NotificationSchemes(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const statements = useSelector(
    (state: any) => state.announcements.statements,
  );
  const [state, setState] = useSetState({
    selectedData: match.params.type,
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    uploaded: false,
    fileUploading: false,
    id: '',
    search: '',
    loading: true,
    file: '',
    date: '',
    page: 1,
    limit: 25,
    title: '',
    description: '',
    url: '',
  });

  const handleGetAllDetails = async ({ limit, page, search }) => {
    try {
    const data = {
      page,
      search,
      type: match.params.type,
      limit,
    };
    const res: any = await getAllDetails(data);
    const details = res.data.docs.map((statement) => {
      statement.author = statement.created_by.user_name;
      statement.date = moment(statement.date).format('DD-MM-YYYY');
      return statement;
    });
    dispatch({
      type: GET_STATEMENTS,
      payload: res.data,
    });
    setState({ loading: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to fetch details');
    }
  };

  useEffect(() => {
    handleGetAllDetails(state);
  }, [match.params.type, state.search]);

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
    // const data = {
    //   page: state.page,
    //   search: e.target.value,
    //   type: match.params.type,
    //   limit: state.limit,
    // };
    // try {
    //   const res: any = await getAllDetails(data);
    //   const details = res.data.docs.map((statement) => {
    //     statement.author = statement.created_by.user_name;
    //     statement.date = moment(statement.created_at).format('DD-MM-YYYY');
    //     return statement;
    //   });
    //   dispatch({
    //     type: GET_STATEMENTS,
    //     payload: res.data,
    //   });
    // } catch (err) {
    //   props.throwError(err.data ? err.data.desc : 'Failed to fetch details');
    // }
  };

  const handleAddSchemes = async () => {
    try {
      const data = {
        pdf: state.url,
        title: state.title,
        description: state.description,
        type: match.params.type,
        date: new Date(state.date),
      };
      await addStatement(data);
      setState({
        edit: false,
        openModal: false,
        uploaded: false,
        fileUploading: false,
      });
      handleGetAllDetails({
        page: 1, limit: state.page * state.limit, search: state.search
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to add scheme');
    }
  };

  const handleEditSchemes = async (id) => {
    setState({ openModal: true, edit: true });
    try {
      const res: any = await getStatementById(id);
      setState({
        title: res.data.title,
        url: res.data.pdf,
        description: res.data.description,
        edit: true,
        id: res.data._id,
        date: moment(res.data.date).format('YYYY-MM-DD'),
        type: match.params.type,
        uploaded: false,
        fileUploading: false,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to get details');
    }
  };
  const handleUpdateSchemes = async () => {
    try {
      const data = {
        pdf: state.url,
        title: state.title,
        description: state.description,
        date: new Date(state.date),
        type: match.params.type,
      };
      await editStatement(state.id, data);
      handleGetAllDetails({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({
        openModal: false,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Unable to get details');
    }
  };

  const handleDeleteSchemes = (id) => {
    setState({ confirmModal: true, id });
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditSchemes,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleDeleteSchemes,
      icon: DeleteIcon,
    },
  ];

  const handleOnChangeInSearch = (e) => {
    setState({ search: e.target.value, page: 1 });
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: pdf.url });
            const fileName = pdf.url.split('/')[4];
            setState({
              url: pdf.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        const data = new FormData();
        setState({ fileUploading: true });
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const pdf: any = await uploadMedia(data);
        if (pdf) {
          setState({ pdf: pdf.url });
          const fileName = pdf.url.split('/')[4];
          setState({
            url: pdf.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };

  const handleOnDeleteSchemes = async () => {
    try {
      await deleteStatement(state.id);
      handleGetAllDetails({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to delete scheme');
    }
  };
  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        search,
        type: match.params.type,
        limit,
      };
      const res: any = await getAllDetails(data);
      const details = res.data.docs.map((statement) => {
        statement.author = statement.created_by.user_name;
        statement.date = moment(statement.created_at).format('DD-MM-YYYY');
        return statement;
      });
      res.data.docs = [...statements.docs, ...res.data.docs]
      dispatch({
        type: GET_STATEMENTS,
        payload: res.data,
      });
      } catch (err) {
        props.throwError(err.data ? err.data.desc : 'Failed to fetch details');
      }
  }

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }
  return (
    <div className="notification_schemes_screen">
      <div className="notification_schemes_container">
        <div className="notification_schemes_wrapper">
          <div className="notification_schemes_header_wrapper">
          <div className="notification_title_wrapper">
          <div className="officers_name_wrapper h2">
             Announcements
          </div>
          </div>
          <div className="notification_action_wrapper">
            <div className="notification_schemes_search_bar_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                placeholder="Search..."
                suggessionData={csiUserSuggessionData}
                onChange={searchChange}
              />
            </div>
            <div className="notification_schemes_button_wrapper">
              <Button
                type="primary"
                text="Add Schemes"
                icon={PlusIcon}
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    title: '',
                    description: '',
                    uploaded: false,
                    fileUploading: false,
                    url: '',
                  })}
              />
            </div>
          </div>
          </div>
        </div>
        <div className="notification_schemes_custom_navbar_wrapper">
          <CustomNavBar
            data={AnnouncementNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add User"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search News here"
            suggession={csiUserSuggessionData}
            buttonOnClick={() => {
              setState({ openModal: false, edit: false });
            }}
            button={false}
            search={false}
            onSearchChange={searchChange}
          />
        </div>
        <div className="notification_schemes_table-wrapper">
          {state.loading ? (
            <Spinner />
          ) : (
            <Table
              header={govtNotificationTableHeading}
              body={statements ? statements.docs : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              loadMore={loadMoreInitial}
              hasMore={statements && statements.hasNextPage ? statements.hasNextPage : ''}
              actionButton={actionArray}
              error="No statements found"
            />
          )}
        </div>
        <div className="notification_schemes_modal_container">
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'notification_schemes_customModal',
              closeButton: 'close_button',
            }}
            showCloseIcon
          >
            <div className="notification_schemes_modal_title_wrapper">
              <div className="notification_schemes_modal_title h4">
                {state.edit !== true ? 'Add' : 'Edit'}
              </div>
            </div>
            <div className="notification_schemes_modal_line_wrapper"> </div>
            <div className="notification_schemes_modal_input_wrapper">
              <div className="notification_schemes_modal_input">
                <Input
                  name="Title"
                  onChange={handleOnChange}
                  inputKey="title"
                  value={state.title}
                />
              </div>
              <div className="notification_schemes_modal_input">
              <Input name="" type="date" onChange={handleOnChange} inputKey="date" value={state.date} />
              </div>
              <div className="notification_schemes_modal_input">
                <TextArea
                  name="Description"
                  onChange={handleOnChange}
                  inputKey="description"
                  value={state.description}
                />
              </div>
              <div className="notification_schemes_modal_input">
                <div className="upload_box_wrapper">
                  Upload PDF
                  <div className="upload_box">
                    <UploadBox
                      name="uploadpdf"
                      className="pdfUpload"
                      loading={state.fileUploading}
                      uploaded={state.uploaded}
                      accept=".pdf,.docx"
                      text="Drag and Drop or"
                      onChange={handleUploadPDF}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="notification_schemes_modal_button_wrapper">
              <div className="notification_schemes_modal_button">
                <Button
                  text={state.edit ? 'Update' : 'Add'}
                  type="primary"
                  onClick={state.edit ? handleUpdateSchemes : handleAddSchemes}
                />
              </div>
            </div>
          </Modal>
        </div>
        <ConfirmModal
          openModal={state.confirmModal}
          description="Are you sure want to delete this ?"
          closeModal={() => setState({ confirmModal: false })}
          deleteConfirm={handleOnDeleteSchemes}
        />
      </div>
    </div>
  );
}
