import { GET_ADVERTISEMENT, ADVERTISEMENT_ERROR, GET_ADVERTISEMENT_WITH_TYPES } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface'

const initialState = [];
const advertisementReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_ADVERTISEMENT:
      const newState = { ...state, ...action.payload }
      return newState;

    case GET_ADVERTISEMENT_WITH_TYPES:
      return { ...state, advertisementTypes: action.payload }

    default:
      return state;
  }
}

export default advertisementReducer;
