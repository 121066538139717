import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './csi_life.screen.scss';
import '../../index.scss';
import Pagination from 'react-js-pagination';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import ResourcesCard from 'components/resources_card/resources_card';
import { getMonth, getYear, throwError, useSetState } from 'utils/common.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { csiMonthSelectionData } from 'helper/constants.helper';
import Navbar from 'components/navbar/navbar.component';
import { getAllPublication } from "models/public.model";
import moment from 'moment';
import { GET_CSI_LIFE } from 'utils/types.utils';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Eye from '../../assets/icons/Eye.svg'

export default function CsiLifeScreen(props) {
  const dispatch = useDispatch();
  const csi = useSelector((state: any) => state.publication.csi_life);
  const [state, setState] = useSetState({
    select_box_year: '',
    select_box_month: '',
    year: '',
    page: 1,
    month: '',
    limit: 21,
    loading: true
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };

  const handleGetAllCsiLife = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.searchValue,
        type: 'csi_life',
        sort: true,
        special_edition: 'all',
      };
      const csiLife: any = await getAllPublication(data);
      const doc = csiLife.data.docs.map((docs) => {
        const date = new Date(docs?.date);
        docs.author = docs.created_by?.user_name;
        docs.year = date.getFullYear();
        docs.month = getMonth(date.getMonth() + 1);
        docs.specialEdition = docs?.special_edition ? 'specialedition' : '-----';
        docs.date = moment(docs?.date).format('DD/MM/YYYY');
        return docs;
      });
      csiLife.data.docs = doc;
      dispatch({
        type: GET_CSI_LIFE,
        payload: csiLife.data,
      });
      setState({ loading: false });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get CSI Life details")
    }
  };
useEffect(() => {
  handleGetAllCsiLife()
}, [state.page])
const handleApplyFilters = async (month, year) => {
  try {
    const data = {
      limit: 20,
      page: 1,
      search: "",
      type: "csi_life",
      sort: true,
      special_edition: "all",
      month,
      year,
    };
    const csiLife: any = await getAllPublication(data);
    const doc = csiLife.data.docs.map((docs) => {
      const date = new Date(docs?.date);
      docs.author = docs.created_by?.user_name;
      docs.year = date.getFullYear();
      docs.month = getMonth(date.getMonth() + 1);
      return docs;
    });
    csiLife.data.docs = doc;
    dispatch({
      type: GET_CSI_LIFE,
      payload: csiLife.data
    })
    setState({ filterModal: false })
  } catch (err) {
    throwError(err.data ? err.data.desc : "Failed to filter CSI Life list")
  }
}
  return (
    <div className="screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
        <div className="csi_life_screen_header_wrapper">
          <div className="csi_life_screen_header h2">
            CSI Life
            <div className="header_line csi_life" />
          </div>
        </div>
      <div className="csi_life_screen_container">
      <div className="select_box_wrapper">
          <div className="select_box">
            <SelectBox
              data={csiMonthSelectionData}
              name=""
              selectedItem={state.month}
              onChange={(selectedOption) => {
                handleApplyFilters(selectedOption.value, state.year)
                setState({
                  month: selectedOption.value
                })
              }}
            />
          </div>
          <div className="select_box">
            <SelectBox
              data={getYear(1947)}
              name=""
              selectedItem={state.year}
              onChange={(selectedOption) => {
                 handleApplyFilters(state.month, selectedOption.value)
                 setState({
                  year: selectedOption.value
                })
                }}
            />
          </div>
      </div>
        <div className="csi_life_screen_body_wrapper">
          {state.spinner ? <Spinner /> : csi && csi.docs.length > 0 ? csi.docs.map((csiLife) => {
            return (
            <div className="card_wrapper">
              <ResourcesCard
                onClick={() => {}}
                hoverText="View"
                icon={Eye}
                splEdition={csiLife.special_edition ? 'Special Edition' : ''}
                title={`${csiLife.year} ${csiLife.month}`}
                type="publications"
                url={csiLife.thumbnail}
                pdfLink={csiLife.pdf}
              />
            </div>
            )
          }) : "No Resources found"}
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            csi && csi.totalDocs ? csi.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
