import React from 'react';
import './custom_nav.component.scss';
import { NavLink } from 'react-router-dom';
import uuid from 'react-uuid';
import SearchBar from "../../common_components/ui/search_bar/search_bar.ui";
import Button from "../../common_components/ui/button/button.ui";

interface CustomNames {
  navName: string,
  navValue: string,
  navUrl: string
}

interface suggessionArray {
  icon: string,
  name: string
}

interface NavBarProps {
  data: CustomNames[],
  selectedItem: string,
  onTapClick?: any,
  buttonText: string,
  buttonType: string,
  buttonIcon?: string,
  searchText: string,
  suggession?: suggessionArray[],
  buttonOnClick?: any,
  search?: boolean,
  onSearchChange?: any,
  searchType?: string,
  button?: boolean
}

const CustomNavBar = (props: NavBarProps) => {
  const { data, selectedItem, onTapClick,
    buttonText, buttonType, buttonIcon, searchType, searchText, suggession, buttonOnClick,
    button, search, onSearchChange } = props;
  return (
    <div className="custom_nav_component">
      <div className="custom_nav_container">
        <div className="custom_nav_wrapper">
          {data.map((navLink) => {
            return (
              <div className="custom_nav_list" key={uuid()}>
                {navLink.navValue === selectedItem
                  ? <NavLink to={navLink.navUrl} className="selected_custom_nav_link h3">
                      {navLink.navName}
                    </NavLink>
                  : <NavLink className="custom_nav_link" to={navLink.navUrl} onClick={() => onTapClick(navLink.navValue)}>{navLink.navName}</NavLink>}
              </div>)
          })}
        </div>
        <div className="custom_nav_wrapper custom_nav_wrapper_flex">
          {search === true && <div className="custom_nav_search_wrapper"><SearchBar suggession={searchText} placeholder={searchText} suggessionData={suggession} type={searchType} onChange={onSearchChange} /></div>}

          {button === true && <div className="custom_nav_button_wrapper"><Button text={buttonText} type={buttonType} icon={buttonIcon} onClick={buttonOnClick} /></div>}
        </div>
      </div>
    </div>
  )
}

CustomNavBar.defaultProps = {
  buttonIcon: "",
  searchType: "primary",
  search: false,
  button: false,
  onTapClick: '',
  buttonOnClick: '',
  onSearchChange: '',
  suggession: []
}

export default CustomNavBar;
