import React from "react";
import "common_components/ui/pdf_file/pdf_file.component.scss";
import Arrow from "assets/icons/Arrow_right_fill.svg";
import { Link } from "react-router-dom";
import Pdf from "assets/icons/pdf.svg";

interface PdfFileProps {
    className?: string,
    title: string,
    onClick: any
}

export default function PdfFile(props: PdfFileProps) {
    const { className, title, onClick } = props;
    return (
        <div className="pdf_file_container">
            <div className="pdf_file_wrapper">
                <div className={`pdf_file_image_wrapper ${className}`}>
                    <img src={Pdf} alt="pdf_icon" className="pdf_file_image" />
                </div>
                <div className="pdf_file_name_button_wrapper" onClick={onClick}>
                    <div className="pdf_file_name h4">Pdf</div>
                    <div className="pdf_file_button_wrapper">
                        <div className="pdf_button_title h6">View More</div>
                        <div className="pdf_file_button_icon">
                            <img src={Arrow} alt="pdf_button_icon" className="button_icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PdfFile.defaultProps = {
    className: ""
}
