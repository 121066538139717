import React, { useState } from 'react';
import './search_bar.ui.scss';
import uuid from 'react-uuid';
import SearchIcon from '../../../assets/icons/Search.svg';

interface suggessionArray
{
  icon: string,
  name: string
}

interface SearchProps {
  width?: string,
  suggession: string,
  suggessionData?: suggessionArray[],
  className?: string,
  type?: string,
  onChange: any,
  placeholder?: string,
}

const SearchBar = (props: SearchProps) => {
  const { width, suggession, suggessionData, placeholder, className, type, onChange } = props;

  return (
    <div className={`search_box_container ${className || ''}`}>
      <div className={`search_box_wrapper ${type === "secondary" && "search_box_secondary"}`}>
        <div className="search_box_icon_container"><img src={SearchIcon} alt="search_icon" /></div>
        <div className="search_box_input">
          <input className="search_input" onChange={onChange} type="text" placeholder={placeholder} />
        </div>
      </div>
    </div>
  )
}

SearchBar.defaultProps = {
  width: '',
  className: "",
  type: 'primary',
  placeholder: "",
  suggessionData: []
}

export default SearchBar;
