import React from 'react';
import Pagination from 'react-js-pagination';
import './pagination.component.scss';
import '../../index.scss';
import { useSetState } from 'utils/common.utils';

export default function PaginationComponent() {
  const [state, setState] = useSetState({
    activePage: 1,
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber });
  };

  return (
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={450}
          pageRangeDisplayed={3}
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          hideFirstLastPages
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
  );
}
