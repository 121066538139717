import React from "react";
import "./download_app.component.scss";
import Phoneimages from "../../assets/images/csi_mobile_app.png";
import AppStore from '../../assets/icons/App_Store_Badge.svg';
import GooglePlayStore from '../../assets/icons/Google_Play_Store_badge.svg';

export default function DownloadApp(props) {
    return (
        <div className="downloadapp_container">
            <div className="downloadapp_wrapper">
                <div className="downloadapp_image_wrapper">
                    <img className="downloadapp_image" alt="Csimobileapp" src={Phoneimages} />
                </div>
                <div className="downloadapp_text_wrapper">
                    <div className="downloadapp_description h4">Download Our App</div>
                    <div className="downloadapp_title h3">CSI Book of Common Worship</div>
                    <div className="downloadapp_line"> </div>
                    <div className="downloadapp_icon_wrapper">
                        <a href="https://apps.apple.com/in/app/csi-book-of-common-worship/id1481040580" target="_blank" rel="noreferrer">
                        <div className="apple_icon"><img className="apple_store" alt="" src={AppStore} /></div>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.churchofsouthindia.csi" target="_blank" rel="noreferrer">
                        <div className="google_icon"><img className="google_store" alt="" src={GooglePlayStore} /></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
