import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import './csi_lectionary.user.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import ResourcesCard from 'components/resources_card/resources_card';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { csiMonthSelectionData } from 'helper/constants.helper';
import Navbar from 'components/navbar/navbar.component';
import { getAllPublication } from "models/public.model";
import { GET_LECTIONARY_LIST } from 'utils/types.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getYear, throwError, useSetState } from 'utils/common.utils';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Eye from '../../assets/icons/Eye.svg'

export default function CsiLectionaryUserScreen(props) {
  const dispatch = useDispatch();
  const lectionaryList = useSelector(
    (state: any) => state.worship_resources.csi_lectionary_list,
  );
  const [state, setState] = useSetState({
    loading: true,
    page: 1,
    limit: 21,
    activePage: 1,
    search: '',
    type: 'csi_lectionary',
    year: '',
    month: ''
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  const fetchLectionaryList = async () => {
    try {
      const data = {
        page: state.page,
        limit: state.limit,
        search: state.search,
        type: state.type,
        sort: true
      };
      const res: any = await getAllPublication(data);
      dispatch({
        type: GET_LECTIONARY_LIST,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to get Lectionary list")
    }
  };
  const handleApplyFilters = async (year) => {
    try {
      const data = {
        limit: 20,
        sort: true,
        page: 1,
        search: "",
        type: "csi_lectionary",
        year,
      };
      const res: any = await getAllPublication(data);
      dispatch({
        type: GET_LECTIONARY_LIST,
        payload: res.data,
      });
      setState({ filterModal: false })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Failed to filter Lectionary list")
    }
  }
  useEffect(() => {
    fetchLectionaryList();
  }, [state.page]);

  return (
    <div className="lectionary_screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper" style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
        <Navbar {...props} />
        </div>
      </div>
      <div className="lectionary_screen_container">
        <div className="lectionary_header_wrapper">
          <div className="lectionary_heading h2">Worship Resources</div>
          <div className="lectionary_header_line" />
        </div>
        <div className="lectionary_screen_title_wrapper">
          <div className="lectionary_screen_title h1">
            <div className="title_links_wrapper">
              <div className="link h3">
                <NavLink
                  to="/resources/worship_resource/prayer_calendar"
                  className="title_link"
                >
                  Prayer Calendar
                </NavLink>
              </div>
              <div className="link_1 h3">
                <NavLink
                  to="/resources/worship_resource/csi_lectionary"
                  className="title_link"
                  activeClassName="selected"
                >
                  CSI Lectionary
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/resources/worship_resource/sermon_outlines"
                  className="title_link"
                >
                  Sermon Outlines
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="lectionary_select_box_wrapper">
            {/* <div className="lectionary_select_box">
              <SelectBox
                data={csiMonthSelectionData}
                name=""
                selectedItem={state.month}
                onChange={(selectedOption) => {
                  handleApplyFilters(selectedOption.value, state.year)
                  setState({
                    month: selectedOption.value
                  })
                }}
              />
            </div> */}
            <div className="lectionary_select_box">
              <SelectBox
                data={getYear(1947)}
                name=""
                selectedItem={state.year}
                onChange={(selectedOption) => {
                  handleApplyFilters(selectedOption.value)
                  setState({
                    year: selectedOption.value
                  })
                }}
              />
            </div>
        </div>
      <div className="lectionary_body_wrapper">
        {state.loading ? <Spinner />
         : lectionaryList && lectionaryList.docs.length > 0
          ? lectionaryList.docs.map((lectionary) => {
            return (
          <div className="lectionary_card_wrapper">
            <ResourcesCard
              onClick={() => {}}
              hoverText="View"
              icon={Eye}
              title={lectionary.title}
              type="publications"
              url={lectionary.thumbnail}
              pdfLink={lectionary.pdf}
            />
          </div>)
          }) : <div className="error_text">No lectionary data found</div> }
      </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            lectionaryList && lectionaryList.totalDocs ? lectionaryList.totalDocs : 20
          }
          pageRangeDisplayed={10}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
