import React, { useEffect } from "react";
import Footer from "components/footer/footer.component";
import Header from "components/header/header.component";
import Navbar from "components/navbar/navbar.component";
import { NavLink } from "react-router-dom";
import OfficerCard from "components/officer_card/officer_card.component";
import "screens/user_screens/officer.screen.scss";
import { getAllUser } from "models/public.model";
import { GET_OFFICERS } from "utils/types.utils";
import { useDispatch, useSelector } from "react-redux";
import { throwError, useSetState } from "utils/common.utils";

export default function Officer(props) {
    const dispatch = useDispatch();
    const officers = useSelector((state: any) => state.aboutCsi.office);
    const [state, setState] = useSetState({
      selectedData: "officers",
      search: '',
      limit: 20,
      page: 1,
      officerId: "",
      loading: true,
      uploaded: false,
    });

    const handleGetAllOfficers = async () => {
      try {
        const data = {
          limit: state.limit,
          search: state.search,
          page: state.page,
          type: "officers",
          user_sort: true
        }
        const officer: any = await getAllUser(data);
      if (officer) {
        const doc = officer.data.map((docs) => {
          if (docs.phone && docs.fax) {
          docs.phone_fax = `${docs.phone}/${docs.fax}`
          docs.author = docs.created_by.user_name
          } else if (docs.phone || docs.fax) {
            docs.phone_fax = `${docs.phone}${docs.fax}`
            docs.author = docs.created_by.user_name
          } else {
            docs.phone_fax = ''
            docs.author = docs.created_by.user_name
          }
          return docs;
        });
        officer.data.docs = doc;
        dispatch({
          type: GET_OFFICERS,
          payload: officer.data
        })
        setState({ loading: false });
      }
      } catch (err) {
        throwError(err.data ? err.data.desc : "Failed to get Officer details")
    }
    }

    useEffect(() => {
      handleGetAllOfficers();
    }, [])

    return (
        <div className="officer_screen">
            <div className="officer_container">
                <div className="officer_wrapper">
                    <div className="officer_header_wrapper">
                        <Header />
                    </div>
                    <div className="officer_navbar_wrapper" style={{ position: 'sticky', top: '0', zIndex: 99999 }}>
                        <Navbar {...props} />
                    </div>
                    <div className="officer_title_wrapper">
                        <div className="officer_title h2">Administration</div>
                        <div className="officer_line_wrapper"> </div>
                    </div>
                        <div className="officer_nav_link_wrapper h1">
                            <div className="title_links_wrapper">
                                <div className="link_1 h3">
                                    <NavLink
                                      to="/about/officer"
                                      className="title_link"
                                      activeClassName="selected"
                                    >
                                        Officers
                                    </NavLink>
                                </div>
                                <div className="link h3">
                                    <NavLink
                                      to="/about/working_committee"
                                      className="title_link"
                                    >
                                        Working Committee
                                    </NavLink>
                                </div>
                                <div className="link h3">
                                    <NavLink
                                      to="/about/executive_committee"
                                      className="title_link"
                                    >
                                        Executive Committee
                                    </NavLink>
                                </div>
                                <div className="link h3">
                                    <NavLink
                                      to="/about/csita"
                                      className="title_link"
                                    >
                                        CSITA
                                    </NavLink>
                                </div>
                                <div className="link h3">
                                    <NavLink
                                      to="/about/secretarial_staff/Staff Under General Secretary"
                                      className="title_link"
                                    >
                                        Secretarial Staff
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    <div className="officer_content_wrapper">
                        <div className="officer_content">
                            {officers && officers.map((officer) => {
                            return (<div className="officer_card">
                                <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="officers_card" />
                                    </div>)
                            })}
                        </div>
                    </div>
                    <div className="officer_footer_wrapper">
                        <div className="officer_footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
