import React from 'react';
import './news_card_3.component.scss';
import ArrowRightFill from '../../assets/icons/Arrow_right_fill.svg';

interface NewsCard3Props {
  title: string;
  description: string;
  link: string;
  embedId?: string;
  onClick: any;
  type: string;
  className?: string;
  reverse?: boolean;
  text: string;
}

const NewsCard3 = (props: NewsCard3Props) => {
  const {
    title,
    description,
    link,
    onClick,
    type,
    className,
    embedId,
    reverse,
    text,
  } = props;
  return (
    <div className="news_card_3_component">
      <div
        className={`news_card_3_container ${
          reverse && 'news_card_3_flex_reverse'
        }`}
      >
        <div className="news_card_3_wrapper wrapper_small">
          <div className="news_card_3_media">
            {type === 'video' && (
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={embedId}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
            {type === 'youtube' && (
              <div className="video-responsive">
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${embedId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            )}
            {type === 'image' || type === 'news_card_1' ? (
              <img
                src={link}
                alt="news_card_3_media"
                onClick={onClick}
                className={`news_card_3_img ${className} ${reverse && 'news_card_3_img_border_left'}`}
              />
            ) : ""}
          </div>
        </div>
        <div className="news_card_3_wrapper">
          <div className="news_card_3_content">
            <div className="news_card_3_text">{text}</div>
            <div className="news_card_3_head h3">{title}</div>
            {type === 'video' || type === 'youtube' ? (
              ''
            ) : (
              <>
                <div className="news_card_3_time_stamp h5">{description}</div>
                <div className="news_card_3_view">
                  <div className="news_card_3_view_text" onClick={onClick}>Read More</div>
                  <div className="arrow_icon">
                    <img
                      src={ArrowRightFill}
                      alt="arrow_right_fill"
                      className="arrow_right_fill"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NewsCard3.defaultProps = {
  className: '',
  embedId: '',
  reverse: false,
};

export default NewsCard3;
