import React, { useEffect } from "react";
import Button from "common_components/ui/button/button.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import Arrow from "assets/icons/Arrow.svg";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import { uploadMedia } from "models/upload_media.model";
import "screens/aboutCSI/add_staff.screen.scss";
import { staffCategoryData, designationData } from "helper/constants.helper";
import { uploadMediaFiles, useSetState } from "../../utils/common.utils";

export default function AddStaff(props) {
  const { match } = props;

  const [state, setState] = useSetState({
    role: "",
    userName: "",
    profile: "",
    email: "",
    mobile: "",
    designation: "",
    uploaded: false,
    imageUploading: false,
    sortOrder: "",
    category: ""
  });

  const handleGetStaffById = async () => {
    try {
      const staff: any = await getUserById(match.params.id);
      if (staff) {
        setState({
          userName: staff.data.user_name,
          designation: staff.data.designation,
          profile: staff.data.profile_picture,
          role: staff.data.role,
          email: staff.data.email,
          mobile: staff.data.mobile,
          sortOrder: staff.data.sort,
          uploaded: staff.data.profile_picture ? true : false
        })
      }
    } catch (err) {
      props.throwError("Failed to get staff");
    }
  }

  useEffect(() => {
    if (match.params.id) {
      handleGetStaffById();
    }
  }, [])

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ imageUploading: true });
          const profile: any = await uploadMediaFiles(e);
          if (profile) {
            setState({ profile: profile.url, uploaded: true, imageUploading: false })
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif',)
        }
      } else {
        setState({ imageUploading: true })
        const profile: any = await uploadMediaFiles(e);
        if (profile) {
          setState({ profile: profile.url, uploaded: true, imageUploading: false })
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Personal Image");
    }
  }

  const handleUpdateStaff = async () => {
    try {
      const data = {
        user_name: state.userName,
        email: state.email,
        profile_picture: state.profile,
        designation: state.designation,
        mobile: state.mobile,
        sort: Number(state.sortOrder),
        role: state.role,
      }
      const staff: any = await editUser(match.params.id, data);
      await handleGetStaffById();
      props.history.push('/about_csi/secretarial_staff');
    } catch (err) {
      props.throwError(err.data ? err.data.message || err.data.desc : "Failed to update staff");
    }
  }

  const handleAddStaff = async () => {
    try {
      const data = {
        user_name: state.userName,
        email: state.email,
        profile_picture: state.profile,
        designation: state.designation,
        mobile: state.mobile,
        role: state.role,
        sort: state.sortOrder,
        user_type: "secretarial_staff"
      }
      const committee: any = await addUser(data);
      setState({ openModal: false })
      props.history.push('/about_csi/secretarial_staff');
    } catch (err) {
      props.throwError(err.data ? err.data.message || err.data.desc : "Failed to add staff");
    }
  }

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ role: selectedOption.value });
  }

  const handleOnChangeIndesignation = (selectedOption) => {
    setState({ designation: selectedOption.value });
  }

  return (
    <div className="add_staff_screen">
      <div className="add_staff_container">
        <div className="add_staff_wrapper">
          <div className="add_staff_header_wrapper">
            <div className="add_staff_header_name_wrapper">
              <div className="add_staff_icon_wrapper">
                <img className="add_stafAboutCsiNavData, workingCommitteeTableHeading, workingCommitteAboutCsiNavData, workingCommitteeTableHeading, workingCommitteeBody, csiUserSuggessionData, eBody, csiUserSuggessionData, f_nav_icon" alt="nav_icon" src={Arrow} onClick={() => props.history.push("/about_csi/secretarial_staff")} />
              </div>
              <div className="add_staff_header_name h2">{match.params.id !== undefined ? "Edit Secretarial Staff" : "Add Secretarial Staff"}</div>
            </div>
            <div className="add_staff_header_button_wrapper">
              <Button text={match.params.id ? "Update" : "Add"} type="primary" onClick={match.params.id ? handleUpdateStaff : handleAddStaff} />
            </div>
          </div>
          <div className="add_staff_body_wrapper">
            <div className="add_staff_input_wrapper">
              <div className="add_staff_input">
                <Input name="Name" onChange={handleOnChange} inputKey="userName" value={state.userName} />
              </div>
              <div className="add_staff_input">
                <SelectBox data={staffCategoryData} name="Staff Category" selectedItem={state.role} onChange={handleOnChangeInSelectBox} />
              </div>
              <div className="add_staff_input">
                <SelectBox data={designationData} name="Designation" selectedItem={state.designation} onChange={handleOnChangeIndesignation} />
              </div>
              <div className="add_staff_input">
                <div className="upload_box_wrapper">
                  Image
                    <div className="upload_box">
                    <UploadBox name="uploadpdf" className="thumbnail" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" onChange={handleUploadImage} loading={state.imageUploading} uploaded={state.uploaded} />
                    </div>
                </div>
              </div>
            </div>
            <div className="add_staff_input_wrapper">
              <div className="add_staff_input">
                <Input name="Email" onChange={handleOnChange} inputKey="email" value={state.email} />
              </div>
              <div className="add_staff_input">
                <Input name="Mobile" onChange={handleOnChange} type="number" inputKey="mobile" value={state.mobile} />
              </div>
              <div className="add_staff_input">
              <Input name="Sort" onChange={handleOnChange} type="number" inputKey="sortOrder" value={state.sortOrder} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
