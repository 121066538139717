import React, { useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import moment from "moment";
import _ from "lodash";
import { GET_RESOURCES, GET_DEPARTMENT, THROW_ERROR, GET_ALL_DEPARTMENTS } from 'utils/types.utils';
import { getAllDepartment, getAllDepartments } from "models/department.model";
import { getAllPublication, addPublication, getPublicationById, editPublication, deletePublication, uploadMedia } from "models/publication.model";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Spinner from "common_components/ui/spinner/spinner.ui";
import Button from "common_components/ui/button/button.ui";
import CheckList from "common_components/ui/checklist/checklist.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import Table from "components/table/table.component";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import PlusIcon from "assets/icons/Plus_White.svg";
import Filter from "assets/icons/Filter.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import Tick from "assets/icons/Tick_White.svg";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import { PublicationNavData, resourceTableHeading, resourceBody, csiUserSuggessionData, departmentData, resourceSelectionData } from "helper/constants.helper";
import "screens/publication/resources.screen.scss";
import { useSetState, getYear, uploadMediaFiles } from "utils/common.utils";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function Resources(props) {
  const departments = useSelector((state: any) => state.department.allDepartments);
  const resources = useSelector((state: any) => state.publication.resources);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selectedData: "resources",
    edit: false,
    openModal: false,
    filterModal: false,
    checkedList: [],
    checkList: [],
    isChecked: false,
    confirmModal: false,
    title: "",
    pdf: [],
    year: "",
    searchValue: "",
    fromDate: "",
    toDate: "",
    image: "",
    limit: 25,
    page: 1,
    search: "",
    selectedDepartment: "",
    resourceId: "",
    thumbnail: "",
    resourceName: "",
    loading: true,
    fromYear: "",
    toYear: "",
    fileUploading: false,
    uploaded: false,
    pdfLoading: false,
    pdfUploaded: false
  });
  interface ResourcesData {
    data: object,
    message: string,
    status: string
  }

  interface DepartmentArray {
    label: string,
    value: string,
  }
  const selectionDepartmentData: DepartmentArray[] = [];
  const departmentSelection = departments && departments.length !== 0
    && departments.map((department) => {
      selectionDepartmentData.push({ label: department.name, value: department._id });
      return "";
    });
  const deptData: any = [];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ name: department.name, value: department._id });
      return "";
    });

  const handleGetAllDepartment = async () => {
    try {
      const data = {
        limit: 30,
        page: state.page,
      };
      const department: any = await getAllDepartments();
        dispatch({
          type: GET_ALL_DEPARTMENTS,
          payload: department.data
        })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Department is not found in Resources");
    }
  }

  const handleGetAllResources = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        search,
        sort: true,
        type: "resources",
        year_from: state.fromYear,
        year_to: state.toYear,
        department: state.filter
      };
      const resource: any = await getAllPublication(data);
      if (resource !== undefined && resource.data !== undefined
        && resource.data.docs !== undefined) {
        setState({ loading: false })
        const doc = resource.data.docs.map((docs) => {
          docs.department_name = docs.department.name;
          docs.author = docs.created_by.user_name;
          docs.date = moment().format('DD/MM/YYYY')
          return docs;
        });
        resource.data.docs = doc;
        dispatch({
          type: GET_RESOURCES,
          payload: resource.data
        })
        setState({ loading: false })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get Resources")
    }
  }

  const handleAddResource = async () => {
    try {
      const data = {
        department: state.selectedDepartment,
        title: state.title,
        year: Number(state.year),
        pdf: state.pdf,
        thumbnail: state.thumbnail,
        resource_name: state.resourceName,
        type: "resources"
      }
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        const resource = await addPublication(data);
        await handleGetAllResources({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false, edit: false })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to Add Resources");
    }
  }

  const handleEditResource = async (id) => {
    try {
      setState({ openModal: true, edit: true, resourceId: id });
      const resource: any = await getPublicationById(id);
      if (resource && resource.data) {
        const { department, title, year, pdf, thumbnail } = resource.data;
        setState({
          selectedDepartment: department,
          title,
          year,
          pdf: pdf[0],
          thumbnail,
          uploaded: thumbnail ? true : false,
          pdfUploaded: pdf ? true : false,
          resourceName: resource.data.resource_name
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to load resources");
    }
  }

  const handleUpdateResource = async () => {
    try {
      const data = {
        department: state.selectedDepartment,
        title: state.title,
        year: state.year,
        thumbnail: state.thumbnail,
        pdf: state.pdf,
        resource_name: state.resourceName
      }
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        const resource = await editPublication(state.resourceId, data);
        await handleGetAllResources({
          page: 1, limit: state.page * state.limit, search: state.search
        });
        setState({ openModal: false, edit: false })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to update resource");
    }
  }

  const handleDeleteResource = (id) => {
    setState({ confirmModal: true, resourceId: id })
  }

  const handleOnDeleteResource = async () => {
    try {
      const resource = await deletePublication(state.resourceId);
      await handleGetAllResources({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false, searchValue: "" })
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to delete resource");
    }
  }

  useEffect(() => {
    handleGetAllDepartment();
    handleGetAllResources(state);
  }, [state.search])

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ checkList: indexes });
      }
    }
  };

  const loadMore = async ({ limit, search, page }) => {
    try {
      const data = {
        limit,
        page,
        search,
        sort: true,
        type: "resources",
        year_from: state.fromYear,
        year_to: state.toYear,
        department: state.filter
      };
      const resource: any = await getAllPublication(data);
      if (resource !== undefined && resource.data !== undefined
        && resource.data.docs !== undefined) {
        const doc = resource.data.docs.map((docs) => {
          docs.department_name = docs.department.name;
          docs.author = docs.created_by.user_name;
          docs.date = moment().format('DD/MM/YYYY')
          return docs;
        });
        resource.data.docs = doc;
        resource.data.docs = [...resources.docs, ...resource.data.docs];
        dispatch({
          type: GET_RESOURCES,
          payload: resource.data
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to get Resources")
    }
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditResource,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteResource,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = async (e) => {
   setState({ search: e.target.value, page: 1 })
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true })
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name.split('.')[0].replace(/ /g, '')
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: pdf.url })
            const fileName = pdf.url.split("/")[4];
            setState({ resourceName: fileName, pdfUploaded: true, pdfLoading: false });
          }
        } else {
          props.throwError("File is not supported! Please Upload Pdf");
        }
      } else {
          const data = new FormData();
          setState({ pdfLoading: true })
          const ext = e.target.files[0].name.split('.').pop();
          const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '')
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.target.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: pdf.url })
            const fileName = pdf.url.split("/")[4];
            setState({ resourceName: fileName, pdfUploaded: true, pdfLoading: false });
          }
        }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      } else {
        props.throwError('Upload Image Format Jpg, Png, Gif',)
      }
      } else {
        setState({ fileUploading: true })
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleApplyFilter = async () => {
    setState({ filterModal: false })
    try {
      const departmentFilter: any = [];
      state.checkList.map((d) => {
        departmentFilter.push(d.value);
        return null;
      })
      setState({ filter: departmentFilter, page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: state.search,
        type: "resources",
        year_from: state.fromYear,
        year_to: state.toYear,
        department: departmentFilter
      };
      const resource: any = await getAllPublication(data);
      if (resource !== undefined && resource.data !== undefined
        && resource.data.docs !== undefined) {
        const doc = resource.data.docs.map((docs) => {
          docs.department_name = docs.department.name;
          docs.author = docs.created_by.user_name;
          docs.date = moment().format('DD/MM/YYYY')
          return docs;
        });
        resource.data.docs = doc;
        dispatch({
          type: GET_RESOURCES,
          payload: resource.data
        })
      }
    } catch (err) {
      props.throwError(err.data ? err.data.desc : "Unable to Load Resources")
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  return (
    <div className="resources_screen">
      <div className="resources_container">
        <div className="resources_wrapper">
      <div className="resources_header_wrapper">
        <div className="books-title_wrapper">
          <div className="officers_name_wrapper h2">
              Publications
          </div>
        </div>
        <div className="resources_action_wrapper">
            <div className="resources_search_wrapper">
              <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={(e) => setState({ search: e.target.value, page: 1 })} />
            </div>
            <div className="resources_filter_button_wrapper">
              <Button type="primary" text="Filter" onClick={() => setState({ filterModal: true })} icon={Filter} />
            </div>
            <div className="resources_action_button_wrapper">
              <Button className="resources_action_button" type="primary" text="Add Resources" onClick={() => setState({ openModal: true, edit: false, title: "", selectedDepartment: "", year: "" })} icon={PlusIcon} />
            </div>
        </div>
      </div>
          <div className="resources_custom_navbar_wrapper">
            <CustomNavBar
              data={PublicationNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddResource}
              button={false}
              search={false}
              onSearchChange={() => {}}
            />
          </div>
          <div className="officers_table-wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={resourceTableHeading}
                    body={resources ? resources.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    hasMore={resources && resources.hasNextPage ? resources.hasNextPage : ''}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    actionButton={actionArray}
                    error="No resources found"
                />
            }
          </div>
          <div>
            <Modal
              open={state.filterModal}
              onClose={() => setState({ filterModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'resources_filter_modal'
              }}
              showCloseIcon={false}
            >
              <div className="resources_filter_modal_container">
                <div className="resources_filter_modal_wrapper">
                  <div className="resources_filter_modal_header_wrapper">
                    <div className="resources_filter_modal_header h4">
                      Year
                    </div>
                    <div
                      className="resources_filter_modal_clear h6"
                      onClick={() => {
                        setState({ fromYear: '', toYear: '' })
                  }}>
                      Clear All
                    </div>
                  </div>
                  <div className="resources_filter_modal_date_wrapper">
                    <div className="resources_filter_modal_name h4">From</div>
                    <div className="resources_filter_modal_input_wrapper">
                      <SelectBox
                        data={getYear(1947)}
                        name=""
                        selectedItem={state.fromYear}
                        onChange={
                          (selectedOption) => {
                          setState({ fromYear: selectedOption.value })
                        }
                          }
                      />
                    </div>
                    <div className="resources_filter_modal_name h4">To</div>
                    <div className="resources_filter_modal_input_wrapper">
                      <SelectBox data={getYear(1947)} name="" selectedItem={state.toYear} onChange={(selectedOption) => setState({ toYear: selectedOption.value })} />
                    </div>
                  </div>
                  <div className="resources_filter_modal_header_wrapper">
                    <div className="resources_filter_modal_department_heading h4">
                      Department
                    </div>
                    <div className="resources_filter_modal_department_clear h6" onClick={() => setState({ checkList: [], page: 1 })}>
                      Clear All
                    </div>
                  </div>
                  <div className="resources_filter_modal_checklist">
                    <CheckList data={deptData ? deptData : []} icon={Tick} multiple selectedData={state.checkList} iconBackground="" onChange={handleOnChangeList} list={state.checkList} multipleList={state.checkList} />
                  </div>
                  <div className="resources_filter_apply_filter_button_wrapper">
                    <div className="resources_filter_button">
                      <Button text="Apply Filters" type="primary" onClick={handleApplyFilter} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="resources_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'resources_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="resources_modal_header_wrapper">
                <div className="resources_modal_title_wrapper">
                  <div className="resources_modal_title h4">{state.edit !== true ? "Add Resources" : "Edit Resources"}</div>
                  <div className="resources_modal_icon_wrapper">
                    <img className="resources_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="resources_modal_line_wrapper"> </div>
              </div>
              <div className="resources_modal_input_wrapper">
                <div className="resources_modal_input">
                  <SelectBox data={selectionDepartmentData} name="Select Department" selectedItem={state.selectedDepartment} onChange={(selectedOption) => setState({ selectedDepartment: selectedOption.value })} />
                </div>
                <div className="resources_modal_input">
                  <Input name="Title" onChange={handleOnChange} inputKey="title" value={state.title} />
                </div>
                <div className="resources_modal_select_input">
                  <SelectBox data={getYear(1947)} name="Year" selectedItem={state.year} onChange={(selectedOption) => setState({ year: selectedOption.value })} />
                </div>
                <div className="resources_modal_input">
                  <div className="upload_box_wrapper">
                    Thumbnail
                    <div className="upload_box">
                      <UploadBox name="thumbnail" className="uploadThumbnail" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" uploaded={state.uploaded} loading={state.fileUploading} onChange={handleUploadImage} />
                    </div>
                  </div>
                </div>
                <div className="resources_modal_input">
                  <div className="upload_box_wrapper">
                    Upload PDF
                    <div className="upload_box">
                      <UploadBox name="pdf" className="UploadPdf" accept=".pdf" multiple text="Drag and Drop or" onChange={handleUploadPDF} uploaded={state.pdfUploaded} loading={state.pdfLoading} />
                    </div>
                  </div>
                </div>
                {state.file !== "" && state.file !== undefined
                  ? <div className="resources_file_upload_wrapper">
                    <div className="file_upload_file_name">{state.pdf}</div>
                    <div className="file_upload_inactive_icon-wrapper">
                      <img className="file_upload_delete_icon" src={InactiveIcon} alt="delete_icon" onClick={() => setState({ file: "" })} />
                    </div>
                    </div>
                  : null}
              </div>
              <div className="resources_modal_button_wrapper">
                <div className="resources_modal_button">
                  <Button text={state.edit ? "Update" : "Add Resources"} type="primary" onClick={state.edit ? handleUpdateResource : handleAddResource} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteResource} />
        </div>
      </div>
    </div>
  )
}
