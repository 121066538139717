import { GET_OFFICERS, GET_STAFF, GET_WORKING_COMMITTEE, GET_CSITA_OFFICER, GET_CSITA_CONTENT } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface'

const initialState = [];
const aboutCsiReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_OFFICERS:
      return { ...state, office: action.payload }

    case GET_WORKING_COMMITTEE:
      return { ...state, workingCommittee: action.payload }

    case GET_STAFF:
      return { ...state, staff: action.payload }

    case GET_CSITA_OFFICER:
      return { ...state, csitaOfficer: action.payload }
      case GET_CSITA_CONTENT:
        return { ...state, csitaContent: action.payload }
    default:
      return state;
  }
}

export default aboutCsiReducer;
