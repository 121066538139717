import React, { useEffect } from "react";
import { useDispatch, useSelector, } from 'react-redux';
import { toast } from 'react-toastify'
import {
  addUser,
  editUser,
  getUserById,
  getAllUser,
  deleteUser,
  departmentDetails
} from 'models/user_management_model';
import { GET_USERS } from "utils/types.utils";
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import Input from "common_components/ui/input_box/input_box.ui";
import _ from "lodash";
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Button from "common_components/ui/button/button.ui";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import { userNavData, departmentTableHeading, departmentBody, csiUserSuggessionData, } from "helper/constants.helper";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import Table from "../../components/table/table.component";
import "screens/users/department_user.screen.scss";
import { useSetState } from "../../utils/common.utils";
import PlusIcon from '../../assets/icons/Plus_White.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import EditIcon from '../../assets/icons/Edit.svg';

export default function DepartmentUser(props) {
  const { throwError } = props
  const list = useSelector((state: any) => state.users.user_list)

  const [state, setState] = useSetState(
    {
      selectedData: "department_user",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      department: "",
      confirmModal: false,
      loading: true,
      user_name: "",
      email: "",
      mobile: "",
      password: "",
      search: '',
      page: 1,
      limit: 20,
      userId: '',
      department_user_id: '',
      user_type: 'department_user',
      select_box_data: []
    });

  const dispatch = useDispatch()
  const handleListUsers = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        type: state.user_type
      }
      const res: any = await getAllUser(data)
      const userList = res.data.map((user) => {
        user.author = user.created_by?.user_name;
        user.department_name = user.department?.name;
        return user
      });
      res.data.docs = userList
      dispatch({
        type: GET_USERS,
        payload: res.data
      })
      setState({
        loading: false
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to fetch user details")
    }
  }
  const getSelectBoxInfo = async () => {
    try {
      const data = { page: state.page, limit: 100, search: state.search }
      const res: any = await departmentDetails(data)
      const value = res.data.docs.map((user) => {
        return { label: user.name, value: user._id }
      })
      setState({
        select_box_data: value
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to fetch details")
    }
  }
  useEffect(() => {
    handleListUsers(state)
    getSelectBoxInfo()
  }, [state.search])

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = departmentBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleEditUser = async (id) => {
    setState({ openModal: true, edit: true, password: '' });
    try {
      const res: any = await getUserById(id);
      setState({
        user_name: res.data.user_name,
        email: res.data.email,
        mobile: res.data.mobile,
        edit: true,
        userId: res.data._id,
        department: res.data.department
      })
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to get user details")
    }
  }

  const handleUpdateUser = async () => {
    try {
      if (state.password.length > 0) {
        const data = {
          user_name: state.user_name,
          email: state.email,
          mobile: state.mobile,
          password: state.password,
          department: state.department
        }
        await editUser(state.userId, data);
        setState({
          openModal: false,
          edit: true,
        })
        handleListUsers({ page: 1, limit: state.page * state.limit, search: state.search });
      } else {
        const data = {
          user_name: state.user_name,
          email: state.email,
          mobile: state.mobile,
          department: state.department
        }
        await editUser(state.userId, data);
        setState({
          openModal: false,
          edit: true,
        })
        handleListUsers({ page: 1, limit: state.page * state.limit, search: state.search });
      }
    } catch (err) {
      throwError(err.status === 422 ? err.data.message : "Unable to add user")
    }
  }
  const handleDelete = (id) => {
    setState({ confirmModal: true, department_user_id: id })
  }
  const handleDeleteUser = async () => {
    try {
      await deleteUser(state.department_user_id);
      handleListUsers({ page: 1, limit: state.page * state.limit, search: state.search });
      setState({
        confirmModal: false
      })
      toast.error('User Deleted')
    } catch (err) {
      throwError(err.data ? err.data.desc : "Unable to delete user")
    }
  }

  const handleAddUser = async () => {
    try {
      const data = {
        user_name: state.user_name,
        email: state.email,
        mobile: state.mobile,
        password: state.password,
        department: state.department,
        user_type: 'department_user'
      }
      const res: any = await addUser(data)
      handleListUsers({ page: 1, limit: state.page * state.limit, search: state.search });
        setState({
          user_name: '',
          email: '',
          mobile: '',
          password: '',
          openModal: false,
        })
    } catch (err) {
      throwError(err.status === 422 ? err.data.message : "Unable to add user")
    }
  }

  const loadMore = async () => {
    try {
      const data = {
        page: state.page + 1,
        limit: state.limit,
        search: state.search,
        type: 'department_user',
      };
      const res: any = await getAllUser(data);
      const userList = res.data.docs.map((user) => {
        user.author = user.created_by.user_name;
        user.department_name = user.department.name;
        return user;
      });
      res.data.docs = userList
      res.data.docs = [...list.docs, ...res.data.docs]
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      props.throwError(err.data ? err.data.desc : 'Failed to get users');
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore();
  };
  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit User",
      onClick: handleEditUser,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete User",
      onClick: handleDelete,
      icon: DeleteIcon
    }
  ]

  return (
    <div className="department_user_screen">
      <div className="department_user_container">
        <div className="department_user_navabar_wrapper">
          <CustomNavBar
            data={userNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add User"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search Text"
            suggession={csiUserSuggessionData}
            buttonOnClick={() => setState({
              openModal: true,
              edit: false,
              user_name: "",
              email: "",
              role: "",
              mobile: '',
              department: "",
              password: ''
            })}
            button
            search={false}
            onSearchChange={searchChange}
          />
        </div>
        {state.loading ? <Spinner />
          : <>
            <div className="department_user_table_wrapper">
              <Table
                header={departmentTableHeading}
                body={list ? list.docs : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMoreInitial}
                hasMore={list.hasNextPage}
                actionButton={actionArray}
                error="No users found"
              />
            </div>
            <div>
              <Modal
                open={state.openModal}
                onClose={() => setState({ openModal: false })}
                center
                classNames={{
                  overlay: 'customOverlay', modal: 'department_user_modal'
                }}
                showCloseIcon
              >
                <div className="department_user_modal_title_wrapper">
                  <div className="department_user_modal_title h4">{state.edit !== true ? "Add User" : "Edit User"}</div>
                </div>
                <div className="department_user_modal_line_wrapper"> </div>
                <div className="department_user_modal_input_wrapper">
                  <div className="department_user_modal_input">
                    <SelectBox data={state.select_box_data} name="Select Department" selectedItem={state.department} onChange={(selectedOption) => { setState({ department: selectedOption.value }) }} />
                  </div>
                  <div className="department_user_modal_input">
                    <Input name="User Name" onChange={handleOnChange} type="text" inputKey="user_name" value={state.user_name} />
                  </div>
                  <div className="department_user_modal_input">
                    <Input name="User Mobile" onChange={handleOnChange} type="text" inputKey="mobile" value={state.mobile} />
                  </div>
                  <div className="department_user_modal_input">
                    <Input name="User Email" onChange={handleOnChange} type="email" inputKey="email" value={state.email} />
                  </div>
                  <div className="department_user_modal_input">
                    <Input name="User Password" onChange={handleOnChange} type="password" inputKey="password" value={state.password} />
                  </div>
                </div>
                <div className="department_user_button_wrapper">
                  <div className="department_user_button">
                    <Button text={state.edit ? 'Update user' : 'Add user'} type="primary" onClick={state.edit ? handleUpdateUser : handleAddUser} />
                  </div>
                </div>
              </Modal>
            </div>
            <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete the Department user ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleDeleteUser} />
            </>}
      </div>
    </div>
  )
}
