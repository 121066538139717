import React, { useRef, useEffect } from 'react';
import './network.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import Pagination from 'react-js-pagination';
import ResourcesCard from 'components/resources_card/resources_card';
import PartnersCard from 'components/partners_card/partners_card';
import { throwError, useSetState } from 'utils/common.utils';
import Navbar from 'components/navbar/navbar.component';
import { getAllPartnerAndNetworkDetails } from 'models/partner_network.model';
import { GET_PARTNER_AND_NETWORK_DETAILS } from 'utils/types.utils';
import { useDispatch, useSelector } from 'react-redux';
import Eye from '../../assets/icons/Eye.svg'

export default function NetworksScreen(props) {
  const dispatch = useDispatch();
  const networkDetails = useSelector(
    (state: any) => state.partner_and_network.partner_network,
  );
  const [state, setState] = useSetState(
    {
      limit: 21,
      page: 1,
      search: "",
      id: "",
      department: "",
      loading: true,
    });
    const handlePageChange = (pageNumber) => {
      setState({ activePage: pageNumber, page: pageNumber });
    };
  const handleGetAllPartnerAndNetworkDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        category: 'network',
      };
      const res: any = await getAllPartnerAndNetworkDetails(data);
      const url = res.data.docs.map((docs) => {
        if (docs.url.includes('http')) {
        docs.link = docs.url
        } else {
          docs.link = `https://${docs.url}`
        }
        return docs;
      })
      res.data.docs = url
      dispatch({
        type: GET_PARTNER_AND_NETWORK_DETAILS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (error) {
      throwError(error.data ? error.data.desc : "Failed to Network details")
    }
  };
  useEffect(() => {
    handleGetAllPartnerAndNetworkDetails();
  }, [state.page]);
  return (
    <div className="screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="network_screen_container">
        <div className="network_screen_header_wrapper">
          <div className="network_screen_header h1">
                Networks
            <div className="header_line" />
          </div>
        </div>
        <div className="network_screen_body_wrapper">
          {networkDetails && networkDetails.docs.map((network) => {
            return (
          <div className="card_wrapper">
            <PartnersCard
              onClick={() => {}}
              icon={Eye}
              title={network.name}
              url={network.image}
              type="publications"
              pdfLink={network.link}
              hoverText="Visit"
            />
          </div>
            )
          })}
        </div>
      </div>
      <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            networkDetails && networkDetails.totalDocs ? networkDetails.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
