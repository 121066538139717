import React, { useEffect } from "react";
import Footer from "components/footer/footer.component";
import Header from "components/header/header.component";
import Navbar from "components/navbar/navbar.component";
import { NavLink } from "react-router-dom";
import parse from 'html-react-parser';
import "screens/user_screens/executive_committee.screen.scss"
import { useDispatch, useSelector } from "react-redux";
import { throwError, useSetState } from "utils/common.utils";
import { GET_EXECUTIVE_COMMITTEE } from "utils/types.utils";
import { getAllDescription } from "models/public.model";

export default function ExecutiveCommittee(props) {
    const dispatch = useDispatch();
    const executive = useSelector((state: any) => state.executive_committee.executive_committee);
    const [state, setState] = useSetState({
      limit: 20,
      page: 1,
      desc: '',
      description: [],
      id: '',
      loading: true,
    });
    const handleGetAllCommittee = async () => {
        try {
          const data = {
            type: "executive_committee"
          }
          const res: any = await getAllDescription(data);
          dispatch({
            type: GET_EXECUTIVE_COMMITTEE,
            payload: res.data
          })
          setState({ loading: false, description: res.data[0].description });
        } catch (err) {
            throwError(err.data ? err.data.des : "Failed to get Committee details")
        }
      }
    useEffect(() => {
      handleGetAllCommittee()
    }, [])

    return (
    <div className="officer_screen">
        <div className="executive_committee_container">
            <div className="executive_committee_wrapper">
                <div className="executive_committee_header_wrapper">
                    <Header />
                </div>
                <div className="executive_committee_navbar_wrapper" style={{ position: 'sticky', top: '0', zIndex: 999 }}>
                <Navbar {...props} />
                </div>
                <div className="executive_committee_title_wrapper">
                    <div className="executive_committee_title h2">Administration</div>
                    <div className="executive_committee_line_wrapper"> </div>
                </div>
                    <div className="executive_committee_nav_link_wrapper h1">
                        <div className="title_links_wrapper">
                            <div className="link h3">
                                <NavLink
                                  to="/about/officer"
                                  className="title_link"
                                >
                                    Officers
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/working_committee"
                                  className="title_link"
                                >
                                    Working Committee
                                </NavLink>
                            </div>
                            <div className="link_1 h3">
                                <NavLink
                                  to="/about/executive_committee"
                                  className="title_link"
                                  activeClassName="selected"
                                >
                                    Executive Committee
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/csita"
                                  className="title_link"
                                >
                                    CSITA
                                </NavLink>
                            </div>
                            <div className="link h3">
                                <NavLink
                                  to="/about/secretarial_staff/Staff Under General Secretary"
                                  className="title_link"
                                >
                                    Secretarial Staff
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="executive_committee_content_container">
                        <div className="executive_committee_content">
                             {parse(`${state.description}`)}
                        </div>
                    </div>
                <div className="executive_committee_footer_wrapper">
                    <div className="executive_committee_footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
