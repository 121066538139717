import React, { useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './text_area.component.scss';
import ImageCompress from 'quill-image-compress';
import { compressImage, uploadMediaFiles } from 'utils/common.utils';
import { uploadMedia } from 'models/advertisment.model';

interface AddNewsProps {
  text: '',
  onChange: any
}

const AddNews = (props: AddNewsProps) => {
  const { text, onChange } = props;
  Quill.register('modules/imageCompress', ImageCompress);
  // Quill.register('modules/imageResize', ImageResize);
  const quillRef: any = React.useRef(null);
  const quill: any = quillRef.current;
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.className = "quill_input"
    input.onchange = async (e) => {
      const data = new FormData();
      const file: any = input.files;
      const ext = file[0].name.split('.').pop();
      const filename = file[0].name.split('.')[0].replace(/ /g, '')
      const newFileName = `${filename}${Date.now()}.${ext}`;
      const newFile = new File([file[0]], newFileName);
      const compressedFile: any = await compressImage(file[0])
      data.append('file', compressedFile, compressedFile.name);
      console.log(compressedFile)
      const res: any = await uploadMedia(data);
      const result = quillRef.current.getEditor().insertEmbed(quillRef.current.getEditor().getSelection(), 'image', res.url);
    }
}
const modules = useMemo(() => ({
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
    ],
    handlers: {
      image: imageHandler
    },
  },
}), [])
  return (
    <div className="text_area_component">
      <div className="text_area_container">
        <div className="text_area_wrapper">
          <ReactQuill
            value={text}
            ref={quillRef}
            onChange={onChange}
            className="text_area"
            modules={modules}
          />
        </div>
      </div>
    </div>
  )
}

export default AddNews;
