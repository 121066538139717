import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GET_STAFF } from "utils/types.utils";
import Button from "common_components/ui/button/button.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import PlusIcon from "assets/icons/Plus_White.svg";
import Spinner from "common_components/ui/spinner/spinner.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Table from "components/table/table.component";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import { AboutCsiNavData, staffTableHeading, staffBody, csiUserSuggessionData } from "helper/constants.helper";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/secretarial_staff.screen.scss";
import { useSetState } from "../../utils/common.utils";

export default function SecretarialStaff(props) {
  const dispatch = useDispatch();
  const staffs = useSelector((state: any) => state.aboutCsi.staff);
  const [state, setState] = useSetState({
    selectedData: "secretarial_staff",
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    search: "",
    limit: 25,
    page: 1,
    role: "",
    userName: "",
    image: "",
    email: "",
    mobile: "",
    designation: "",
    sortOrder: "",
    staffId: "",
    loading: true
  });
  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleGetAllStaff = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        user_sort: true,
        search,
        page,
        type: "secretarial_staff"
      }
      const staff: any = await getAllUser(data);
        dispatch({
          type: GET_STAFF,
          payload: staff.data
        })
        setState({ loading: false });
    } catch (err) {
      props.throwError("Failed to get Staff");
    }
  }

  useEffect(() => {
    handleGetAllStaff(state);
  }, [])
  useEffect(() => {
    handleGetAllStaff(state);
  }, [state.search])

  const handleEditStaff = (id) => {
    props.history.push(`/about_csi/addstaff/${id}`);
  }

  const handleDeleteStaff = (id) => {
    setState({ confirmModal: true, staffId: id })
  }

  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        search,
        page,
        user_sort: true,
        type: "secretarial_staff"
      }
      const staff: any = await getAllUser(data);
        dispatch({
          type: GET_STAFF,
          payload: staff.data
        })
        staff.data.docs = [...staffs.docs, ...staff.data.docs]
    } catch (err) {
      props.throwError("Failed to get Staff");
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }
  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditStaff,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteStaff,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value, page: 1 })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     search: e.target.value,
    //     page: state.page,
    //     type: "secretarial_staff"
    //   }
    //   const staff: any = await getAllUser(data);
    //   if (staff) {
    //     dispatch({
    //       type: GET_STAFF,
    //       payload: staff.data
    //     })
    //   }
    // } catch (err) {
    //   props.throwError("Failed to search Staff");
    // }
  }

  const handleOnDeleteStaff = async () => {
    try {
      const officer: any = await deleteUser(state.staffId);
      await handleGetAllStaff({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false })
    } catch (err) {
      props.throwError("Failed to delete Staff");
    }
  }

  return (
    <div className="secretarial_staff_screen">
      <div className="secretarial_staff_container">
        <div className="secretarial_staff_wrapper">
          <div className="secretarial_staff_header_wrapper">
            <div className="secretarial_staff_name_wrapper h2">
              Administration
            </div>
            <div className="secretarial_staff_action_wrapper">
              <div className="secretarial_staff_search_box_wrapper">
                <SearchBar suggession="" placeholder="Search..." type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              <div className="secretarial_staff_button_wrapper">
                <Button className="secretarial_staff_button" type="primary" text="Add Staff" onClick={() => props.history.push('/about_csi/addstaff')} icon={PlusIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className="secretarial_staff_custom_navbar_wrapper">
          <CustomNavBar
            data={AboutCsiNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add User"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search Text"
            suggession={csiUserSuggessionData}
            buttonOnClick=""
            button={false}
            search={false}
            onSearchChange={searchChange}
          />
        </div>
        <div className="secretarial_staff_table-wrapper">
          {
            state.loading ? <Spinner />
              : <Table
                  header={staffTableHeading}
                  body={staffs ? staffs.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={staffs && staffs.hasNextPage ? staffs.hasNextPage : ''}
                  actionButton={actionArray}
                  error="Secretarial Staff is not found"
              />
          }
        </div>
        <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteStaff} />
      </div>
    </div>
  )
}
