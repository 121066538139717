import { GET_SUBSCRIBER, GET_NEWS } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface';

const initialState = [];

const newsLetterReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_SUBSCRIBER:
      return { ...state, subscriber: action.payload };
      case GET_NEWS:
      return { ...state, news: action.payload };
    default:
      return state;
  }
}

export default newsLetterReducer;
