import Button from 'common_components/ui/button/button.ui';
import { useSetState } from "utils/common.utils";
import React from 'react';
import './magazine_card.component.scss';

interface MagazineCardProps {
  link: string;
  magazineDate: string,
  url?: string
}

const MagazineCard = (props: MagazineCardProps) => {
  const { link, magazineDate, url } = props;
  const [state, setState] = useSetState({ hover: false });
  return (
    <div className="magazine_card_container" onMouseEnter={() => setState({ hover: true })} onMouseLeave={() => setState({ hover: false })}>
      <div className="magazine_card_image_container">
        <img src={link} alt="magazine_image" className="magazine_card_image" />
        <div className="magazine_card_date_container">
          <div className="date_wrapper">
            <div className="magazine_card_date h4">{magazineDate}</div>
          </div>
        </div>
      </div>
      <div className="magazine_overlay"> </div>
      {/* {state.hover */}
         <div className="on_hover_button">
            <div className="download_btn">
              <a href={url} className="download_link" rel="noopener noreferrer" target="_blank" download>View</a>
            </div>
         </div>
      {/* <div className="button_container">
        <div className="button_wrapper">
          <Button type="primary" text="View" color="#FFFFFF" fontSize="10px" onClick={() => {}} />
        </div>
      </div> */}
    </div>
  )
}

export default MagazineCard;

MagazineCard.defaultProps = {
  url: ""
}
