import { instance } from '../helper/axios.helper';

export const getAllDetails = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance()
      .post('statements/get', data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
  return promise;
};

export const addStatement = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance()
      .post('statements/create', data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
  return promise;
};

export const getStatementById = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance()
      .get(`statements/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
  return promise;
};
export const editStatement = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance()
      .put(`statements/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
  return promise;
};

export const deleteStatement = (id) => {
    const promise = new Promise((resolve, reject) => {
      instance()
        .delete(`statements/delete/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response) {
            reject(err.response);
          } else {
            reject(err);
          }
        });
    });
    return promise;
  };
