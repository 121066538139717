import React from 'react';
import './news_card_1.component.scss';
import ArrowRight from '../../assets/icons/Arrow_right.svg'

interface NewsCardProps {
  title: string,
  description: string,
  link: string,
  onClick: any,
  type: string,
  className?: string,
  name?: String,
  typeName?: String,
  hasButton?: boolean
}

const NewsCard1 = (props: NewsCardProps) => {
  const { title, description, link, onClick, type, className, hasButton, name, typeName } = props;
  return (
    <div className={`news_card_1_container ${className || ''}`} onClick={onClick}>
      <div className="news_card_1_wrapper">
        <div className="news_card_1_image_container">
          <img src={link} alt="news_card_img" className="news_card_1_image" />
        </div>
        {type === "news_card"
          && <div className="overlay_news_card"> </div>}
        {type === "event"
          && <div className="overlay_event"> </div>}
        <div className="text_block_container">
          <div className={`text_block ${type === "event" && "text_block_responsive"}`}>
            <div className="text_block_date p4">{description}</div>
            <div className="text_block_date p4">{name}</div>
            <div className="text_block_head p1">{title}</div>
            <div className="text_block_date p4">{typeName}</div>
            {type === "news_card" && hasButton
              && <div className="text_block_btn_container" onClick={onClick}><div className="text_block_btn">Read More</div></div>}
          </div>
          {type === "event" && hasButton
            && <div className="text_block_event_btn_container" onClick={onClick}>
                <div className="text_block_btn">Read More</div>
                <div className="text_block_btn_icon"><img className="right_arrow_icon" src={ArrowRight} alt="arrow_right" /></div>
               </div>}
        </div>
      </div>
    </div>
  )
}

NewsCard1.defaultProps = {
  className: '',
  name: '',
  typeName: '',
  hasButton: false
}

export default NewsCard1
