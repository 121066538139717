import { GET_DEPARTMENT_NEWS, GET_DEPARTMENT_EVENTS, GET_DEPARTMENT, GET_DEPARTMENT_NEWS_DETAILS, GET_ALL_DEPARTMENTS, GET_SINGLE_DEPARTMENT } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface';

const initialState = {};

const departmentReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_DEPARTMENT:
      const department = { ...state, department: action.payload }
      return department
    case GET_DEPARTMENT_NEWS:
      const news = { ...state, news: action.payload }
      return news;
    case GET_DEPARTMENT_EVENTS:
      const events = { ...state, events: action.payload }
      return events;
    case GET_DEPARTMENT_NEWS_DETAILS:
      const newsDetails = { ...state, newsDetails: action.payload };
      return newsDetails;
    case GET_SINGLE_DEPARTMENT:
      return { ...state, singleDepartment: action.payload };
    case GET_ALL_DEPARTMENTS:
      const allDepartments = { ...state, allDepartments: action.payload };
      return allDepartments;
    default:
      return state;
  }
}

export default departmentReducer;
